import { store } from './store';

const LS_SELECTED_BRAND = 'selected-brand';
const supportedBrands = ['esi', 'caredx', 'rxcap'];
const defaultBrand = 'rxcap';

function getBrandFromOrg() {
  const org = store?.getState()?.auth?.profile?.organization?.name;
  if (org && supportedBrands.includes(org.toLowerCase())) return org.toLowerCase();
  return 'rxcap';
}

export function setBrandFromLS() {
  let brand = defaultBrand;
  try {
    brand = localStorage.getItem(LS_SELECTED_BRAND) || defaultBrand;
  } catch (e) {}
  document.documentElement.className = brand;
}

export function saveBrandInLS(brand) {
  if (localStorage) {
    try {
      localStorage.setItem(LS_SELECTED_BRAND, supportedBrands.includes(brand) ? brand : defaultBrand);
    } catch (e) {}
  }
}

export function setBrandFromStore() {
  var org = getBrandFromOrg();
  saveBrandInLS(org);
  setBrandFromLS();
}

export function getVariablesForBrand() {
  const brand = document.documentElement.className;
  switch (brand) {
    case 'esi':
      return { orgName: 'esi' };
    case 'caredx':
      return { orgName: 'AlloHome' };
    default:
      return { orgName: 'rxcap' };
  }
}
