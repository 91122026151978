import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import LoadingRenderer from '../../components/LoadingRenderer';
import Select from '../../components/Select';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import {
  ASSIGN_DEVICE_TO_KIT_ERROR,
  CREATE_KIT_ERROR,
  CREATE_KIT_RESULT,
  KITS_LIST_RESULT,
  UPDATE_KIT_ERROR,
} from '../../pages/Kits/redux/constants';
import { actions } from '../../pages/SuperUser/Organization/redux/actions';
import Strings from '../../Strings';
import './../editStudyModal.scss';
import DeviceList from './DeviceList';

const State = Object.freeze({
  ProvideData: Symbol('ProvideData'),
  Review: Symbol('Review'),
  Submited: Symbol('Submited'),
});

const Mode = Object.freeze({
  Create: Symbol('Create'),
  Edit: Symbol('Edit'),
});

const MAX_KIT_NAME_LENGTH = 50;
const MAX_DEVICES_IN_SELECT = 500;

class CreateKitAndAddDevices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: Strings.createKit,
      review: State.ProvideData,
      kitName: '',
      originalKitName: '',
      isLoading: false,
      mode: Mode.Create,
      selectedOrg: {
        id: 0,
        name: '',
        masked_id: '',
      },
      devices: [],
      unassignedDevices: [],
      organizations: [],
      errors: [],
      orgErrors: [],
      deviceErrors: [],
      lengthError: false,
      asyncSelectNeeded: false,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.timerLoadCaps = null;
    this.timerOrgList = null;
  }

  componentDidMount() {
    if (!this.props.organizations) {
      this.props.getOrgs();
    }

    if (this.props.data?.kitId) {
      this.requestKitName(this.props.data?.kitId);
    }
  }

  requestKitName = kitId => {
    this.setState({
      mode: Mode.Edit,
      title: Strings.editKit,
      isLoading: true,
    });
    this.props.getKits({ search: kitId }).then(resp => this.onKitsReceived(resp));
  };

  onKitsReceived(resp) {
    if (resp.type === KITS_LIST_RESULT) {
      const name = resp.response.kits[0].name;
      this.setState({
        kitName: name,
        originalKitName: name,
        isLoading: false,
        selectedOrg: { masked_id: resp.response.kits[0].org_id },
      });
      this.loadCaps(resp.response.kits[0].org_id);
    } else {
      this.setState({ isLoading: false });
    }
  }

  onTextChange(event) {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  }

  attachDevices = (kit_id, devices = []) => {
    const results = [];

    devices.forEach(device => {
      this.props.assignDeviceToKit(kit_id, { device_id: device.device_id }).then(r => {
        if (r.type === ASSIGN_DEVICE_TO_KIT_ERROR) {
          this.setState({ deviceErrors: [...this.state.dev, r.response?.data?.error?.message] });
        } else {
          results.push(r);

          if (results.length === devices.length) {
            this.props.parentRefresh();
            this.props.onCancel();
          }
        }
      });
    });
  };

  onEdit = () => {
    this.setState({ review: State.Submited });
    const realDevices = this.state.devices.filter(e => e.device_id !== '');
    const areThereAny = realDevices.length > 0;
    if (this.state.kitName !== this.state.originalKitName) {
      this.props
        .updateKit({
          id: this.props.data?.kitId,
          name: this.state.kitName,
        })
        .then(resp => {
          if (resp.type === UPDATE_KIT_ERROR) {
            this.setState({ errors: [...this.state.errors, resp.response?.data?.error?.message] });
          } else if (areThereAny) {
            this.attachDevices(this.props.data?.kitId, this.state.devices);
          } else {
            this.props.parentRefresh();
            this.props.onCancel();
          }
        });
    } else if (areThereAny) {
      this.attachDevices(this.props.data?.kitId, this.state.devices);
    } else {
      this.props.parentRefresh();
      this.props.onCancel();
    }
  };

  onSave = () => {
    const { devices, selectedOrg, kitName } = this.state;
    this.setState({ review: State.Submited });
    this.props
      .createKit({
        name: kitName,
        org_id: selectedOrg.masked_id,
      })
      .then(res => {
        if (res && res.type === CREATE_KIT_RESULT) {
          const kit_id = res.response.kit.id;
          this.attachDevices(kit_id, devices);
        } else if (res.type === CREATE_KIT_ERROR) {
          const errors = [res.response?.data?.error?.message];
          this.setState({ errors });
        }
      });
  };

  onReview = () => {
    this.setState({
      errors: [],
      orgErrors: [],
      deviceErrors: [],
    });

    let { devices } = this.state;
    const { masked_id } = this.state.selectedOrg;

    devices = devices.filter(function(e) {
      return e.device_id != '';
    });

    const validationErrors = [];
    const validationOrgErrors = [];
    const validationDeviceErrors = [];

    // check if kit name is different from original
    if (this.state.kitName === this.state.originalKitName && !devices.length) {
      validationErrors.push(Strings.errors.nothingToChange);
    }

    if (this.state.mode !== Mode.Edit && !masked_id.length) {
      validationOrgErrors.push(Strings.errors.emptyOrgId);
    }

    if (this.state.mode !== Mode.Edit && !devices.length) {
      validationDeviceErrors.push(Strings.errors.emptyDeviceId);
    }

    if (
      validationErrors.length ||
      validationDeviceErrors.length ||
      validationOrgErrors.length ||
      this.state.lengthError
    ) {
      this.setState({ errors: validationErrors });
      this.setState({ orgErrors: validationOrgErrors });
      this.setState({ deviceErrors: validationDeviceErrors });
    } else {
      this.setState({ review: State.Review });
    }
  };

  onBack = () => {
    this.setState({ review: State.ProvideData });
  };

  onKitNameChange = event => {
    const value = event.target.value;
    this.setState({
      kitName: value,
      errors: [],
    });
  };

  handleChange = option => {
    if (this.state.deviceErrors.length > 0) {
      this.setState({
        deviceErrors: [],
        errors: [],
      });
    }

    const devices = [...this.state.devices];

    devices.push(option);
    this.setState({ devices });

    if (option.id) {
      if (option.id.length >= 100) {
        this.setState({ lengthError: true });
      }
    }
    this.forceUpdate();
  };

  clickOnDelete = record => {
    this.setState({ devices: this.state.devices.filter(r => r !== record) });
  };

  onOrgChange = option => {
    this.setState({ selectedOrg: option || { id: 0 } });
    this.setState({ orgErrors: [] });
    this.loadCaps(option.masked_id);
  };

  loadCaps = orgId => {
    this.setState({ devices: [] });
    this.props.loadCaps({ orgId, limit: MAX_DEVICES_IN_SELECT }).then(resp => {
      const devices = resp.response.devices.map(e => {
        return {
          value: e.device_id,
          device_id: e.device_id,
          label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
          device_name: e.device_name,
        };
      });
      this.setState({
        unassignedDevices: devices.filter(d => !this.state.devices.some(dv => dv.device_id === d.device_id)),
        asyncSelectNeeded: resp.response.pagination.totalRecords > MAX_DEVICES_IN_SELECT,
      });
    });
  };

  loadOptions = (value, callback) => {
    if (!value || value.length < 3) {
      return;
    }
    clearTimeout(this.timerLoadCaps);
    this.timerLoadCaps = setTimeout(() => {
      this.props
        .loadCaps({
          search: value,
          orgId: this.state.selectedOrg.masked_id,
        })
        .then(resp =>
          callback(() => {
            const devices = resp.response.devices.map(e => {
              return {
                value: e.device_id,
                device_id: e.device_id,
                label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
                device_name: e.device_name,
              };
            });
            return devices.filter(d => !this.state.devices.some(dv => dv.device_id === d.device_id));
          }),
        );
    }, 1000);
  };

  listOfDevices = devices => {
    const realDevices = devices.filter(e => e.device_id !== '');
    const areThereDevices = realDevices.length > 0;
    return (
      <React.Fragment>
        {areThereDevices && <label className="header-label">{Strings.devices}:</label>}
        {areThereDevices &&
          realDevices.map(device => (
            <label className="mb-20" key={device.device_id}>
              {device.label}
            </label>
          ))}
      </React.Fragment>
    );
  };

  selectOrganization = data => {
    return (
      <div className="row select-org">
        <label>{Strings.organization}</label>
        <Select
          key={`org-list-${this.state.organizations.length}`}
          onChange={this.onOrgChange}
          data={data}
          isSearchable
          searchByLabelOnly
          value={this.state.selectedOrg.value}
          placeholder={Strings.select}
        />
      </div>
    );
  };

  render() {
    const orgs = [];

    const { devices } = this.state;
    if (this.props.organizations) {
      Object.values(this.props.organizations).forEach(organization => {
        orgs.push({
          value: organization.masked_id,
          label: organization.name,
          name: organization.name,
          masked_id: organization.masked_id,
        });
      });
    }

    const modalHeader = {
      caption: this.state.title,
      name: this.props.name,
    };
    const errorsStr = this.state.errors.map(e => <div style={{ margin: 0 }}>{e}</div>);
    const orgErrorsStr = this.state.orgErrors.map(e => <div style={{ margin: 0 }}>{e}</div>);
    const deviceErrorsStr = this.state.deviceErrors.map(e => <div style={{ margin: 0 }}>{e}</div>);

    return (
      <Modal
        name="create-kit-and-add-devices"
        onOpen={this.props.onOpen}
        additionalClasses={['form-modal', 'user-invite']}
        {...modalHeader}
      >
        <LoadingRenderer>
          {this.state.review === State.ProvideData ? (
            <Form onCancel={this.props.onCancel} id="attach-cap-to-org" className="add-kit attach-cap-to-schedule">
              {this.state.mode === Mode.Create ? this.selectOrganization(orgs) : <div />}

              {this.state.selectedOrg.id !== 0 ? (
                <React.Fragment>
                  <div>
                    <label className="header-label">{Strings.kitName}</label>
                    <input
                      type="text"
                      name="kitName"
                      onChange={this.onKitNameChange}
                      value={this.state.kitName}
                      maxLength={MAX_KIT_NAME_LENGTH}
                    />
                  </div>
                  <div>
                    <label className="header-label">{Strings.attachDevicesToKit}</label>
                    <div
                      key="admin-capId"
                      style={this.state.selectedOrg.id === 0 ? { pointerEvents: 'none', opacity: '0.4' } : {}}
                    >
                      <DeviceList
                        asyncNeeded={this.state.asyncSelectNeeded}
                        delete={this.clickOnDelete}
                        key={`device-list-${this.state.devices.length}`}
                        devices={this.state.devices}
                        data={this.state.unassignedDevices}
                        handleChange={this.handleChange}
                        loadOptions={this.loadOptions}
                        simpleBar
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}

              <div key="errors-block" className="errors-block">
                {errorsStr}
                {orgErrorsStr}
                {deviceErrorsStr}
              </div>
              <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
                <button
                  className="brand-blue"
                  key="submit"
                  type="button"
                  onClick={this.onReview}
                  disabled={this.state.isLoading || this.state.selectedOrg.id === 0}
                >
                  {Strings.review}
                </button>
                <button className="brand-white-gray mr-15" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
              </div>
            </Form>
          ) : (
            <React.Fragment>
              <label className="header-label">
                {Strings.kitName} : {this.state.kitName ? this.state.kitName : 'N/A'}
              </label>
              {this.state.mode === Mode.Create ? (
                <label className="header-label">
                  {Strings.organization} : {this.state.selectedOrg.name}
                </label>
              ) : null}
              {this.listOfDevices(devices)}
              <div key="errors-block" className="errors-block">
                {errorsStr}
                {orgErrorsStr}
                {deviceErrorsStr}
              </div>
              <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
                {this.state.review === State.Submited ? null : this.state.mode === Mode.Create ? (
                  <button className="brand-blue" key="save" type="button" onClick={this.onSave}>
                    {Strings.save}
                  </button>
                ) : (
                  <button className="brand-blue" key="submit" type="button" onClick={this.onEdit}>
                    {Strings.save}
                  </button>
                )}
                {this.state.review === State.Submited &&
                errorsStr.length === 0 &&
                orgErrorsStr.length === 0 &&
                deviceErrorsStr.length === 0 ? null : (
                  <button className="white gray-text mr-15" key="back" type="button" onClick={this.onBack}>
                    {Strings.back}
                  </button>
                )}
              </div>
            </React.Fragment>
          )}
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  AttachCaptoAdminModalLoading: state.entities.caps.loading,
  organizations: state.superUser?.organizations?.organizationAll,
  devices: state.entities.kits?.devices,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('create-kit-and-add-devices')),
  getOrgs: request => dispatch(actions.getOrgs(request)),
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  updateKit: (id, request) => dispatch(kitActions.updateKit(id, request)),
  createKit: data => dispatch(kitActions.createKit(data)),
  assignDeviceToKit: (kitId, data) => dispatch(kitActions.assignDeviceToKit(kitId, data)),
  loadCaps: pageRequest => dispatch(kitActions.getDevicesUnassignedToKit(pageRequest)),
  onOpen: () => dispatch(resetForm('create-kit-and-add-devices', ownProps.data)),
  parentRefresh: () => ownProps.data.onRefresh(),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateKitAndAddDevices);
