export const GET_DEVICES_UNASSIGNED_TO_KIT = 'get-devices-unassigned-to-kit';
export const GET_DEVICES_UNASSIGNED_TO_KIT_RESULT = `${GET_DEVICES_UNASSIGNED_TO_KIT}/result`;
export const GET_DEVICES_UNASSIGNED_TO_KIT_ERROR = `${GET_DEVICES_UNASSIGNED_TO_KIT}/error`;

export const KIT_DEVICES = 'kit-device';
export const KIT_DEVICES_RESULT = `${KIT_DEVICES}/result`;
export const KIT_DEVICES_ERROR = `${KIT_DEVICES}/error`;

export const KITS_LIST = 'kits-list';
export const KITS_LIST_RESULT = `${KITS_LIST}/result`;
export const KITS_LIST_ERROR = `${KITS_LIST}/error`;

export const CREATE_KIT = 'create-kit';
export const CREATE_KIT_RESULT = `${CREATE_KIT}/result`;
export const CREATE_KIT_ERROR = `${CREATE_KIT}/error`;

export const ASSIGN_DEVICE_TO_KIT = 'assign-device-to-kit';
export const ASSIGN_DEVICE_TO_KIT_RESULT = `${ASSIGN_DEVICE_TO_KIT}/result`;
export const ASSIGN_DEVICE_TO_KIT_ERROR = `${ASSIGN_DEVICE_TO_KIT}/error`;

export const UNASSIGN_DEVICE_FROM_KIT = 'unassign-device-from-kit';
export const UNASSIGN_DEVICE_FROM_KIT_RESULT = `${UNASSIGN_DEVICE_FROM_KIT}/result`;
export const UNASSIGN_DEVICE_FROM_KIT_ERROR = `${UNASSIGN_DEVICE_FROM_KIT}/error`;

export const DELETE_KIT = 'delete-kit';
export const DELETE_KIT_RESULT = `${DELETE_KIT}/result`;
export const DELETE_KIT_ERROR = `${DELETE_KIT}/error`;

export const UPDATE_KIT = 'update-kit';
export const UPDATE_KIT_RESULT = `${UPDATE_KIT}/result`;
export const UPDATE_KIT_ERROR = `${UPDATE_KIT}/error`;

export const GET_PATIENT_KIT_INFO = 'get-patient-kit-info';
export const GET_PATIENT_KIT_INFO_RESULT = `${GET_PATIENT_KIT_INFO}/result`;
export const GET_PATIENT_KIT_INFO_ERROR = `${GET_PATIENT_KIT_INFO}/error`;

export const ATTACH_PATIENT_TO_KIT = 'attach-patient-to-kit';
export const ATTACH_PATIENT_TO_KIT_RESULT = `${ATTACH_PATIENT_TO_KIT}/result`;
export const ATTACH_PATIENT_TO_KIT_ERROR = `${ATTACH_PATIENT_TO_KIT}/error`;

export const DELETE_KIT_FOR_PATIENT = 'delete-kit-for-patient';
export const DELETE_KIT_FOR_PATIENT_RESULT = `${DELETE_KIT_FOR_PATIENT}/result`;
export const DELETE_KIT_FOR_PATIENT_ERROR = `${DELETE_KIT_FOR_PATIENT}/error`;

export const GET_KIT_DETAILS = 'get-kit-details';
export const GET_KIT_DETAILS_RESULT = `${GET_KIT_DETAILS}/result`;
export const GET_KIT_DETAILS_ERROR = `${GET_KIT_DETAILS}/error`;

export const CLEAR_DATA = 'clear-data';
