export const FILTER_RESET = 'filter-reset';
export const FILTER_CHANGE = 'filter-change';
export const START_LOADING = 'start-loading';

export const PATIENT_DETAIL = 'patient-detail';
export const PATIENT_DETAIL_RESULT = 'patient-detail/result';
export const PATIENT_DETAIL_ERROR = 'patient-detail/error';

export const PATIENT_DETAIL_EDIT = 'patient-info';
export const PATIENT_DETAIL_EDIT_RESULT = 'patient-info/result';
export const PATIENT_DETAIL_EDIT_ERROR = 'patient-info/error';

export const LIST_COUNTRIES = 'countries';
export const LIST_COUNTRIES_RESULT = 'countries/result';
export const LIST_COUNTRIES_ERROR = 'countries/error';

export const LIST_TRIALS = 'list-trials';
export const LIST_TRIALS_RESULT = 'list-trials/result';
export const LIST_TRIALS_ERROR = 'list-trials/error';

export const GET_TRIAL_ADHERENCE = 'get-trial-adherence';
export const GET_TRIAL_ADHERENCE_RESULT = 'get-trial-adherence/result';
export const GET_TRIAL_ADHERENCE_ERROR = 'get-trial-adherence/error';

export const GET_TOTAL_TRIAL_ADHERENCE = 'get-total-trial-adherence';
export const GET_TOTAL_TRIAL_ADHERENCE_RESULT = 'get-total-trial-adherence/result';
export const GET_TOTAL_TRIAL_ADHERENCE_ERROR = 'get-total-trial-adherence/error';

export const LIST_NOTIFICATIONS = 'list-notifications';
export const LIST_NOTIFICATIONS_RESULT = 'list-notifications/result';
export const LIST_NOTIFICATIONS_ERROR = 'list-notifications/error';

export const GET_TRIAL = 'get-trial';
export const GET_TRIAL_RESULT = 'get-trial/result';
export const GET_TRIAL_ERROR = 'get-trial/error';

export const TRIAL_INFO = 'trial-info';
export const TRIAL_INFO_RESULT = 'trial-info/result';
export const TRIAL_INFO_ERROR = 'trial-info/error';

export const UPDATE_TRIAL = 'update-trial';
export const UPDATE_TRIAL_RESULT = 'update-trial/result';
export const UPDATE_TRIAL_ERROR = 'update-trial/error';

export const DELETE_TRIAL = 'delete-trial';
export const DELETE_TRIAL_RESULT = 'delete-trial/result';
export const DELETE_TRIAL_ERROR = 'delete-trial/error';

export const CLEAR_ENTITIES = 'clear-entities';

export const NEW_TRIAL = 'new-trial';
export const NEW_TRIAL_RESULT = 'new-trial/result';
export const NEW_TRIAL_ERROR = 'new-trial/error';

export const LIST_PATIENT = 'list-patients';
export const LIST_PATIENT_RESULT = 'list-patients/result';
export const LIST_PATIENT_ERROR = 'list-patients/error';

export const TRIAL_NEW_PATIENT = 'trial-new-patient';
export const TRIAL_NEW_PATIENT_RESULT = 'trial-new-patient/result';
export const TRIAL_NEW_PATIENT_ERROR = 'trial-new-patient/error';

export const LIST_INVITED_PATIENT = 'list-invited-patients';
export const LIST_INVITED_PATIENT_RESULT = 'list-invited-patients/result';
export const LIST_INVITED_PATIENT_ERROR = 'list-invited-patients/error';

export const LIST_OWN_PATIENTS = 'list-own-patients';
export const LIST_OWN_PATIENTS_RESULT = 'list-own-patients/result';
export const LIST_OWN_PATIENTS_ERROR = 'list-own-patients/error';

export const LIST_SHEDULES = 'list-schedules';
export const LIST_SHEDULES_RESULT = 'list-schedules/result';
export const LIST_SHEDULES_ERROR = 'list-schedules/error';

export const LIST_ALL_ACTIVITY_EVENTS = 'list-all-activity-events';
export const LIST_ALL_ACTIVITY_EVENTS_RESULT = 'list-all-activity-events/result';
export const LIST_ALL_ACTIVITY_EVENTS_ERROR = 'list-all-activity-events/error';

export const UPDATE_PATIENT_SETTINGS = 'update-patient-settings';
export const UPDATE_PATIENT_SETTINGS_RESULT = 'update-patient-settings/result';
export const UPDATE_PATIENT_SETTINGS_ERROR = 'update-patient-settings/error';

export const UPDATE_PATIENT_NOTIFICATIONS = 'update-patient-notifications';
export const UPDATE_PATIENT_NOTIFICATIONS_RESULT = 'update-patient-notifications/result';
export const UPDATE_PATIENT_NOTIFICATIONS_ERROR = 'update-patient-notifications/error';

export const EVENT_LOCATIONS = 'event-locations';
export const EVENT_LOCATIONS_RESULT = 'event-locations/result';
export const EVENT_LOCATIONS_ERROR = 'event-locations/error';

export const POST_ADD_CAREGIVER = 'post-add-caregiver';
export const POST_ADD_CAREGIVER_RESULT = 'post-add-caregiver/result';
export const POST_ADD_CAREGIVER_ERROR = 'post-add-caregiver/error';

export const DELETE_CAREGIVER = 'delete-caregiver';
export const DELETE_CAREGIVER_RESULT = 'delete-caregiver/result';
export const DELETE_CAREGIVER_ERROR = 'delete-caregiver/error';

export const DELETE_CAP_PATIENT = 'delete-patient';
export const DELETE_CAP_PATIENT_RESULT = 'delete-patient/result';
export const DELETE_CAP_PATIENT_ERROR = 'delete-patient/error';

export const ADD_NICK_NAME = 'add-nick-name';
export const ADD_NICK_NAME_RESULT = 'add-nick-name/result';
export const ADD_NICK_NAME_ERROR = 'add-nick-name/error';

export const DOWNLOAD_PATIENTS_DATA = 'download-patients-data';
export const DOWNLOAD_PATIENTS_DATA_RESULT = 'download-patients-data/result';
export const DOWNLOAD_PATIENTS_DATA_ERROR = 'download-patients-data/error';

export const DOWNLOAD_PATIENT_DATA = 'download-patient-data';
export const DOWNLOAD_PATIENT_DATA_RESULT = 'download-patient-data/result';
export const DOWNLOAD_PATIENT_DATA_ERROR = 'download-patient-data/error';

export const TRIAL_DELETE_PATIENT = 'trial-delete-patient';
export const TRIAL_DELETE_PATIENT_RESULT = 'trial-delete-patient/result';
export const TRIAL_DELETE_PATIENT_ERROR = 'trial-delete-patient/error';

export const DELETE_PATIENT = 'delete-patient';
export const DELETE_PATIENT_RESULT = 'delete-patient/result';
export const DELETE_PATIENT_ERROR = 'delete-patient/error';

export const POST_ADMIN_INVITE = 'post-admin-invite';
export const POST_ADMIN_INVITE_RESULT = 'post-admin-invite/result';
export const POST_ADMIN_INVITE_ERROR = 'post-admin-invite/error';

export const LIST_ADMINS_MEMBERSHIPS = 'list-admins-memberships';
export const LIST_ADMINS_MEMBERSHIPS_RESULT = 'list-admins-memberships/result';
export const LIST_ADMINS_MEMBERSHIPS_ERROR = 'list-admins-memberships/error';

export const LIST_ADMINS = 'list-admins';
export const LIST_ADMINS_RESULT = 'list-admins/result';
export const LIST_ADMINS_ERROR = 'list-admins/error';

export const DELETE_ADMIN_MEMBERSHIP = 'delete-admin-membership';
export const DELETE_ADMIN_MEMBERSHIP_RESULT = 'delete-admin-membership/result';
export const DELETE_ADMIN_MEMBERSHIP_ERROR = 'delete-admin-membership/error';

export const SAVE_ADMIN_PERMISSION = 'save-admin-permission';
export const SAVE_ADMIN_PERMISSION_RESULT = 'save-admin-permission/result';
export const SAVE_ADMIN_PERMISSION_ERROR = 'save-admin-permission/error';

export const RESET_ADMIN_PASSWORD = 'reset-admin-password';
export const RESET_ADMIN_PASSWORD_RESULT = 'reset-admin-password/result';
export const RESET_ADMIN_PASSWORD_ERROR = 'reset-admin-password/error';

export const LIST_INVITES = 'list-invites';
export const LIST_INVITES_RESULT = 'list-invites/result';
export const LIST_INVITES_ERROR = 'list-invites/error';

export const SEND_INVITE = 'send-invite';
export const SEND_INVITE_RESULT = 'send-invite/result';
export const SEND_INVITE_ERROR = 'send-invite/error';

export const PATIENT_PORTAL_GET_MEDICATIONS = 'patient-portal-get-medications';
export const PATIENT_PORTAL_GET_MEDICATIONS_RESULT = 'patient-portal-get-medications/result';
export const PATIENT_PORTAL_GET_MEDICATIONS_ERROR = 'patient-portal-get-medications/error';

export const PATIENT_PORTAL_TAKE_MEDICATIONS = 'patient-portal-take-medication';
export const PATIENT_PORTAL_TAKE_MEDICATIONS_RESULT = 'patient-portal-take-medication/result';
export const PATIENT_PORTAL_TAKE_MEDICATIONS_ERROR = 'patient-portal-take-medication/error';

export const PATIENT_PORTAL_LIST_SCHEDULES = 'patient-portal-list-schedules';
export const PATIENT_PORTAL_LIST_SCHEDULES_RESULT = 'patient-portal-list-schedules/result';
export const PATIENT_PORTAL_LIST_SCHEDULES_ERROR = 'patient-portal-list-schedules/error';

export const PATIENT_PORTAL_DELETE_SCHEDULE = 'patient-portal-delete-schedule';
export const PATIENT_PORTAL_DELETE_SCHEDULE_RESULT = 'patient-portal-delete-schedule/result';
export const PATIENT_PORTAL_DELETE_SCHEDULE_ERROR = 'patient-portal-delete-schedule/error';

export const PATIENT_PORTAL_SAVE_SCHEDULE = 'patient-portal-save-schedule';
export const PATIENT_PORTAL_SAVE_SCHEDULE_RESULT = 'patient-portal-save-schedule/result';
export const PATIENT_PORTAL_SAVE_SCHEDULE_ERROR = 'patient-portal-save-schedule/error';

export const PATIENT_PORTAL_LIST_NOTIFICATIONS = 'patient-portal-list-notifications';
export const PATIENT_PORTAL_LIST_NOTIFICATIONS_RESULT = 'patient-portal-list-notifications/result';
export const PATIENT_PORTAL_LIST_NOTIFICATIONS_ERROR = 'patient-portal-list-notifications/error';

export const PATIENT_UPDATE_NOTIFICATIONS = 'patient-update-notifications';
export const PATIENT_UPDATE_NOTIFICATIONS_RESULT = 'patient-update-notifications/result';
export const PATIENT_UPDATE_NOTIFICATIONS_ERROR = 'patient-update-notifications/error';

export const PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS = 'patient-portal-update-patient-settings';
export const PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS_RESULT = 'patient-portal-update-patient-settings/result';
export const PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS_ERROR = 'patient-portal-update-patient-settings/error';

export const PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS = 'patient-portal-update-patient-notifications';
export const PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS_RESULT = 'patient-portal-update-patient-notifications/result';
export const PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS_ERROR = 'patient-portal-update-patient-notifications/error';

export const PATIENT_PORTAL_ADD_CAREGIVER = 'patient-portal-add-caregiver';
export const PATIENT_PORTAL_ADD_CAREGIVER_RESULT = 'patient-portal-add-caregiver/result';
export const PATIENT_PORTAL_ADD_CAREGIVER_ERROR = 'patient-portal-add-caregiver/error';

export const PATIENT_PORTAL_DELETE_CAREGIVER = 'patient-portal-delete-caregiver';
export const PATIENT_PORTAL_DELETE_CAREGIVER_RESULT = 'patient-portal-delete-caregiver/result';
export const PATIENT_PORTAL_DELETE_CAREGIVER_ERROR = 'patient-portal-delete-caregiver/error';

export const PATIENT_PORTAL_NOTIFICATIONS_EXISTS = 'patient-portal-notifications-exists';
export const PATIENT_PORTAL_NOTIFICATIONS_EXISTS_RESULT = 'patient-portal-notifications-exists/result';
export const PATIENT_PORTAL_NOTIFICATIONS_EXISTS_ERROR = 'patient-portal-notifications-exists/error';

export const GET_USER_SETTINGS = 'get-user-settings';
export const GET_USER_SETTINGS_RESULT = 'get-user-settings/result';
export const GET_USER_SETTINGS_ERROR = 'get-user-settings/error';

export const POST_USER_SETTINGS = 'post-user-settings';
export const POST_USER_SETTINGS_RESULT = 'post-user-settings/result';
export const POST_USER_SETTINGS_ERROR = 'post-user-settings/error';

export const USER_ACTIVATE = 'user-activate';
export const USER_ACTIVATE_RESULT = 'user-activate/result';
export const USER_ACTIVATE_ERROR = 'user-activate/error';

export const USER_NAME_CHECK = 'user-name-check';
export const USER_NAME_CHECK_RESULT = 'user-name-check/result';
export const USER_NAME_CHECK_ERROR = 'user-name-check/error';

export const MOBILE_PHONE_VERIFY = 'mobile-phone-verify';
export const MOBILE_PHONE_VERIFY_RESULT = 'mobile-phone-verify/result';
export const MOBILE_PHONE_VERIFY_ERROR = 'mobile-phone-verify/error';

export const VERIFY_SEND = 'verify@send';
export const VERIFY_SEND_RESULT = 'verify@send/result';
export const VERIFY_SEND_ERROR = 'verify@send/error';

export const TRIAL_OVERVIEW = 'trial-overview';
export const TRIAL_OVERVIEW_RESULT = 'trial-overview/result';
export const TRIAL_OVERVIEW_ERROR = 'trial-overview/error';

export const GENERAL_OVERVIEW = 'general-overview';
export const GENERAL_OVERVIEW_RESULT = 'general-overview/result';
export const GENERAL_OVERVIEW_ERROR = 'general-overview/error';

export const TRIAL_TIMESTAMP = 'trial-timestamp';
export const TRIAL_TIMESTAMP_RESULT = 'trial-timestamp/result';
export const TRIAL_TIMESTAMP_ERROR = 'trial-timestamp/error';

export const TRIAL_TIMESTAMP_RELOAD = 'trial-timestamp-reload';

export const LIST_CAREGIVERS = 'patient-caregivers';
export const LIST_CAREGIVERS_RESULT = 'patient-caregivers/result';
export const LIST_CAREGIVERS_ERROR = 'patient-caregivers/error';

export const LIST_PATIENT_MEDICATION_HISTORY = 'list-patient-medication-history';
export const LIST_PATIENT_MEDICATION_HISTORY_RESULT = 'list-patient-medication-history/result';
export const LIST_PATIENT_MEDICATION_HISTORY_ERROR = 'list-patient-medication-history/error';

export const DATE_FILTER_CALLED = 'date-filter-called';
export const DATE_FILTER_RESULT = 'date-filter-called/result';
export const DATE_FILTER_ERROR = 'date-filter-called/error';

export const POST_ADD_MEDICINE = 'post-add-medicine';
export const POST_ADD_MEDICINE_RESULT = 'post-add-medicine/result';
export const POST_ADD_MEDICINE_ERROR = 'post-add-medicine/error';

export const SEND_SECURITY_CODE_CAP = 'send-security-code';
export const SEND_SECURITY_CODE_CAP_RESULT = 'send-security-code/result';
export const SEND_SECURITY_CODE_CAP_ERROR = 'send-security-code/error';

export const POST_CAP_MANAGER_INVITE = 'add-cap-manager';
export const POST_CAP_MANAGER_INVITE_RESULT = 'add-cap-manager/result';
export const POST_CAP_MANAGER_INVITE_ERROR = 'add-cap-manager/error';

export const ACCEPT_AS_CAREGIVER = 'accept-as-caregiver';
export const ACCEPT_AS_CAREGIVER_RESULT = 'accept-as-caregiver/result';
export const ACCEPT_AS_CAREGIVER_ERROR = 'accept-as-caregiver/error';

export const GET_MONTHLY_ADHERENCE = 'get-monthly-adherence';
export const GET_MONTHLY_ADHERENCE_RESULT = 'get-monthly-adherence/result';
export const GET_MONTHLY_ADHERENCE_ERROR = 'get-monthly-adherence/error';

export const ADD_HARDWARE_ID = 'add-hardware-id';
export const ADD_HARDWARE_ID_RESULT = 'add-hardware-id/result';
export const ADD_HARDWARE_ID_ERROR = 'add-hardware-id/error';

export const SHOW_MESSAGE = 'show-message';
export const HIDE_MESSAGE = 'hide-message';

export const GET_PATIENT_DASHBOARD = 'get-patient-dashboard';
export const GET_PATIENT_DASHBOARD_RESULT = 'get-patient-dashboard/result';
export const GET_PATIENT_DASHBOARD_ERROR = 'get-patient-dashboard/error';

export const LIST_MEDICATION = 'list-medications';
export const LIST_MEDICATION_RESULT = 'list-medications/result';
export const LIST_MEDICATION_ERROR = 'list-medications/error';

export const LIST_MEDICATION_DETAILS = 'list-medications-details';
export const LIST_MEDICATION_DETAILS_RESULT = 'list-medications-details/result';
export const LIST_MEDICATION_DETAILS_ERROR = 'list-medications-details/error';

export const UPDATE_PATIENT_SCHEDULE = 'update-patient-schedule';
export const UPDATE_PATIENT_SCHEDULE_RESULT = 'update-patient-schedule/result';
export const UPDATE_PATIENT_SCHEDULE_ERROR = 'update-patient-schedule/error';

export const UPDATE_PATIENT_STOCK = 'update-patient-stock';
export const UPDATE_PATIENT_STOCK_RESULT = 'update-patient-stock/result';
export const UPDATE_PATIENT_STOCK_ERROR = 'update-patient-stock/error';

export const CLEAR_HISTORY_DATA = 'clear-patient-history-data';
export const CLEAR_SCHEDULES = 'clear-schedules';

export const GET_MEDICATION_DOCS_DETAILS = 'get-medication-doc-details';
export const GET_MEDICATION_DOCS_DETAILS_RESULT = 'get-medication-doc-details/result';
export const GET_MEDICATION_DOCS_DETAILS_ERROR = 'get-medication-doc-details/error';

export const UPDATE_MEDICATION_STATUS = 'update-medication-status';
export const UPDATE_MEDICATION_STATUS_RESULT = 'update-medication-status/result';
export const UPDATE_MEDICATION_STATUS_ERROR = 'update-medication-status/error';

export const GET_ENROLLMENT_DATE = 'get-enrollment-date';
export const GET_ENROLLMENT_DATE_RESULT = 'get-enrollment-date/result';
export const GET_ENROLLMENT_DATE_ERROR = 'get-enrollment-date/error';

export const GET_PATIENT_MEDICATION_HISTORY = 'get-patient-medication-history';
export const GET_PATIENT_MEDICATION_HISTORY_RESULT = 'get-patient-medication-history/result';
export const GET_PATIENT_MEDICATION_HISTORY_ERROR = 'get-patient-medication-history/error';

export const ACCEPT_EULA = 'accept-eula';
export const ACCEPT_EULA_RESULT = `${ACCEPT_EULA}/result`;
export const ACCEPT_EULA_ERROR = `${ACCEPT_EULA}/error`;
