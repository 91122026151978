import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modal';
import Form, { Input, SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import { notificationActions } from '../../components/Notification/redux/actions';
import { Modal } from '../../containers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { actionsDashboard } from '../../pages/Dashboards/redux/actions';
import { ColorPalette } from '../../pages/Dashboards/DashboardColorPalettes';
import { GetDefaultConfiguration } from '../../pages/Dashboards/DashboardsMetadata';

const getGlobalConfigFromWidgetConfigs = widgetConfigs => {
  const globalConfig = {};
  Object.entries(widgetConfigs).forEach(([widgetId, widgetConfig]) => {
    if (widgetConfig) {
      Object.entries(widgetConfig).forEach(([configKey, configValue]) => {
        if (configValue.canBeSetByDashboard && !globalConfig[configKey]) {
          globalConfig[configKey] = configValue;
        }
      });
    }
  });
  return globalConfig;
};

function DashboardConfigurationModal(props) {
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [saveButtonHovered, setSaveButtonHovered] = useState(false);
  const [palette, setPalette] = useState(props.colorPalette);
  const [defaultWidgetsConfig, setDefaultWidgetsConfig] = useState(
    props.dashboardConfig || getGlobalConfigFromWidgetConfigs(props.defaultConfiguration),
  );

  const onSave = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);
    props.changeColorPalette(palette);
    props.changeDashboardConfiguration(defaultWidgetsConfig);
    props.showNotification(Strings.formatString(Strings.itemSaved, Strings.configuration));
    props.closeModal();
    setSaveBtnClicked(false);
  };

  const onChange = (property, value) => {
    setDefaultWidgetsConfig(c => ({
      ...c,
      [property]: { ...c[property], value },
    }));
  };

  return (
    <Modal name="dashboard-configuration" withoutContainer {...props}>
      <div className="config-modal">
        <h2>Dashboard configuration</h2>
        <Form onCancel={props.closeModal} id="dashboard-configuration">
          <OverlayScrollbarsComponent
            defer
            options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
            key="scrollbar"
          >
            <div className="content-block">
              <SelectField
                name="color-scheme"
                id="color-scheme"
                value={palette}
                label="Graphs color scheme"
                placeholder="Select colors"
                isRequired
                highlightInvalid={saveButtonHovered}
                onChange={option => setPalette(option.value)}
                data={Object.entries(ColorPalette).map(([key, value]) => ({
                  label: (
                    <div className="color-select">
                      {value.name}
                      <div className="colors">
                        {[0, 0.2, 0.4, 0.6, 0.8, 1].map(i => (
                          <span
                            className="color-sample"
                            key={`color-bar-${i}`}
                            style={{ backgroundColor: value.interpolate(i) }}
                          />
                        ))}
                      </div>
                    </div>
                  ),
                  value: key,
                }))}
              />
              {defaultWidgetsConfig && (
                <React.Fragment>
                  <div>Default widgets configuration:</div>
                  {Object.entries(defaultWidgetsConfig).map(([key, c]) => (
                    <div key={c.name} className="config-modal-row">
                      <Input
                        id={c.name}
                        onChange={e => onChange(key, e.target.value)}
                        isRequired={c.isRequired}
                        highlightInvalid={saveButtonHovered}
                        // errorsForTooltip={validationErrors.find(v => v.property === c.name)?.errors || []}
                        {...c}
                        defaultValue={c.value ? undefined : c.defaultValue}
                      />
                    </div>
                  ))}
                </React.Fragment>
              )}
            </div>
          </OverlayScrollbarsComponent>
          <div key="content">
            {/* <div className="requiredFieldsError">
              {saveButtonHovered && validationErrors.filter(e => e.missing === true)?.length > 0
                ? `${validationErrors.filter(e => e.missing === true)?.length} *${Strings.fieldsRequired}`
                : '\xa0'}
            </div> */}
            <div className="buttons" key="button-bar">
              <div className="bottom-button-container">
                <button className="brand-white-gray" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
              </div>
              <div
                className="bottom-button-container"
                onMouseEnter={() => setSaveButtonHovered(true)}
                onMouseLeave={() => setSaveButtonHovered(false)}
              >
                <button
                  className="brand-blue"
                  key="submit"
                  type="button"
                  onClick={onSave}
                  // disabled={validationErrors.length > 0 || saveBtnClicked}
                >
                  {Strings.save}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

DashboardConfigurationModal.propTypes = {
  closeModal: PropTypes.func,
  showNotification: PropTypes.func,
  changeColorPalette: PropTypes.func,
  changeDashboardConfiguration: PropTypes.func,
  data: PropTypes.shape({ dashboardId: PropTypes.string, additionalData: PropTypes.any }),
};

const mapStateToProps = (state, ownProps) => {
  const { configuration } = state.dashboardState;
  return {
    colorPalette: configuration?.allDashboards?.colorPalette,
    defaultConfiguration: GetDefaultConfiguration(ownProps.data.dashboardId, ownProps.data.additionalData),
    dashboardConfig: configuration[ownProps.data.dashboardId]?.WidgetConfigForDashboard,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModal: () => dispatch(closeModal('dashboard-configuration')),
  changeColorPalette: colorPalette => dispatch(actionsDashboard.changeColorPalette(colorPalette)),
  changeDashboardConfiguration: configuration =>
    dispatch(actionsDashboard.changeWidgetConfigurationForDashboard(ownProps.data.dashboardId, configuration)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardConfigurationModal);
