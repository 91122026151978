import PropTypes from 'prop-types';
import cn from 'classnames';

import _, { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { ADD_NICK_NAME } from '../../actions/action-types';
import { openModalAction } from '../../actions/modal';
import CapTakesPlot from './CapTakesPlot';
import { Button } from '../../components/PageHeader/Button';
import {
  DATE_MONTH_DAY_YEAR,
  LOCAL_TIMEZONE,
  PAGE_LIMIT,
  TIME_FORMAT_12_UPPERCASE,
  DATE_MONTH_DAY_YEAR_WITH_DASH,
} from '../../constants';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import Strings from '../../Strings';
import { getCapChargeText, getFullLocation, getLocation, signalRender } from '../../utils';
import { isShipper, isSuperUser } from '../../utils/userRoles';
import { actions } from './redux/actions';
import styles from './style.module.scss';
import { PageHeader } from '../../components/PageHeader';
import AdvancedFilters, { Filter } from '../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../components/AdvancedFilters/AdvancedFiltersBar';
import { getRelevantFilters } from '../../components/AdvancedFilters/helpers';

const mapStateToProps = state => {
  const { profile } = state.auth;
  const access = profile?.access;
  return {
    isLoading: state.shipper.capsDetails.isLoading,
    details: state.shipper.capsDetails.data,
    plotData: state.shipper.capsDetails.plotData,
    plotScheduleData: state.shipper.capsDetails.plotScheduleData,
    pagination: state.shipper.capsDetails.data?.pagination,
    access,
    timezone: state.auth?.profile?.preferences?.timezone,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  getFillterData: type => dispatch(actions.getFillterData(type)),
  getCapsDetails: (id, pageRequest) => dispatch(actions.getCapsDetails(id, pageRequest)),
  clearCapsDetails: () => dispatch(actions.clearCapsDetails()),
  getPlotScheduleDetail: id => dispatch(actions.getPlotScheduleDetail(id)),
  getPlotData: (id, startDate, endDate) => dispatch(actions.getPlotData(id, startDate, endDate)),
  onAddNickName: (pageRequest, capName, id) =>
    dispatch(
      openModalAction('add-nick-name', {
        action: actions.addNickName,
        actionType: ADD_NICK_NAME,
        id,
        nextAction: actions.getCapsDetails,
        pageRequest,
        capName,
        allowEmpty: true,
      }),
    ),
  navigate: path => dispatch(push(path)),
});

const getFilterItems = filters => {
  const ret = [];
  /* eslint-disable */
  for (const i in filters) {
    ret.push({
      id: filters[i].type,
      value: filters[i].value,
    });
  }
  /* eslint-enable */
  return ret;
};

export function getActivityTypeFilter(activityTypes) {
  return {
    activityType: {
      group: 'activityType',
      text: Strings.eventType,
      values: activityTypes.map(a => {
        return { text: a.value, value: a.id };
      }),
    },
  };
}

export const getCapHeader = (details, type, editCapName, showBackButton, color = undefined) => {
  return (
    <div className={`infoHeader ${color ? color : ''}`}>
      <div className="rowDirection">
        {showBackButton && (
          <button className="back" onClick={() => history.back()} tabIndex={0}>
            {Strings.back}
          </button>
        )}
        <div className="feildBox">
          {Strings.deviceName}:{' '}
          <div className="feildValue val">{details?.cap_name?.toString() ? details?.cap_name?.toString() : '-'} </div>
          {isShipper() ? (
            ''
          ) : (
            <div className="feildValue edit">
              <div
                className="editImg"
                onClick={() => editCapName(this.pageRequest, details?.cap_name, this.deviceId)}
              />
            </div>
          )}
        </div>

        {type === 'lte' || type === 'hub' ? (
          <React.Fragment>
            <div className="pathHr" />
            <div className="feildBox">
              {Strings.imei}: <div className="feildValue val">{details?.imei ? details?.imei : '-'}</div>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
        <div className="pathHr" />
        <div className="feildBox">
          {Strings.deviceId}: <div className="feildValue val">{details?.cap_id ? details?.cap_id : '-'}</div>
        </div>
        {type === 'lte' || type === 'lsc' || type === 'hub' ? (
          <React.Fragment>
            <div className="pathHr" />
            <div className="feildBox">
              {Strings.network}:{' '}
              <div
                className="feildValue val"
                data-tooltip-content={`${Strings.networkTime}: 
                ${
                  details?.last_network_connection_status
                    ? details?.last_network_connection_status !== 'OK'
                      ? details?.last_network_connection_status
                      : moment(details?.last_network_connection).format(DATE_MONTH_DAY_YEAR_WITH_DASH)
                    : 'N/A'
                }`}
                data-tooltip-id="tooltip"
              >
                {details?.last_network_operator ? details?.last_network_operator : '-'}
              </div>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </div>

      <div className="rowDirection left">
        {type === 'lte' || type === 'hub' ? (
          <React.Fragment>
            <div className="feildBox">
              {Strings.location}:{' '}
              <div className="feildValue val">
                {details?.location?.toString() ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://maps.google.com/?q=${getFullLocation(details?.location?.toString())}`}
                  >
                    {getLocation(details?.location?.toString())}
                  </a>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="pathHr" />
            <div className="feildBox">
              {Strings.signal}: <div className="feildValue val">{signalRender(details?.signal_strength)}</div>
            </div>
            <div className="pathHr" />
          </React.Fragment>
        ) : (
          ''
        )}
        <div className="feildBox">
          {Strings.battery.battery}: <div className="feildValue">{getCapChargeText(details?.battery)}</div>
        </div>
      </div>
    </div>
  );
};
class ShipperCapsDetails extends PureComponent {
  type = 'all';
  offset = 0;
  filterItems = [];
  plotDateRanges = [];
  selectedPlotDateRange = undefined;
  refreshEnable = true;
  state = {
    plotSelectedRefHourIndex: undefined,
    graphMinDate: undefined,
    graphMaxDate: undefined,
  };
  pageRequest = {};

  componentDidMount() {
    // due to the fact that we more than 1 route that leads to this component, they uses id as userId, but some of them it as deviceId.
    // if deviceId is present, we will use it instead of id.
    if (this.props.match.params?.deviceId) {
      this.deviceId = this.props.match.params?.deviceId;
    } else {
      this.deviceId = this.props.match.params.id;
    }

    this.loadAllData();
  }

  componentWillUnmount() {
    this.props.clearCapsDetails();
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  loadAllData() {
    const type = this.props.match.params.type;

    if (type !== 'hub') {
      this.props.getPlotScheduleDetail(this.deviceId).then(r => {
        this.startDate = r.response.start_date
          ? moment(r.response.start_date * 1000).startOf('day')
          : moment()
              .subtract(1, 'month')
              .startOf('day');
        this.endDate =
          !r.response.end_date || moment(r.response.end_date) > moment()
            ? moment().endOf('day')
            : moment(r.response.end_date * 1000).endOf('day');
        this.setState({ graphMinDate: this.startDate, graphMaxDate: this.endDate });
        this.loadPlot();
      });
    }

    this.props.getFillterData(type).then(r => {
      this.filterItems = getFilterItems(r.response.data);
      this.load();
    });
  }

  load() {
    this.pageRequest = {
      offset: this.offset,
      search: '',
      limit: PAGE_LIMIT,
      activityType: this.type,
    };

    this.props.getCapsDetails(this.deviceId, this.pageRequest);
  }

  loadPlot() {
    if (!this.startDate || !this.endDate) return;
    this.props.getPlotData(this.deviceId, this.startDate.format(), this.endDate.format());
  }

  onFiltersChange = _.debounce(() => {
    this.offset = 0;
    const type = getRelevantFilters(this.props.filters.filter(f => f.group === 'activityType'));
    if (type?.length > 0) {
      this.type = type.map(f => f.value).join(',');
    } else {
      this.type = 'all';
    }
    this.load();
  }, 1000);

  // for plots with selecting refHour
  _unused_onRefHourChange = value => {
    if (!isNaN(value)) {
      let refHours = cloneDeep(this.state.graphRefHours);
      refHours.forEach((rh, i) => {
        rh.selected = i.toString() === value.toString();
      });
      this.setState({ graphRefHours: refHours });
    }
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    this.offset = pagination.offset - PAGE_LIMIT;
    this.load();
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.offset = pagination.offset + PAGE_LIMIT;
    this.load();
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getCapsDetails(this.deviceId, this.pageRequest);
  };

  onCustomPage = page => {
    this.offset = (page - 1) * PAGE_LIMIT;
    this.load();
  };

  cityDisplay = value => {
    const location = value.split('"');
    const index = location.findIndex(e => e == 'city');
    if (index != -1) {
      return location[index + 2];
    }
    return '-';
  };

  getTimezoneOffsetStr = offset => {
    if (offset == null) {
      return Strings.na;
    }
    // -128 * 15 - special value for invalid timezone
    if (offset == -1920) {
      return Strings.invalidTimezone;
    }
    return `${offset > 0 ? '+' : ''}${offset} min`;
  };

  getHWValue(e) {
    if (e.activity_type == 'Signal strength') {
      return signalRender(e.activity_value);
    } else if (e.activity_type == 'Location') {
      const localizationStr = e.activity_value.city ? e.activity_value.city : '-';
      const operatorStr = e.activity_value.network_operator ? '(' + e.activity_value.network_operator + ')' : '';
      let offsetStr = '';
      if (isSuperUser() && (e.activity_value.used_timezone_offset || e.activity_value.network_timezone_offset)) {
        offsetStr = `offsetStr = [TZ: ${this.getTimezoneOffsetStr(
          e.activity_value.used_timezone_offset,
        )}, TZN: ${this.getTimezoneOffsetStr(e.activity_value.network_timezone_offset)}]`;
      }

      return localizationStr + ' ' + operatorStr + ' ' + offsetStr;
    }
    return isShipper() ? `${e.activity_value ?? ''} (${e.hw_code})` : e.activity_value;
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.loadAllData();
  };

  render() {
    const { details, isLoading, pagination, onAddNickName, plotData, plotScheduleData } = this.props;
    const { graphMinDate, graphMaxDate } = this.state;
    const { type } = this.props.match.params;

    const inner =
      isLoading && !this.refreshEnable ? (
        <div className="pagination-spinner-container">
          <div className="spinner" />
        </div>
      ) : (
        ''
      );

    return (
      <React.Fragment>
        {!this.props.noHeader && getCapHeader(details, type, onAddNickName, this.props.showBackButton)}
        {this.plotDateRanges && this.plotDateRanges.length > 0 && (
          <PageHeader
            noLeftPadding
            right={
              <React.Fragment>
                <Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />
              </React.Fragment>
            }
          />
        )}
        {inner}

        {plotData && (
          <div className={styles.chart}>
            <CapTakesPlot
              plotData={plotData}
              plotScheduleData={plotScheduleData}
              graphMinDate={graphMinDate}
              graphMaxDate={graphMaxDate}
              // onSelectRefHourIndex={this.onRefHourChange.bind(this)}
            />
          </div>
        )}
        <PageHeader
          noLeftPadding
          left={
            this.filterItems?.length > 0 && (
              <AdvancedFilters expandToRight>
                <Filter definition={getActivityTypeFilter(this.filterItems).activityType} />
              </AdvancedFilters>
            )
          }
          right={
            (!this.plotDateRanges || this.plotDateRanges.length == 0) && (
              <React.Fragment>
                <Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />
              </React.Fragment>
            )
          }
        />
        {this.filterItems?.length > 0 && <AdvancedFiltersBar customFilters={getActivityTypeFilter(this.filterItems)} />}

        <Table
          isLoading={isLoading}
          name="capsDetails"
          data={details?.activity ?? []}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={pagination}
          onSortClick={this.onSortClick}
        >
          <Column
            key="timestamp"
            sortKey="timestamp"
            title={Strings.date}
            value={e => {
              if (e.tzinfo === LOCAL_TIMEZONE) {
                return e.timestamp
                  ? moment
                      .unix(e.timestamp)
                      .tz(this.props.timezone)
                      .format(DATE_MONTH_DAY_YEAR)
                  : '-';
              }
              return e.timestamp ? moment.utc(moment.unix(e.timestamp)).format(DATE_MONTH_DAY_YEAR) : '-';
            }}
          />
          <Column
            key="timestampTime"
            title={Strings.time}
            value={e => {
              if (e.tzinfo === LOCAL_TIMEZONE) {
                return e.timestamp
                  ? moment
                      .unix(e.timestamp)
                      .tz(this.props.timezone)
                      .format(TIME_FORMAT_12_UPPERCASE)
                  : '-';
              }
              return e.timestamp ? moment.utc(moment.unix(e.timestamp)).format(TIME_FORMAT_12_UPPERCASE) : '-';
            }}
          />
          <Column
            key={Strings.eventType}
            title={Strings.eventType}
            value={e => (e.activity_type ? e.activity_type : '-')}
          />
          <Column
            key={Strings.value}
            title={Strings.value}
            value={e => {
              return this.getHWValue(e);
            }}
          />
        </Table>
      </React.Fragment>
    );
  }
}

ShipperCapsDetails.propTypes = {
  clearCapsDetails: PropTypes.func,
  details: PropTypes.shape({
    activity: PropTypes.array,
    battery: PropTypes.any,
    cap_id: PropTypes.shape({ toString: PropTypes.func }),
    cap_name: PropTypes.shape({ toString: PropTypes.func }),
    imei: PropTypes.any,
    location: PropTypes.shape({ toString: PropTypes.func }),
    signal_strength: PropTypes.any,
  }),
  getCapsDetails: PropTypes.func,
  getFillterData: PropTypes.func,
  getPlotData: PropTypes.func,
  getPlotScheduleDetail: PropTypes.func,
  isLoading: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      deviceId: PropTypes.any,
      id: PropTypes.any,
      type: PropTypes.string,
    }),
    url: PropTypes.shape({
      includes: PropTypes.func,
      replace: PropTypes.func,
    }),
  }),
  navigate: PropTypes.func,
  onAddNickName: PropTypes.func,
  pagination: PropTypes.shape({ offset: PropTypes.any }),
  plotData: PropTypes.any,
  plotScheduleData: PropTypes.any,
  filters: PropTypes.array,
  showBackButton: PropTypes.bool,
  noHeader: PropTypes.bool,
};

ShipperCapsDetails.defaultProps = { showBackButton: true, noHeader: false };

export default connect(mapStateToProps, mapDispatchToProps)(ShipperCapsDetails);
