export const UPDATE_ADVANCED_FILTER_STATE = 'update-advanced-filter-state';
export const CLEAR_FILTERS_ADVANCED_FILTER_STATE = 'clear-filters-advanced-filter-state';
export const CLEAR_ALL_ADVANCED_FILTER_STATE = 'clear-all-advanced-filter-state';
export const INITALIZE_ADVANCED_FILTER_STATE = 'initialize-advanced-filter-state';
export const INJECT_URL_ADVANCED_FILTER_STATE = 'inject-url-advanced-filter-state';

export const actionsAdvancedFilters = {
  updateFilterState: data => ({
    type: UPDATE_ADVANCED_FILTER_STATE,
    data,
  }),
  clearFilters: () => ({
    type: CLEAR_FILTERS_ADVANCED_FILTER_STATE,
  }),
  initializeFilterState: () => ({
    type: INITALIZE_ADVANCED_FILTER_STATE,
  }),
  urlInjected: () => ({
    type: INJECT_URL_ADVANCED_FILTER_STATE,
  }),
  clearAll: () => ({
    type: CLEAR_ALL_ADVANCED_FILTER_STATE,
  }),
};
