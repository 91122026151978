import { store } from './store';

export function getLanguage() {
  var lng = store?.getState()?.auth?.profile?.preferences?.language || 'en';
  return lng;
}

export const LS_LANGUAGE = 'PreferredLanguage';

export const supportedLanguages = [
  { id: 'en', values: ['en', 'english'] },
  { id: 'en-us', values: ['en-us'] },
  { id: 'es', values: ['es', 'spanish'] },
];

export const languageValueToId = value => {
  let lang = null;
  supportedLanguages.forEach(l => {
    if (l.values.includes(value.toLowerCase())) {
      lang = l.id;
    }
  });
  return lang;
};
