import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../actions/modal';
import { PageHeader } from '../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import { TextInput } from '../../components/PageHeader/TextInput';
import Select from '../../components/Select';
import { PAGE_LIMIT } from '../../constants';
import TableWithPagination, { Column } from '../../containers/Table/TableWithPagination';
import Strings from '../../Strings';
import { downloadFileOnly, getCapChargeText, convertUnixEpochToHumanReadable } from '../../utils';
import { isCapManager, isOrganizationManager, isShipper, isSuperUser, isOmron, isDoctor } from '../../utils/userRoles';
import { actions } from './redux/actions';
import { DOWNLOAD_REPORT_DATA_ERROR, ORGANIZATION_LIST_RESULT, SHIPPER_CAPS_RESULT } from './redux/constants';
import { ActionWidget, ActionButton, ItemSelectorHelper } from '../../components/ActionWidget';
import { notificationActions } from '../../components/Notification/redux/actions';
import AdvancedFilters, { Filter } from '../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersForRequest } from '../../components/AdvancedFilters/helpers';
import { getFiltersDefinition } from '../../components/AdvancedFilters/FiltersDefinition';
import { BLUETOOTH_CAP } from '../SuperUser/Patients/redux/constants';
import { DateFilters } from '../../utils/DateFilters';

const mapStateToProps = state => ({
  filters: state.entities.advancedFilters.filters.items,
  urlInjected: state.entities.advancedFilters.filters.urlInjected,
});

const mapDispatchToProps = dispatch => ({
  getOrgs: request => dispatch(actions.getOrgs(request)),
  getCaps: (pageRequest, signal) => dispatch(actions.getCaps(pageRequest, signal)),
  cancelGetCaps: controller => dispatch(actions.cancelGetCaps(controller)),
  downloadEvents: params => dispatch(actions.downloadReport(params)),
  downloadDetails: params => dispatch(actions.downloadDetails(params)),
  navigate: path => dispatch(push(path)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onAttachCapToAdmin: postAction =>
    dispatch(
      openModalAction('attach-cap-to-org', {
        postAction,
        type: BLUETOOTH_CAP,
      }),
    ),
  openDateRangeSelectionModal: (actionOnDone, downloadRequest, orgName) =>
    dispatch(
      openModalAction('date-range-selection-for-events-reports-modal', {
        actionOnDone,
        simplified: true,
        filters: downloadRequest,
        orgName,
      }),
    ),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

const all = 'all';
const no = 'no_org';

class BLE extends PureComponent {
  refreshEnable = true;
  state = {
    selectedOrg: all,
    openActionWidget: false,
    multiselectedItems: false,
    keyForItemCheckboxes: false,
    controller: undefined,
    firstRequestSent: false,
    caps: [],
    pagination: undefined,
    isLoading: false,
    organizations: [],
  };

  itemSelectorHelper = new ItemSelectorHelper();

  static propTypes = {
    downloadEvents: PropTypes.func,
    downloadDetails: PropTypes.func,
    getCaps: PropTypes.func,
    getOrgs: PropTypes.func,
    isLoading: PropTypes.bool,
    navigate: PropTypes.func,
    onAttachCapToAdmin: PropTypes.func,
    openConfirmModal: PropTypes.func,
    cancelGetCaps: PropTypes.func,
    openDateRangeSelectionModal: PropTypes.func,
    showNotification: PropTypes.func,
    filters: PropTypes.array,
    urlInjected: PropTypes.bool,
  };

  request = {
    limit: PAGE_LIMIT,
    offset: 0,
    search: '',
    filters: [],
  };

  componentDidMount() {
    this.props.getOrgs().then(resp => {
      if (resp?.type === ORGANIZATION_LIST_RESULT) {
        this.setState({ organizations: resp.response?.data });
      }
    });

    if (this.props.urlInjected) this.load();
  }

  componentDidUpdate(prevProps) {
    if (this.props.urlInjected !== prevProps.urlInjected && !this.state.firstRequestSent) this.load();

    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  load() {
    const { selectedOrg } = this.state;
    this.request.deviceType = 'ble';
    this.request.organizationId = '';
    if (selectedOrg !== all) {
      this.request.organizationId = this.state.selectedOrg;
    }

    let skipFirstRender = false;
    const urlParams = new URLSearchParams(window.location.search);
    const filtersSerialized = urlParams.get('filters');
    const filters = JSON.parse(filtersSerialized);
    if (Array.isArray(filters) && filters.length > 0 && this.state.firstRequestSent === false) {
      skipFirstRender = true;
    }
    this.setState({ firstRequestSent: true });

    if (!skipFirstRender) {
      const controller = new AbortController();
      this.setState({ controller, isLoading: true });
      this.props.getCaps(this.request, controller.signal).then(resp => {
        if (resp?.type === SHIPPER_CAPS_RESULT) {
          this.setState({
            caps: resp.response?.data,
            pagination: resp.response?.pagination,
          });
          this.resetActionWidget();
        }
        this.setState({ isLoading: false });
      });
    }
    this.resetActionWidget();
  }

  componentWillUnmount() {
    const { controller } = this.state;

    controller && this.props.cancelGetCaps(controller);
  }

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      openActionWidget: false,
    });
    this.itemSelectorHelper.clearItems();
  };

  onFiltersChange = filters => {
    return this.filtersDebounced(filters);
  };

  filtersDebounced = _.debounce(() => {
    const newFiltersForRequest = getFiltersForRequest(this.props.filters);
    if (JSON.stringify(newFiltersForRequest) !== JSON.stringify(this.request.filters)) {
      this.request.filters = newFiltersForRequest;
      this.request.offset = 0;
      this.load();
    }
  }, 1000);

  onRowSelection = id => {
    this.props.navigate(`/devices/ble/${this.state.caps[id].cap_id}/events`);
  };

  onPrevClick = () => {
    this.request.offset -= PAGE_LIMIT;
    if (this.request.offset < 0) this.request.offset = 0;
    this.load();
  };

  onNextClick = () => {
    this.request.offset += PAGE_LIMIT;
    this.load();
  };

  onSortClick = ({ sortKey, direction }) => {
    this.request.offset = 0;
    this.request.sortColumn = sortKey;
    this.request.sortType = direction;
    this.load();
  };

  onCustomPage = page => {
    this.request.offset = (page - 1) * PAGE_LIMIT;
    this.load();
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.request.offset = 0;
    this.request.search = query;
    this.load();
  }, 1000);

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.load();
  };

  onDownload = () => {
    const downloadHandler = params => {
      const paramsWithType = params;
      paramsWithType.deviceType = 'ble';

      switch (params.selectedOption) {
        case 'events':
          delete paramsWithType.selectedOption;
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        case 'details':
          delete paramsWithType.selectedOption;
          paramsWithType.limit = 100000;
          paramsWithType.offset = 0;
          this.props.downloadDetails(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        case 'vitals':
          delete paramsWithType.selectedOption;
          paramsWithType.simplified = true;
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        default:
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
      }
      this.props.showNotification('Download request sent, please wait...');
    };
    const downloadRequest = { ...this.request };
    delete downloadRequest.offset;
    delete downloadRequest.limit;
    if (!downloadRequest.lastUpdatedStart) {
      downloadRequest.lastUpdatedStart = DateFilters.AllTime.dates.startDate();
    }
    if (!downloadRequest.lastUpdatedEnd) {
      downloadRequest.lastUpdatedEnd = DateFilters.AllTime.dates.endDate();
    }

    let orgName = '';
    if (downloadRequest.organizationId) {
      orgName = this.state.organizations?.find(o => o.masked_id === downloadRequest.organizationId)?.name;
      if (downloadRequest.organizationId === no) {
        orgName = Strings.patientDashboard.noOrg;
      }
    }
    this.props.openDateRangeSelectionModal(downloadHandler, downloadRequest, orgName);
  };

  onDownloadBtnClickSuccessHandler = req => {
    if (req?.type === DOWNLOAD_REPORT_DATA_ERROR) {
      this.props.showNotification(req?.error);
    } else {
      const receivedName = req.headers['content-disposition'].split('filename=')[1];
      const fileName = `${moment().toString() + receivedName}`;
      downloadFileOnly(req, fileName);
    }
  };

  onOrgChange = option => {
    let value = option.value;
    this.request.offset = 0;
    this.setState({ selectedOrg: value }, () => {
      this.load();
    });
  };

  onCapsDelete = (event, cap_id) => {
    event.preventDefault();
    event.stopPropagation();

    const selected_device = this.state.caps.filter(hub => hub.cap_id === cap_id)?.pop();
    const request = {
      organization_id: selected_device?.organization?.masked_id,
      device_id: cap_id,
    };

    if (isCapManager() || isOrganizationManager()) {
      const data = {
        title: (
          <span>
            {Strings.deleteCapWarning} <b>{cap_id}</b>?
          </span>
        ),
        onConfirmAction: actions.deleteCapManagerCaps(request),
        onCancelAction: null,
        confirmPostAction: this.onRefresh,
      };
      this.props.openConfirmModal(data);
    } else {
      const data = {
        title: (
          <span>
            {Strings.deleteCapWarning} <b>{cap_id}</b> from organization <b>{selected_device?.organization?.name}</b>?
          </span>
        ),
        onConfirmAction: actions.deleteCapManagerCaps(request),
        confirmPostAction: this.onRefresh,
        onCancelAction: null,
      };
      this.props.openConfirmModal(data);
    }
  };

  onDateRangeChanged = option => {
    if (option) {
      this.request.lastUpdatedStart = option.dates.startDate();
      this.request.lastUpdatedEnd = option.dates.endDate();
    } else {
      this.request.lastUpdatedStart = '';
      this.request.lastUpdatedEnd = '';
    }
    this.request.offset = 0;

    this.load();
  };

  getHeaderComponents = () => {
    if (isCapManager() || isOrganizationManager()) {
      return (
        <React.Fragment>
          <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
          <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
          <Select
            data={[
              DateFilters.AllTime,
              DateFilters.Last48Hours,
              DateFilters.ThisMonth,
              DateFilters.Last30Days,
              DateFilters.LastMonth,
              DateFilters.Last6Months,
              DateFilters.ThisYear,
              DateFilters.Last12Months,
            ]}
            onChange={this.onDateRangeChanged}
            defaultValue={DateFilters.AllTime.value}
          />
          <AdvancedFilters>
            <Filter definition={getFiltersDefinition().Battery} />
            <Filter definition={getFiltersDefinition().Status} />
          </AdvancedFilters>
          <Button class="download" onClick={this.onDownload} />
        </React.Fragment>
      );
    } else if (isDoctor()) {
      return (
        <React.Fragment>
          <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
          <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
          <Select
            data={[
              DateFilters.AllTime,
              DateFilters.Last48Hours,
              DateFilters.ThisMonth,
              DateFilters.Last30Days,
              DateFilters.LastMonth,
              DateFilters.Last6Months,
              DateFilters.ThisYear,
              DateFilters.Last12Months,
            ]}
            onChange={this.onDateRangeChanged}
            defaultValue={DateFilters.AllTime.value}
          />
          <AdvancedFilters lastButton>
            <Filter definition={getFiltersDefinition().Battery} />
            <Filter definition={getFiltersDefinition().Status} />
          </AdvancedFilters>
          <Button class="download" onClick={this.onDownload} />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={this.onDateRangeChanged}
          defaultValue={DateFilters.AllTime.value}
        />
        <AdvancedFilters lastButton>
          <Filter definition={getFiltersDefinition().Battery} />
          <Filter definition={getFiltersDefinition().Status} />
        </AdvancedFilters>
      </React.Fragment>
    );
  };

  getSuperuserHeaderComponents = () => {
    const { onAttachCapToAdmin } = this.props;
    const { selectedOrg, organizations } = this.state;

    const data = [
      {
        value: all,
        label: Strings.patientDashboard.all,
      },
      {
        value: no,
        label: Strings.patientDashboard.noOrg,
      },
    ];

    if (organizations) {
      Object.values(organizations).forEach(organization => {
        data.push({
          value: organization.masked_id,
          label: organization.name,
        });
      });
    }
    return (
      <React.Fragment>
        <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Select data={data} value={selectedOrg} onChange={this.onOrgChange} isSearchable />
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={this.onDateRangeChanged}
          defaultValue={DateFilters.AllTime.value}
        />
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          onClick={() => onAttachCapToAdmin(this.onRefresh)}
          title={Strings.attachCap}
        />
        <AdvancedFilters lastButton>
          <Filter definition={getFiltersDefinition().Battery} />
          <Filter definition={getFiltersDefinition().Status} />
        </AdvancedFilters>

        <Button class="download" onClick={this.onDownload} />
      </React.Fragment>
    );
  };

  itemChecked = ({ target }) => {
    if (target.checked === true) {
      this.itemSelectorHelper.addItem(target.id);
    } else {
      this.itemSelectorHelper.removeItem(target.id);
    }

    if (this.itemSelectorHelper.getItems().length !== 0) {
      this.setState({ openActionWidget: true });
    } else {
      this.setState({ openActionWidget: false });
    }

    this.setState({ multiselectedItems: this.itemSelectorHelper.isMultiselect() });
  };

  render() {
    const { pagination, isLoading, caps } = this.state;
    const columns = [];

    columns.push(
      <Column
        key="capid"
        title={Strings.deviceId}
        value={e => (
          <div className="cell-with-select">
            <div className="selector">
              <input
                type="checkbox"
                className="item-checkbox"
                id={e.cap_id}
                onChange={this.itemChecked}
                key={this.state.keyForItemCheckboxes}
              />
            </div>
            <div className="selector-label">{e.cap_id}</div>
          </div>
        )}
      />,
    );
    columns.push(<Column key="capShortName" title={Strings.deviceName} value={e => e.cap_name || '-'} />);

    columns.push(<Column key="kit_id" title={Strings.kitId} value={e => e.kit_id || '-'} />);

    if (isShipper() || isSuperUser()) {
      columns.push(
        <Column key="organization" title={Strings.organizationName} value={e => e.organization?.name || '-'} />,
      );
    }
    columns.push(
      <Column
        key="last_updated_at"
        title={Strings.lastActivity}
        value={e => (e.last_updated_at ? convertUnixEpochToHumanReadable(e.last_updated_at) : '-')}
        sortKey="last_updated_at"
      />,
    );
    columns.push(
      <Column
        key="eventTime"
        title={Strings.lastEventTime}
        value={e => (e.last_vital_at ? convertUnixEpochToHumanReadable(e.last_vital_at) : '-')}
      />,
    );
    columns.push(<Column key="charge" title={Strings.battery.battery} value={d => getCapChargeText(d.battery)} />);

    const actionBtns = (
      <React.Fragment>
        <ActionButton img="action-led" tooltiptext="LED" text="LED" action={() => {}} disabled />
        <ActionButton img="action-reminder" tooltiptext="Reminder" text="Reminder" action={() => {}} disabled />
        <ActionButton img="action-flightmode" tooltiptext="Flight mode" text="Flight mode" action={() => {}} disabled />
        <ActionButton img="action-download" tooltiptext="Download" text="Download" action={() => {}} disabled />
        {!isOmron() ? (
          <ActionButton
            img="action-delete"
            tooltiptext="Delete"
            text="Delete"
            action={e => {
              this.onCapsDelete(e, this.itemSelectorHelper.getFirstItem());
            }}
            disabled={this.state.multiselectedItems}
          />
        ) : null}
      </React.Fragment>
    );

    const headerTitle =
      !isLoading && pagination && caps
        ? Strings.formatString(
            Strings.showingXDevices,
            caps.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
          )
        : Strings.showingWait;

    return (
      <div>
        <PageHeader
          isBlack
          left={headerTitle}
          right={isShipper() || isSuperUser() ? this.getSuperuserHeaderComponents() : this.getHeaderComponents()}
        />
        <AdvancedFiltersBar />
        <TableWithPagination
          isLoading={isLoading}
          name="caps"
          uuid="3f405e5d-78de-4a99-91e0-c55ea95bc959"
          data={caps}
          onRowClick={this.onRowSelection}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={pagination}
          onSortClick={this.onSortClick}
          enableColumnFiltering
        >
          {columns}
        </TableWithPagination>
        <ActionWidget show={this.state.openActionWidget}>{actionBtns}</ActionWidget>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BLE);
