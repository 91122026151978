import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '../PageHeader/Button';
import './AdvancedFilters.scss';
import Strings from '../../Strings';
import { actionsAdvancedFilters } from './actions';
import SideList from './internals/SideList';
import Checkbox from './internals/Checkbox';
import Input from './internals/Input';

function Filter(props) {
  const { definition } = props;
  return (
    <SideList title={definition.text} {...props}>
      {definition.type === 'number' ? (
        <Input filterDefinition={definition} {...props} />
      ) : (
        definition.values.map(e => (
          <Checkbox text={e.text} group={definition.group} value={e.value} {...props} key={e.value}>
            {definition.itemRender ? definition.itemRender(e) : null}
          </Checkbox>
        ))
      )}
    </SideList>
  );
}

Filter.propTypes = {
  definition: PropTypes.object.isRequired,
  firmwares: PropTypes.array,
};

function AdvancedFilters(props) {
  const [show, setShow] = useState(false);
  const [lastOpenedChild, setLastOpenedChild] = useState('');

  const initializeFilters = () => {
    const { children, updateFilterState } = props;

    const childrenArray = Array.isArray(children) ? children : [children];

    // lets take a look which children we have and initialize all needed filters
    if (childrenArray) {
      childrenArray.forEach(child => {
        const group = child.props.definition.group;
        if (child.props.definition.type === 'number') {
          updateFilterState({
            group,
            value: group,
            state: '',
          });
        } else {
          child.props.definition.values.forEach(e => {
            updateFilterState({
              group,
              value: e.value,
              state: false,
            });
          });
        }
      });
    }
    props.initializeFilterState();
  };

  useEffect(() => {
    initializeFilters();
  }, []);

  const blurHandler = event => {
    // if Click is outside of this list components, lets close it, mean that we lost focus
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShow(false);
    }
  };

  // callback for childrens to inform about changes of thier open state
  const notifWhoOpened = e => {
    setLastOpenedChild(e);
  };

  const getChildren = () => {
    const { children } = props;

    // add callback to childs and pass infomation about last opened child and ptr to stateObserver,
    // check if as childrens we get array (multiple childs) or just one child
    if (Array.isArray(children)) {
      return props.children?.map((child, i) => {
        return React.cloneElement(child, {
          notifWhoOpened: e => notifWhoOpened(e),
          lastOpenedChild,
          key: i,
        });
      });
    } else if (children) {
      return React.cloneElement(props.children, {
        notifWhoOpened: e => notifWhoOpened(e),
        lastOpenedChild,
      });
    }
  };

  const dropdownList = (
    <div key="dropdownList" className="dropdown-list">
      {getChildren() || <React.Fragment />}
    </div>
  );

  const isDisabled = !props.children;

  const button = (
    <Button
      class="addfilter"
      title={Strings.addFilter}
      onClick={() => {
        setShow(!show);
      }}
      disabled={isDisabled}
      testid="add-filter"
    />
  );

  return (
    <div
      className={`advanced-filters${props.expandToRight ? ' right-expanding' : ''}`}
      tabIndex={1}
      onBlur={e => blurHandler(e)}
    >
      {button}
      {show && dropdownList}
    </div>
  );
}

AdvancedFilters.propTypes = {
  children: PropTypes.any,
  updateFilterState: PropTypes.func,
  initializeFilterState: PropTypes.func,
  expandToRight: PropTypes.bool,
};
AdvancedFilters.defaultProps = { expandToRight: false };

const mapDispatchToProps = dispatch => ({
  updateFilterState: state => dispatch(actionsAdvancedFilters.updateFilterState(state)),
  initializeFilterState: () => dispatch(actionsAdvancedFilters.initializeFilterState()),
});

export default connect(null, mapDispatchToProps)(AdvancedFilters);
export { Filter };
