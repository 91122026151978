import {
  GET_DEVICE_EVENTS,
  GET_DEVICE_EVENTS_ERROR,
  GET_DEVICE_EVENTS_RESULT,
  GET_ORAGANIZATION_DEVICES_BILLING,
  GET_ORAGANIZATION_DEVICES_BILLING_ERROR,
  GET_ORAGANIZATION_DEVICES_BILLING_RESULT,
  GET_TASK_BILLING,
  GET_TASK_BILLING_ERROR,
  GET_TASK_BILLING_RESULT,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_ERROR,
  GET_TASK_DETAILS_RESULT,
} from './constants';

export const reducer = (state = null, action) => {
  switch (action.type) {
    case GET_ORAGANIZATION_DEVICES_BILLING:
    case GET_TASK_BILLING:
    case GET_DEVICE_EVENTS:
    case GET_TASK_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORAGANIZATION_DEVICES_BILLING_ERROR:
    case GET_TASK_BILLING_ERROR:
    case GET_TASK_DETAILS_ERROR:
    case GET_DEVICE_EVENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        billing: [],
      };
    case GET_ORAGANIZATION_DEVICES_BILLING_RESULT:
      return {
        ...state,
        devices: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case GET_TASK_BILLING_RESULT:
      return {
        ...state,
        tasks: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case GET_TASK_DETAILS_RESULT:
      return {
        ...state,
        taskDetails: action.response.data,
        pagination: action.response.pagination,
        patientInfo: action.response.patientInfo,
        isLoading: false,
      };

    case GET_DEVICE_EVENTS_RESULT:
      return {
        ...state,
        deviceEvents: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    default:
      return state;
  }
};
