import { createMigrate } from 'redux-persist';
import { storage } from '../persistStorage';
import { defaultPalette } from '../../pages/Dashboards/DashboardColorPalettes';

const removeIsResizableTrue = layout => {
  return layout.map(({ isResizable, ...widgetProps }) => {
    if (isResizable === false) {
      return { ...widgetProps, isResizable: false };
    }
    return widgetProps;
  });
};

const migrations = {
  0: previousVersionState => ({
    dashboardFilters: previousVersionState.dashboardFilters,
    dashboardLayouts: Object.entries(previousVersionState.dashboardLayouts).map(([dashboardId, layouts]) => {
      return {
        [dashboardId]: {
          l: removeIsResizableTrue(layouts.l),
          m: removeIsResizableTrue(layouts.m),
          s: removeIsResizableTrue(layouts.s),
        },
      };
    }),
  }),
  1: previousVersionState => ({
    dashboardFilters: previousVersionState.dashboardFilters,
    dashboardLayouts: previousVersionState.dashboardLayouts,
    configuration: { allDashboards: { colorPalette: defaultPalette } },
  }),
};

export const dashboardStateConfig = {
  key: 'dashboardState',
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  blacklist: ['editMode'],
};
