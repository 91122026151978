import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _, { isNumber } from 'lodash';
import moment from 'moment-timezone';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { BILLABLE_TIME_THRESHOLD, PAGE_LIMIT, RPM_PATIENT_STATUS, RPM_PORTAL_ACCESS_STATUS } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getSchedulesIcons, getVitalsIcons, lastActivityDisplay, makeValid, showStatus } from '../../../utils';
import './patient.scss';
import { actions } from './redux/actions';
import { actions as cohortActions } from './Cohorts/redux/actions';
import { EDIT_PATIENT, PATIENT_LIST_FOR_SUGGESTION } from './redux/constants';
import { patientAction } from '../../../actions/patient';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getRelevantFilters } from '../../../components/AdvancedFilters/helpers';
import { getProgramsText } from '../../../utils/cmsPrograms';
import { EnrollPatientModalPages } from '../../../modals/_ModalsMetadata/ModalsMetadata';
import { UPDATE_PATIENT_SCHEDULE } from '../../../actions/action-types';
import { FacilityFilter } from './Cohorts/Cohorts';
import { sendRestPasswordMessage } from '../../../actions/auth';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import { getFiltersDefinition } from '../../../components/AdvancedFilters/FiltersDefinition';

function PatientList(props) {
  const searchTime = React.useRef(null);
  const pageRequest = React.useRef({
    offset: 0,
    search: '',
    lastReadings: true,
    missedToday: true,
  });

  React.useEffect(() => {
    props.getFacilities();
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const filtersSerialized = JSON.parse(urlParams.get('filters'));
    if (!filtersSerialized || filtersSerialized.length === 0) {
      load();
    }
    return () => {
      props.clearData();
    };
  }, [props.cohortId, props.refreshTimestamp]);

  React.useEffect(() => {
    const facilities = getRelevantFilters(props.filters.filter(f => f.group === 'facilityId'));
    const facilityQueryString = facilities?.length > 0 ? facilities.map(f => f.value).join(',') : undefined;

    const noReadingsSince = getRelevantFilters(props.filters.filter(f => f.group === 'NoReadingsSince'));
    const noReadingsSinceValue =
      noReadingsSince?.length > 0
        ? moment()
            .subtract(noReadingsSince[noReadingsSince.length - 1].state, 'days')
            .startOf('day')
            .format()
        : undefined;
    if (
      pageRequest.current.facilityId !== facilityQueryString ||
      pageRequest.current.noReadingsSince !== noReadingsSinceValue
    ) {
      onFiltersChange();
    }
  }, [props.filters]);

  const load = () => {
    const { getPatients, cohortId } = props;

    if (props.cohortId) pageRequest.current.cohortId = cohortId;
    else delete pageRequest.current.cohortId;

    getPatients(pageRequest.current);
  };

  const onFiltersChange = _.debounce(() => {
    pageRequest.current.offset = 0;
    const facilities = getRelevantFilters(props.filters.filter(f => f.group === 'facilityId'));
    if (facilities?.length > 0) {
      pageRequest.current.facilityId = facilities.map(f => f.value).join(',');
    } else {
      delete pageRequest.current.facilityId;
    }
    const noReadingsSince = getRelevantFilters(props.filters.filter(f => f.group === 'NoReadingsSince'));
    if (noReadingsSince?.length > 0) {
      pageRequest.current.noReadingsSince = moment()
        .subtract(noReadingsSince[noReadingsSince.length - 1].state, 'days')
        .startOf('day')
        .format();
    } else {
      delete pageRequest.current.noReadingsSince;
    }
    pageRequest.current.offset = 0;
    load();
  }, 1000);

  const onSearchQueryChange = query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    clearTimeout(searchTime.current);
    searchTime.current = setTimeout(() => {
      props.getPatients(pageRequest.current);
    }, 1000);
  };

  const getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput class="" placeholder={Strings.search} onChange={e => onSearchQueryChange(e.target.value)} />
        {props.facilities?.length > 0 && (
          <AdvancedFilters>
            <Filter definition={FacilityFilter(props.facilities).facilityId} />
            <Filter definition={getFiltersDefinition().NoReadingsSince} />
          </AdvancedFilters>
        )}
      </React.Fragment>
    );
  };

  const onPrevClick = () => {
    const { pagination } = props;
    const offset = pagination.offset - PAGE_LIMIT;
    pageRequest.current.offset = offset;
    props.getPatients(pageRequest.current);
  };

  const onCustomPage = page => {
    pageRequest.current.offset = (page - 1) * PAGE_LIMIT;
    props.getPatients(pageRequest.current);
  };

  const onNextClick = () => {
    const { pagination } = props;
    const offset = pagination.offset + PAGE_LIMIT;
    pageRequest.current.offset = offset;
    props.getPatients(pageRequest.current);
  };

  const onSortClick = ({ sortKey, direction }) => {
    pageRequest.current.offset = 0;
    pageRequest.current.sortColumn = sortKey;
    pageRequest.current.sortType = direction;
    props.getPatients(pageRequest.current);
  };

  const onPatientSelected = id => {
    const { patients } = props;
    if (patients && patients[id].id) {
      const data = patients[id];
      const maskedId = encodeURIComponent(data.id);
      props.onNavigate(`/cap-patients/${maskedId}`);
    }
  };

  const editPatient = id => {
    props.onEditPatient(props.patients[id], load);
  };

  const sendSMS = id => {
    props.onSendMessage(props.patients[id], props.getPatientsForSuggestion, PATIENT_LIST_FOR_SUGGESTION);
  };

  const deletePatient = id => {
    const { patients, pagination } = props;
    const deleteData = { maskedId: patients[id].masked_id, allowBilled: true };
    const data = {
      title: (
        <span>
          {Strings.deletePatientWarning}: <b>{patients[id].patientName}</b>? <br />{' '}
          {Strings.warnigs.deletePatientWarning}
        </span>
      ),

      onConfirmAction: actions.deletetPatient(deleteData, patients[id].id),
      onCancelAction: null,
      confirmPostAction: () => {
        if (pagination.totalRecords - pagination.offset === 1) {
          pageRequest.current.offset -= PAGE_LIMIT;
        }
        load();
      },
    };
    props.openConfirmModal(data);
  };

  const enrollPatient = id => {
    const kitOnly = props.patients[id].status === 'verified';
    const messagePageVisible = props.enrollPagesVisibility?.find(
      p => p.id === EnrollPatientModalPages.welcomeMessage.id,
    )?.visible;
    props.onEnrollPatient(
      props.patients[id],
      () => load(),
      kitOnly
        ? messagePageVisible
          ? [EnrollPatientModalPages.kit, EnrollPatientModalPages.welcomeMessage]
          : [EnrollPatientModalPages.kit]
        : [],
      kitOnly,
    );
  };

  const endEnrollment = id => {
    props.onEnrollPatient(props.patients[id], () => load(), [EnrollPatientModalPages.programs], false);
  };

  const addMedicine = id => {
    const patientDetails = props.patients[id];
    const defaultEnrollmentId = patientDetails?.patientEnrollment?.enrollments
      ? Object.entries(patientDetails.patientEnrollment.enrollments).find(
          ([key, v]) => v.program === patientDetails.patientEnrollment.defaultProgram,
        )?.[0]
      : null;
    props.onAddMedicine(patientDetails, defaultEnrollmentId, patientDetails.status === 'enrolled');
  };

  const onPatientsUnassignById = id => {
    unassignPatient(props.patients[id]);
  };

  const unassignPatient = patient => {
    const cohort = props;
    const data = {
      title: (
        <span>
          {Strings.unassignPatientWarning} <b>{patient.patientName}</b>?
        </span>
      ),

      onConfirmAction: cohortActions.unassignPatientFromCohort(patient.id, cohort.cohortId),
      confirmPostAction: () => {
        load();
      },
      caption: Strings.removePatient,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const isPatientEnrolled = id => {
    return props.patients[id]?.status === RPM_PATIENT_STATUS.enrolled;
  };

  const isEnrollmentEndDateSet = patient => {
    return (
      patient.patientEnrollment?.enrollments &&
      Object.values(patient.patientEnrollment?.enrollments)?.some(e => e.enrollmentEnd)
    );
  };

  const inviteToPortal = patient => {
    const data = {
      title: <span>{Strings.formatString(Strings.invitePatientWarning, patient?.patientName)}</span>,
      caption: Strings.invitePatient,
      onConfirmAction: actions.invitePatientToPortal(patient.id, 'email'),
      onCancelAction: null,
      confirmPostAction: () => load(),
      onSuccessNotification: Strings.invitationSuccess,
      hideCaution: true,
    };
    props.openConfirmModal(data);
  };

  const resetPassword = patient => {
    const data = {
      title: (
        <span>
          {Strings.warnigs.adminResetPassword} <b>{patient.patientName}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: sendRestPasswordMessage({
        method: 'email',
        email: patient.email,
      }),
      onCancelAction: null,
      caption: 'Confirm password reset',
    };

    props.openConfirmModal(data);
  };

  const getEnrollmentStatus = status => {
    switch (status) {
      case 'enrolled':
        return Strings.capPatient.patientStatus.enrolled;
      case 'onboarded':
        return Strings.capPatient.patientStatus.onboarded;
      case 'verified':
        return Strings.capPatient.patientStatus.verified;
      default:
        return '-';
    }
  };

  const getAlerts = alerts => {
    let alertsCount = 0;
    if (alerts) {
      alertsCount = Object.values(alerts).filter(v => isNumber(v))?.length;
      alertsCount += alerts.schedule_missed_doses_alerts?.length || 0;
    }
    return (
      <div className="icon-and-text-container">
        <div className={`icon-container ${alertsCount > 0 ? 'alert' : 'tick grey'}`} />
        {/* {alertsCount} */}
      </div>
    );
  };

  const getBillableTime = time => {
    const time_m = moment.utc(time * 1000);
    const color = time < BILLABLE_TIME_THRESHOLD ? 'red' : 'grey';
    return (
      <div className="icon-and-text-container">
        <div className={`billable-time-dot ${color}`} />
        {time_m.format('HH:mm:ss')}
      </div>
    );
  };

  const getInbox = hasUnreadConversations => (
    <div className="icon-and-text-container">
      <div className={`icon-container envelope ${hasUnreadConversations ? 'unread' : ''}`} />
    </div>
  );

  const { isLoading, patients, pagination, cohortId, selectedFacilities, facilities } = props;

  const columns = [];

  columns.push(<Column key="mrn" sortKey="mrn" title={Strings.capPatient.mrnNumber} value={e => e.mrn} />);
  columns.push(
    <Column
      key="patientName"
      sortKey="patientName"
      title={Strings.capPatient.patientName}
      value={d => makeValid(d.patientName)}
    />,
  );
  columns.push(<Column key="status" title={Strings.status} value={d => getEnrollmentStatus(d.status)} />);
  if (hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS)) {
    columns.push(<Column key="portal_access" title={Strings.portalAccess} value={d => showStatus(d.portalAccess)} />);
    columns.push(<Column key="last_login" title={Strings.lastLogin} value={d => lastActivityDisplay(d.last_login)} />);
  }
  columns.push(<Column key="program" title={Strings.program} value={d => getProgramsText(d.patientEnrollment)} />);
  columns.push(
    <Column key="readings" title={Strings.latestReadings} value={d => getVitalsIcons(d.lastReadings, d.id)} />,
  );
  columns.push(
    <Column
      key="schedules"
      title={Strings.todaysMedications}
      value={d => getSchedulesIcons(d.todaysMedications, d.lastReadings?.alerts?.schedule_missed_doses_alerts)}
    />,
  );
  columns.push(<Column key="alerts" title={Strings.alerts} value={d => getAlerts(d.lastReadings?.alerts)} />);
  columns.push(<Column key="inbox" title={Strings.messages.inbox} value={d => getInbox(d.unreadConversations)} />);
  columns.push(
    <Column
      key="billing_time"
      title={Strings.timeThisMonth}
      value={d => getBillableTime(d.lastReadings?.billable_time)}
    />,
  );
  columns.push(<Column key="kitId" title={Strings.kitId} value={d => d.kitId} />);
  columns.push(<Column key="cohort" title={Strings.cohort} value={d => d.cohortName} />);
  columns.push(<Column key="facility" title={Strings.facility} value={d => d.facilityName} />);
  columns.push(<Column key="primaryPhoneNo" title={Strings.capPatient.phoneNumber} value={d => d?.primaryPhoneNo} />);
  columns.push(
    <Column key="textPhoneNo" title={Strings.capPatient.textNumber} value={d => makeValid(d.textPhoneNo)} />,
  );
  columns.push(<Column key="email" title={Strings.capPatient.email} value={d => makeValid(d.email)} />);
  columns.push(
    <Column key="organType" title={Strings.capPatient.organType} value={d => d.transplantDetails?.[0]?.organType} />,
  );
  columns.push(
    <Column key="insuranceName" title={Strings.capPatient.insurance} value={d => d.insurance?.[0]?.insuranceName} />,
  );
  columns.push(<Column key="policyId" title={Strings.capPatient.policyId} value={d => d.insurance?.[0]?.policyId} />);
  columns.push(
    <Column
      key="clinicContact"
      title={Strings.capPatient.clinicContact}
      value={d => d.centerDetails?.[0]?.clinicContact}
    />,
  );

  const buttons = [];
  if (hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS)) {
    buttons.push({
      icon: (id, patient) => {
        return patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.noAccess
          ? 'invite'
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.invited
          ? 'invite'
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.hasAccess
          ? 'refresh'
          : '';
      },
      onClick: (id, patient) => {
        switch (patient?.portalAccess) {
          case RPM_PORTAL_ACCESS_STATUS.noAccess:
          case RPM_PORTAL_ACCESS_STATUS.invited:
            inviteToPortal(patient);
            break;
          case RPM_PORTAL_ACCESS_STATUS.hasAccess:
            resetPassword(patient);
            break;
          default:
            break;
        }
      },
      text: (id, patient) => {
        return patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.noAccess
          ? Strings.inviteToPortal
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.invited
          ? Strings.reInviteToPortal
          : patient?.portalAccess === RPM_PORTAL_ACCESS_STATUS.hasAccess
          ? Strings.resetPassword
          : '';
      },
    });
  }

  buttons.push({
    icon: 'enroll',
    onClick: id => {
      isPatientEnrolled(id) ? endEnrollment(id) : enrollPatient(id);
    },
    text: id => {
      return props.patients[id]?.status === RPM_PATIENT_STATUS.enrolled && isEnrollmentEndDateSet(props.patients[id])
        ? Strings.changeEnrollmentEndDate
        : props.patients[id]?.status === RPM_PATIENT_STATUS.enrolled
        ? Strings.setEnrollmentEndDate
        : props.patients[id]?.status === RPM_PATIENT_STATUS.verified
        ? Strings.continueEnrollment
        : Strings.enroll;
    },
  });
  buttons.push({
    icon: 'addMedication',
    onClick: addMedicine,
    text: Strings.addMedicationText,
  });
  buttons.push({
    icon: 'message',
    onClick: sendSMS,
    text: Strings.sendSMS,
  });
  buttons.push({
    icon: 'edit',
    onClick: editPatient,
    text: Strings.edit,
  });
  buttons.push({
    icon: 'delete',
    onClick: cohortId ? onPatientsUnassignById : deletePatient,
    text: cohortId ? Strings.capPatient.unassignFromCohort : Strings.delete,
  });

  const headerTitle =
    !isLoading && pagination
      ? Strings.formatString(
          Strings.showingXPatients,
          patients?.length || 0,
          pagination.totalRecords ? pagination.totalRecords : 0,
        )
      : Strings.showingWait;

  return (
    <React.Fragment>
      <div className="details-container">
        <PageHeader right={() => getHeaderComponents()} left={headerTitle} noLeftPadding />
        {props.facilities && <AdvancedFiltersBar customFilters={FacilityFilter(props.facilities)} />}
        <Table
          className="schedules-table"
          isLoading={isLoading}
          name="organizations"
          uuid="703c850e-af80-4550-9e86-a0f35bad91c1"
          data={patients || []}
          onRowSelection={onPatientSelected}
          onPrevClick={onPrevClick}
          onSortClick={onSortClick}
          onNextClick={onNextClick}
          onCustomPage={onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          buttons={buttons}
          enableColumnFiltering
        >
          {columns}
        </Table>
      </div>
    </React.Fragment>
  );
}

PatientList.propTypes = {
  getPatients: PropTypes.func,
  isLoading: PropTypes.any,
  onEditPatient: PropTypes.func,
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patients: PropTypes.array,
  filters: PropTypes.array,
  facilities: PropTypes.array,
  cohortId: PropTypes.string,
  refreshTimestamp: PropTypes.any,
  clearData: PropTypes.func,
  onEnrollPatient: PropTypes.func,
  enrollPagesVisibility: PropTypes.array,
  onSendMessage: PropTypes.func,
  getPatientsForSuggestion: PropTypes.func,
  onAddMedicine: PropTypes.func,
  getFacilities: PropTypes.func,
  filters: PropTypes.array.isRequired,
  urlInjected: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    patients: patients && patients?.data?.data,
    isLoading: patients && patients?.isLoading,
    pagination: patients && patients?.data?.pagination,
    facilities: state.superUser.cohorts?.facilities,
    filters: state.entities.advancedFilters.filters.items,
    urlInjected: state.entities.advancedFilters.filters.urlInjected,
    enrollPagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === 'enroll-patient').pages,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getPatients: pageRequest => dispatch(actions.getPatients(pageRequest)),
  getPatientsForSuggestion: pageRequest => dispatch(actions.getPatientsForSuggestion(pageRequest)),
  clearData: () => dispatch(actions.clearPatients()),
  onEditPatient: (patient, nextAction) =>
    dispatch(
      openModalAction('edit-rpm-patient', {
        action: actions.editPatient,
        actionType: EDIT_PATIENT,
        nextAction,
        patient,
      }),
    ),
  onEnrollPatient: (patient, nextAction, pages, ignoreFilters) =>
    dispatch(
      openModalAction('enroll-patient', {
        patient,
        onSuccess: nextAction,
        pages,
        ignoreFilters,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onSendMessage: (patient, loadOptionsAction, loadOptionsActionType) =>
    dispatch(
      openModalAction(
        'send-message',
        {
          patient,
          loadRecipients: loadOptionsAction,
          loadRecipientsActionType: loadOptionsActionType,
        },
        patient.id,
      ),
    ),
  onAddMedicine: (patient, enrollmentId, logTask) =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient,
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        logTask,
        enrollmentId,
      }),
    ),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
