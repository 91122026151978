import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Strings from '../../../Strings';
import { isShipper, isSuperUser } from '../../../utils/userRoles';
import TabView, { Tab } from '../../../components/TabView';
import HubDevices from './HubDevices';
import DeviceStatusHistory from './DeviceStatusHistory';
import ShipperCapsDetails, { getCapHeader } from '../../ShipperCapsDetails';
import { actions } from '../../ShipperCapsDetails/redux/actions';

export const CAP_EVENTS_TAB = Strings.events;
export const CAP_STATUS_HISTORY_TAB = Strings.history;
export const HUB_DEVICES_TAB = Strings.devices;

function CapDetails(props) {
  const capId = encodeURIComponent(props.match.params.id);
  const type = encodeURIComponent(props.match.params.type);
  const [capInfo, setCapInfo] = React.useState({});

  React.useEffect(() => {
    const pageRequest = {
      offset: 0,
      search: '',
      filterBy: '',
      limit: 1,
    };
    props.getCapsDetails(capId, pageRequest).then(resp => {
      if (resp?.response) {
        setCapInfo(resp.response);
      }
    });
  }, [capId]);

  const rootPath = `/devices/${type}/${capId}`;
  const tabs = [];
  if (type === 'hub') {
    tabs.push(
      <Tab name={HUB_DEVICES_TAB} path={`${rootPath}/devices`} key={HUB_DEVICES_TAB}>
        <HubDevices {...props} noHeader />
      </Tab>,
    );
  }
  tabs.push(
    <Tab name={CAP_EVENTS_TAB} path={`${rootPath}/events`} key={CAP_EVENTS_TAB}>
      <ShipperCapsDetails {...props} noHeader />
    </Tab>,
  );

  if (isSuperUser() || isShipper()) {
    tabs.push(
      <Tab name={CAP_STATUS_HISTORY_TAB} path={`${rootPath}/history`} key={CAP_STATUS_HISTORY_TAB}>
        <DeviceStatusHistory {...props} />
      </Tab>,
    );
  }

  return (
    <React.Fragment>
      {getCapHeader(capInfo, type, props.editCapName, true, 'white')}
      <TabView activeTab={props.activeTab} routeMode>
        {tabs}
      </TabView>
    </React.Fragment>
  );
}

CapDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.any }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  capInfo: PropTypes.shape({
    signal_strength: PropTypes.string,
    battery: PropTypes.number,
  }),
  activeTab: PropTypes.string,
  getCapsDetails: PropTypes.func,
  editCapName: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  getCapsDetails: (id, pageRequest) => dispatch(actions.getCapsDetails(id, pageRequest)),
  editCapName: (pageRequest, capName, id) =>
    dispatch(
      openModalAction('add-nick-name', {
        action: actions.addNickName,
        actionType: ADD_NICK_NAME,
        id,
        nextAction: actions.getCapsDetails,
        pageRequest,
        capName,
        allowEmpty: true,
      }),
    ),
});

export default connect(null, mapDispatchToProps)(CapDetails);
