const LS_SELECTED_COLOR_THEME = 'selected-color-theme';

export function switchTheme() {
  const currentColor = document.documentElement.getAttribute('data-theme');
  if (currentColor === 'blue') {
    document.documentElement.setAttribute('data-theme', 'red');
  } else {
    document.documentElement.setAttribute('data-theme', 'blue');
  }
  if (localStorage) {
    try {
      localStorage.setItem(LS_SELECTED_COLOR_THEME, currentColor === 'blue' ? 'red' : 'blue');
    } catch (e) {}
  }
}

export function setTheme(theme) {
  document.documentElement.setAttribute('data-theme', theme);
  if (localStorage) {
    try {
      localStorage.setItem(LS_SELECTED_COLOR_THEME, theme);
    } catch (e) {}
  }
}

export function setThemeFromLS() {
  let theme = 'red';
  try {
    theme = localStorage.getItem(LS_SELECTED_COLOR_THEME) || 'blue';
  } catch (e) {}
  document.documentElement.setAttribute('data-theme', theme);
}

export function getColorsForColorTheme() {
  const theme = document.documentElement.getAttribute('data-theme');
  switch (theme) {
    case 'red':
      return { themeName: 'red', mainColor: '#AB3439' };
    case 'blue':
      return { themeName: 'blue', mainColor: '#0460A9' };
    case 'white':
      return { themeName: 'white', mainColor: '#0060A8' };
    default:
      return { themeName: 'red', mainColor: '#0460A9' };
  }
}
