import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment, { now } from 'moment-timezone';

import DashboardLayout from './DashboardLayout';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import Strings from '../../Strings';
import PendingCallsMessages from './Widgets/PendingCallsMessages';
import PatientsEnrollment from './Widgets/PatientsEnrollment';
import TotalKits from './Widgets/TotalKits';
import { actionsDashboard as actions } from './redux/actions';
import { actions as actionsPE } from './Widgets/PatientsEnrollment/redux/actions';
import { actions as actionsPCM } from './Widgets/PendingCallsMessages/redux/actions';
import { actions as actionsTK } from './Widgets/TotalKits/redux/actions';
import { actions as actionsPwnR } from './Widgets/PatientsWithNoReadings/redux/actions';
import DashboardFilter from './DashboardFilter';
import PatientsToCall from './Widgets/PatientsToCall';
import DashboardEditModeButton from './DashboardEditModeButton';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PatientsWithNoReadings from './Widgets/PatientsWithNoReadings';

export const DASHBOARD_ID = 'DashboardRPM';

const PendingCallsMessagesMetadata = {
  id: PendingCallsMessages.widgetId,
  name: Strings.widgets.patientActions,
  permission: '',
  defProps: { i: PendingCallsMessages.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};

const PatientsEnrollmentMetadata = {
  id: PatientsEnrollment.widgetId,
  name: Strings.widgets.patientsEnrollment,
  permission: '',
  defProps: { i: PatientsEnrollment.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};

const TotalKitsMetadata = {
  id: TotalKits.widgetId,
  name: Strings.widgets.totalKits,
  permission: '',
  defProps: { i: TotalKits.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
};

const PatientsWithNoReadingsMetadata = {
  id: PatientsWithNoReadings.widgetId,
  name: Strings.widgets.patientsWithNoReadings,
  permission: '',
  defProps: { i: PatientsWithNoReadings.widgetId, w: 4, h: 2, minW: 2, minH: 2 },
  configuration: {
    days: {
      type: 'number',
      name: 'days',
      label: Strings.numberOfDays,
      min: 1,
      max: 30,
      defaultValue: 7,
      isRequired: true,
    },
    // We're not allowing to change widget title (yet). It would cause problems with dashboard filters.
    // title: {
    //   type: 'string',
    //   name: 'title',
    //   label: Strings.title,
    //   defaultValue: Strings.widgets.patientsWithNoReadings,
    //   isRequired: true,
    // },
  },
};

const PatientsToCallMetadata = {
  id: PatientsToCall.widgetId,
  name: Strings.patientAlerts,
  permission: '',
  defProps: { i: PatientsToCall.widgetId, w: 12, h: 6, minW: 4, minH: 3 },
};

export const WidgetMetadata = [
  PendingCallsMessagesMetadata,
  PatientsEnrollmentMetadata,
  TotalKitsMetadata,
  PatientsWithNoReadingsMetadata,
  PatientsToCallMetadata,
];

const l = [
  { ...PendingCallsMessagesMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...PatientsEnrollmentMetadata.defProps, x: 4, y: 0, w: 4, h: 2 },
  { ...TotalKitsMetadata.defProps, x: 8, y: 0, w: 2, h: 2 },
  { ...PatientsWithNoReadingsMetadata.defProps, x: 10, y: 0, w: 2, h: 2 },
  { ...PatientsToCallMetadata.defProps, x: 0, y: 2, w: 12, h: 10 },
];

const m = [
  { ...PendingCallsMessagesMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...PatientsEnrollmentMetadata.defProps, x: 4, y: 0, w: 4, h: 2 },
  { ...TotalKitsMetadata.defProps, x: 0, y: 2, w: 3, h: 2 },
  { ...PatientsWithNoReadingsMetadata.defProps, x: 3, y: 2, w: 3, h: 2 },
  { ...PatientsToCallMetadata.defProps, x: 0, y: 4, w: 8, h: 6 },
];

const s = [
  { ...PendingCallsMessagesMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...PatientsEnrollmentMetadata.defProps, x: 0, y: 2, w: 4, h: 2 },
  { ...TotalKitsMetadata.defProps, x: 0, y: 4, w: 4, h: 2 },
  { ...PatientsWithNoReadingsMetadata.defProps, x: 0, y: 6, w: 4, h: 2 },
  { ...PatientsToCallMetadata.defProps, x: 0, y: 6, w: 4, h: 4 },
];

export const DefaultLayouts = { l, m, s };

function DashboardRPM(props) {
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());

  const initializeDashboard = () => {
    const widgetPCM = {
      ...PendingCallsMessagesMetadata,
      render: <PendingCallsMessages />,
      noPadding: true,
    };

    const widgetPE = {
      ...PatientsEnrollmentMetadata,
      render: <PatientsEnrollment />,
      noPadding: true,
    };

    const widgetTK = {
      ...TotalKitsMetadata,
      render: <TotalKits />,
    };

    const widgetPwnR = {
      ...PatientsWithNoReadingsMetadata,
      render: <PatientsWithNoReadings />,
    };

    const widgetPC = {
      ...PatientsToCallMetadata,
      render: <PatientsToCall refreshTimestamp={refreshTimestamp} />,
    };

    setWidgets([widgetPCM, widgetPE, widgetTK, widgetPwnR, widgetPC]);
  };

  useEffect(() => {
    initializeDashboard();
  }, [refreshTimestamp]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
    props.filters.forEach(f => {
      if (f.id === PatientsEnrollment.widgetId && f.checked) props.refreshPE();
      if (f.id === PendingCallsMessages.widgetId && f.checked) props.refreshPCM();
      if (f.id === TotalKits.widgetId && f.checked) props.refreshTK();
      if (f.id === PatientsWithNoReadings.widgetId && f.checked) {
        const days =
          props.configuration?.[PatientsWithNoReadings.widgetId]?.days?.value ||
          PatientsWithNoReadings.configuration.days.defaultValue;
        props.refreshPwnR(
          moment()
            .subtract(days, 'day')
            .startOf('day')
            .format(),
        );
      }
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        isBlack
        left={`${Strings.welcome} ${props.userName}`}
        right={
          <React.Fragment>
            <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
            <DashboardFilter dashboardId={DASHBOARD_ID} />
            <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
      />
      {widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        >
          <DashboardLayout
            dashboardId={DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={DefaultLayouts}
            rowHeight={45}
          />
        </OverlayScrollbarsComponent>
      )}
    </React.Fragment>
  );
}

DashboardRPM.propTypes = {
  userName: PropTypes.string,
  filters: PropTypes.array,
  configuration: PropTypes.any,
  initializeDashboard: PropTypes.func,
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { dashboardFilters } = state.dashboardState;
  const filters = dashboardFilters[DASHBOARD_ID];
  const configuration = state.dashboardState.configuration[DASHBOARD_ID];
  return {
    userName: profile ? (profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username) : '',
    filters,
    configuration,
  };
};

const mapDispatchToProps = dispatch => ({
  initializeDashboard: (dashboardId, defaultLayouts, widgets, force) =>
    dispatch(actions.initializeDashboard(dashboardId, defaultLayouts, widgets, force)),
  refreshPE: () => dispatch(actionsPE.getPatientsEnrollment()),
  refreshPCM: () => dispatch(actionsPCM.getPendingCallsMessages()),
  refreshTK: () => dispatch(actionsTK.getTotalKits()),
  refreshPwnR: date => dispatch(actionsPwnR.getPatientsWithNoReadings(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRPM);
