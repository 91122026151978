import moment from 'moment-timezone';
import { remindersTypes } from '../../modals/EditRpmSchedule/EditRpmScheduleModal';

import Strings from '../../Strings';

const allTimeZones = moment.tz.names();

export const scheduleTypes = {
  asNeeded: 'as_needed',
  everyDay: 'daily',
};

const MAX_MISSED_DOSES_DAYS = 365;

export const validateSchedule = (schedule, allowedTagsForReminders) => {
  const errors = validateMedication(schedule.name)
    .concat(validateType(schedule.type))
    .concat(validateEndDate(schedule.end_time, schedule.schedule_timezone))
    .concat(validateTimezone(schedule.type, schedule.schedule_timezone))
    .concat(
      validateOffset(
        schedule.type,
        schedule.offset,
        schedule.take_window,
        schedule.invalidFields?.filter(f => f.field === 'offset'),
      ),
    )
    .concat(validateAdministrativeWindow(schedule.offset, schedule.take_window))
    .concat(validateReminders(schedule.sms_reminder, allowedTagsForReminders, schedule.offset?.length));
  return errors;
};

function removeNonErrors(errors) {
  return errors.filter(e => e.missing || e.errors?.length > 0);
}

export function validateMedication(name) {
  if (!name) {
    return [{ property: 'name', errors: [], missing: true }];
  }
  return [];
}

export function validateType(type) {
  const ret = { property: 'type', errors: [], missing: false };

  if (!type) {
    ret.missing = true;
  } else if (!Object.values(scheduleTypes).includes(type)) {
    ret.errors.push('Unknown schedule type');
  }

  return removeNonErrors([ret]);
}

export function validateEndDate(date, timezone) {
  const ret = { property: 'end_time', errors: [], missing: false };
  if (
    date &&
    moment
      .tz(date, timezone)
      .endOf('day')
      .unix() <
      moment
        .tz(timezone)
        .endOf('day')
        .unix()
  ) {
    ret.errors.push(Strings.errors.endDateMustBeTomorrow);
  }
  return removeNonErrors([ret]);
}

export function validateTimezone(type, timezone) {
  const ret = { property: 'schedule_timezone', errors: [], missing: false };
  if (type && !timezone) {
    ret.missing = true;
  } else if (timezone && !allTimeZones.includes(timezone)) ret.errors.push('Unknown timezone');
  return removeNonErrors([ret]);
}

export function validateOffset(type, offset, window, invalidFields) {
  if (type === scheduleTypes.everyDay && (!offset || offset.length < 1)) {
    return [{ property: 'offset', errors: [], missing: true }];
  }
  if (type !== scheduleTypes.everyDay && (!offset || offset.length < 1)) {
    return [];
  }

  const ret = [];
  const windowStart = window && window.length > 0 ? window[0] : null;
  const windowEnd = window && window.length > 1 ? window[1] : null;

  if (offset.length > 6) {
    ret.push({
      property: 'timesPerDay',
      errors: ['Maximum takes per day is 6'],
      missing: false,
    });
  }

  const secondsInDay = 24 * 60 * 60;
  offset.forEach((o, i) => {
    if (o === null || o === undefined || o === '') {
      ret.push({
        property: 'offset',
        index: i,
        missing: true,
      });
    } else {
      const r = { property: 'offset', index: i, errors: [], missing: false };

      if (offset.filter(off => off === o)?.length > 1) {
        r.errors.push(Strings.errors.duplicateTimeForTake);
      }

      if (windowStart && windowEnd) {
        const minDiff = windowStart + windowEnd;
        if (
          offset.find(
            off =>
              off !== o &&
              ((o - off + secondsInDay) % secondsInDay <= minDiff ||
                (off - o + secondsInDay) % secondsInDay <= minDiff),
          )
        ) {
          ret.push({
            property: 'offset',
            index: offset.findIndex(off => off === o),
            errors: ['Time too close to another take. Change time or administrative window'],
            missing: false,
          });
        }
      }
    }
  });

  if (invalidFields) {
    invalidFields.forEach(f => {
      ret.push({
        property: f.field,
        index: f.index,
        errors: [Strings.capPatient.incorrectFieldFormat],
        missing: false,
      });
    });
  }

  return removeNonErrors(ret);
}

export function validateAdministrativeWindow(offset, window) {
  const windowStart = window && window.length > 0 ? window[0] : null;
  const windowEnd = window && window.length > 1 ? window[1] : null;
  const ret = [];

  if (offset && offset.length > 1) {
    if (!windowStart) {
      ret.push({ property: 'take_window', field: 'start', missing: true });
    }
    if (!windowEnd) {
      ret.push({ property: 'take_window', field: 'end', missing: true });
    }
  } else {
    if (windowEnd && !windowStart) {
      ret.push({ property: 'take_window', field: 'start', missing: true });
    }
    if (windowStart && !windowEnd) {
      ret.push({ property: 'take_window', field: 'end', missing: true });
    }
  }

  return removeNonErrors(ret);
}

export function validateReminders(reminders, allowedTagsForReminders, takesPerDay) {
  const ret = [];

  if (reminders) {
    Object.entries(reminders).forEach(([type, r]) => {
      if (r?.checked) {
        if ((type === remindersTypes.early || type === remindersTypes.late) && !r.offset) {
          ret.push({ property: 'sms_reminder', type, field: 'offset', missing: true });
        }
        if (
          type === remindersTypes.missedDoses &&
          takesPerDay &&
          r.offset &&
          r.offset > MAX_MISSED_DOSES_DAYS * takesPerDay
        ) {
          ret.push({
            property: 'sms_reminder',
            type,
            field: 'offset',
            missing: false,
            errors: [Strings.formatString(Strings.errors.maxValueExceeded, MAX_MISSED_DOSES_DAYS * takesPerDay)],
          });
        }
        if (!r.text) {
          ret.push({ property: 'sms_reminder', type, field: 'text', missing: true });
        } else {
          const tags = r.text.match(/\[.*?\]/g);
          if (tags && allowedTagsForReminders[type]) {
            tags.forEach(tag => {
              const tagValue = tag.replace(/\[|\]/g, '');
              if (!allowedTagsForReminders[type].includes(tagValue)) {
                ret.push({
                  property: 'sms_reminder',
                  type,
                  field: 'text',
                  missing: false,
                  errors: [`${Strings.invalidTag}: ${tag}`],
                });
              }
            });
          }
        }
      }
    });
  }

  return removeNonErrors(ret);
}
