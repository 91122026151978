import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';

import TabView, { Tab } from '../../components/TabView';
import Strings from '../../Strings';
import { hasPermission, PERMISSIONS } from '../../utils/userPermissions';
import {
  AIR_QUALITY_MONITOR_DEVICE_TYPE,
  BLOOD_PRESSURE_MONITOR,
  GLUCOMETER_DEVICE_TYPE,
  HAILIE_INHALER_DEVICE_TYPE,
  INHALER,
  PXO,
  SPIROMETER_DEVICE_TYPE,
  THERMOMETER_DEVICE_TYPE,
  WEIGHING_MACHINE,
  WRIST_BAND,
  PTINR_MONITOR_DEVICE_TYPE,
  INJECTABLE_TRACKER,
} from '../SuperUser/DeviceManagement/constant';
import DeviceView from '../ThirdPartyDevices/ThirdPartyDevices';
import BLE from './BLE';
import Hub from './Hub';
import Kits from './Kits';
import LTE from './LTE';
import { getActiveTabNameFromUrl } from '../../utils';
import './ShipperCaps.scss';

export const root = 'devices';

export const Tabs = {
  Hub: {
    DisplayName: Strings.hub,
    urlId: 'hub',
    url: `/${root}/hub`,
  },
  BLESmartCap: {
    DisplayName: Strings.bluetooth,
    urlId: 'ble',
    url: `/${root}/ble`,
  },
  PillboxLte: {
    DisplayName: Strings.lte,
    urlId: 'lte',
    url: `/${root}/lte`,
  },
  LTESmartCap: {
    DisplayName: Strings.lsc,
    urlId: 'lsc',
    url: `/${root}/lsc`,
  },
  InjectableTracker: {
    DisplayName: Strings.injectableTracker,
    urlId: 'injectable-tracker',
    url: `/${root}/injectable-tracker`,
  },
  BloodPressureMonitor: {
    DisplayName: Strings.bloodPressureMonitor,
    urlId: 'bpm',
    url: `/${root}/bpm`,
  },
  WeightScales: {
    DisplayName: Strings.weightScales,
    urlId: 'weight-scales',
    url: `/${root}/weight-scales`,
  },
  WristBand: {
    DisplayName: Strings.deviceTypes.wristBand,
    urlId: 'wb',
    url: `/${root}/wb`,
  },
  Glucometer: {
    DisplayName: Strings.deviceTypes.glucometer,
    urlId: 'glucometer',
    url: `/${root}/glucometer`,
  },
  Thermometer: {
    DisplayName: Strings.thermometer,
    urlId: 'thermometer',
    url: `/${root}/thermometer`,
  },
  PulseOx: {
    DisplayName: Strings.pulseOx,
    urlId: 'pxo',
    url: `/${root}/pxo`,
  },
  Spirometer: {
    DisplayName: Strings.deviceTypes.spirometer,
    urlId: 'spirometer',
    url: `/${root}/spirometer`,
  },
  Inhaler: {
    DisplayName: Strings.deviceTypes.inhaler,
    urlId: 'inhaler',
    url: `/${root}/inhaler`,
  },
  Kits: {
    DisplayName: Strings.kits,
    urlId: 'kits',
    url: `/${root}/kits`,
  },
  AirQualityMonitor: {
    DisplayName: Strings.deviceTypes.airQualityMonitor,
    urlId: 'air-quality-monitor',
    url: `/${root}/air-quality-monitor`,
  },
  HailieInhaler: {
    DisplayName: Strings.deviceTypes.hailieInhaler,
    urlId: 'hailie-inhaler',
    url: `/${root}/hailie-inhaler`,
  },
  PtInrMonitor: {
    DisplayName: Strings.deviceTypes.ptInrMonitor,
    urlId: 'pt_inr',
    url: `/${root}/pt_inr`,
  },
};

class ShipperCaps extends PureComponent {
  render() {
    const activeTab = this.props.match.params.type;
    const tabArray = [];

    hasPermission(PERMISSIONS.KITS_MANAGEMENT) &&
      tabArray.push(
        <Tab name={Tabs.Kits.DisplayName} path={Tabs.Kits.url} key="kits">
          <Kits />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_HUB) &&
      tabArray.push(
        <Tab name={Tabs.Hub.DisplayName} path={Tabs.Hub.url} key="hub">
          <Hub />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_BLE) &&
      tabArray.push(
        <Tab name={Tabs.BLESmartCap.DisplayName} path={Tabs.BLESmartCap.url} key="ble">
          <BLE />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_LTE) &&
      tabArray.push(
        <Tab name={Tabs.PillboxLte.DisplayName} path={Tabs.PillboxLte.url} key="lte">
          <LTE type="lte" />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_LSC) &&
      tabArray.push(
        <Tab name={Tabs.LTESmartCap.DisplayName} path={Tabs.LTESmartCap.url} key="lsc">
          <LTE type="lsc" />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_INJECTABLE_TRACKER) &&
      tabArray.push(
        <Tab name={Tabs.InjectableTracker.DisplayName} path={Tabs.InjectableTracker.url} key="injectable-tracker">
          <LTE type={INJECTABLE_TRACKER} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_WEIGHTING_MACHINE) &&
      tabArray.push(
        <Tab name={Tabs.WeightScales.DisplayName} path={Tabs.WeightScales.url} key="wm">
          <DeviceView deviceClass={WEIGHING_MACHINE} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_BPM) &&
      tabArray.push(
        <Tab name={Tabs.BloodPressureMonitor.DisplayName} path={Tabs.BloodPressureMonitor.url} key="bpm">
          <DeviceView deviceClass={BLOOD_PRESSURE_MONITOR} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_WB) &&
      tabArray.push(
        <Tab name={Tabs.WristBand.DisplayName} path={Tabs.WristBand.url} key="wb">
          <DeviceView deviceClass={WRIST_BAND} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_GLUCO) &&
      tabArray.push(
        <Tab name={Tabs.Glucometer.DisplayName} path={Tabs.Glucometer.url} key="glucometer">
          <DeviceView deviceClass={GLUCOMETER_DEVICE_TYPE} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_THERMOMETER) &&
      tabArray.push(
        <Tab name={Tabs.Thermometer.DisplayName} path={Tabs.Thermometer.url} key="thermometer">
          <DeviceView deviceClass={THERMOMETER_DEVICE_TYPE} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_INHALER) &&
      tabArray.push(
        <Tab name={Tabs.Inhaler.DisplayName} path={Tabs.Inhaler.url} key="inhaler">
          <DeviceView deviceClass={INHALER} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_SPIROMETER) &&
      tabArray.push(
        <Tab name={Tabs.Spirometer.DisplayName} path={Tabs.Spirometer.url} key="spirometer">
          <DeviceView deviceClass={SPIROMETER_DEVICE_TYPE} filtersDisabled />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_PULSE_OXIMETER) &&
      tabArray.push(
        <Tab name={Tabs.PulseOx.DisplayName} path={Tabs.PulseOx.url} key="pxo">
          <DeviceView deviceClass={PXO} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_AIR_QUALITY_MONITOR_DEVICE_TYPE) &&
      tabArray.push(
        <Tab name={Tabs.AirQualityMonitor.DisplayName} path={Tabs.AirQualityMonitor.url} key="aqm">
          <DeviceView deviceClass={AIR_QUALITY_MONITOR_DEVICE_TYPE} />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_HAILIE_INHALER) &&
      tabArray.push(
        <Tab name={Tabs.HailieInhaler.DisplayName} path={Tabs.HailieInhaler.url} key="hailie-inhalers">
          <DeviceView deviceClass={HAILIE_INHALER_DEVICE_TYPE} key="hailie-inhalers" filtersDisabled />
        </Tab>,
      );

    hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_PTINR_MONITOR) &&
      tabArray.push(
        <Tab name={Tabs.PtInrMonitor.DisplayName} path={Tabs.PtInrMonitor.url} key="pt-inr-monitor">
          <DeviceView deviceClass={PTINR_MONITOR_DEVICE_TYPE} />
        </Tab>,
      );

    return (
      <TabView
        key="tabs"
        activeTab={getActiveTabNameFromUrl(activeTab, Tabs)}
        routeMode
        rightMargin={isMobile ? 45 : 60}
      >
        {tabArray}
      </TabView>
    );
  }
}

ShipperCaps.propTypes = {
  activeTab: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
};

export default ShipperCaps;
