import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _, { capitalize } from 'lodash';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import { actions as kitActions } from '../Kits/redux/actions';
import { actions as local_actions } from './redux/actions';
import { PageHeader } from '../../components/PageHeader';
import { Column } from '../../containers/Table/components';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import { TextInput } from '../../components/PageHeader/TextInput';
import Strings from '../../Strings';
import Select from '../../components/Select';
import TableWithPagination, { PAGES_BATCH } from '../../containers/Table/TableWithPagination';
import { KITS_LIST_RESULT } from '../Kits/redux/constants';
import { PAGE_LIMIT, DATE_MONTH_DAY_YEAR, DATE_MONTH_DAY_YEAR_WITH_DASH } from '../../constants';
import { openModalAction } from '../../actions/modal';
import { isCapManager, isOrganizationManager, isShipper, isSuperUser } from '../../utils/userRoles';
import { convertUnixEpochToHumanReadable } from '../../utils';
import { DateFilters } from '../../utils/DateFilters';
import { ORGANIZATION_LIST_RESULT } from './redux/constants';
import { PERMISSIONS, hasPermission } from '../../utils/userPermissions';

const mapDispatchToProps = dispatch => ({
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  getOrgs: pageRequest => dispatch(local_actions.getOrgs(pageRequest)),
  onNavigate: (path, params) =>
    dispatch(
      push({
        pathname: path,
        state: { params },
      }),
    ),
  onCreateKit: (req, refreshFunction) =>
    dispatch(
      openModalAction('create-kit-and-add-devices', {
        onRefresh: refreshFunction,
        request: req,
      }),
    ),
});

const mapStateToProps = state => ({
  labFeatures: state.labState.features,
});

class Kits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      kits: [],
      pagination: {},
      refreshEnable: true,
      isLoading: true,
      openActionWidget: false,
      selectedOrg: '',
      organizations: [],
      pageLimit: 0,
    };

    this.request = {
      limit: 0,
      offset: 0,
      search: '',
      orgId: '',
      sortColumn: 'updated_at',
      sortType: 'descending',
    };

    this.fullHeightTable = props.labFeatures.find(f => f.name === 'Full-height Table')?.selected;
    this.infiniteScrollTable = props.labFeatures.find(f => f.name === 'Infinite scroll Table')?.selected;
  }

  static propTypes = {
    getKits: PropTypes.func.isRequired,
    getOrgs: PropTypes.func.isRequired,
    onNavigate: PropTypes.func,
    onCreateKit: PropTypes.func,
  };

  componentDidMount() {
    if (this.props.history?.location?.state?.params?.offset) {
      this.request.offset = this.props.history?.location?.state?.params?.offset;
    }
    this.props.getOrgs().then(resp => {
      if (resp?.type === ORGANIZATION_LIST_RESULT) {
        this.setState({ organizations: resp.response?.data });
      }
    });
    if (this.infiniteScrollTable || this.fullHeightTable) {
      return;
    }
    this.setState({ pageLimit: PAGE_LIMIT });
    this.request.limit = PAGE_LIMIT;
    this.load();
  }

  onPageLimitChange = pageLimit => {
    const newLimit = this.infiniteScrollTable ? pageLimit * PAGES_BATCH : pageLimit;
    this.setState({ pageLimit: pageLimit });
    this.request.limit = newLimit;
    this.load();
  };

  onKitsReceived(resp) {
    let kits = this.state.kits;
    const { pagination } = resp.response;
    if (resp.type === KITS_LIST_RESULT) {
      if (this.infiniteScrollTable) {
        if (kits.length === 0) {
          kits = new Array(pagination.totalRecords).fill(null);
        }
        kits.splice(pagination.offset, pagination.limit, ...resp.response.kits);
      }
      this.setState({
        kits: this.infiniteScrollTable ? kits : resp.response.kits,
        pagination,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  load = (clearDataBeforeFetch = false) => {
    if (Number.isNaN(this.request.limit)) {
      return;
    }
    this.setState({ isLoading: true });
    if (clearDataBeforeFetch) {
      this.setState({ kits: [], pagination: {} });
    }
    this.request.orgId = this.state.selectedOrg;
    this.props.getKits(this.request).then(resp => this.onKitsReceived(resp));
  };

  onRefresh = () => {
    this.state.refreshEnable = false;
    this.state.turnOffTimeout = setTimeout(() => {
      this.state.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.load(true);
  };

  onOffsetChange = (offset, limit) => {
    this.request.offset = offset;
    if (limit) {
      this.request.limit = limit;
    }
    this.load();
  };

  onRowSelection = selectedRows => {
    this.props.onNavigate(`kits/${this.state.kits[selectedRows].id}/details`, { offset: this.request.offset });
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.request.offset = 0;
    this.request.search = query;
    this.load(true);
  }, 1000);

  onDateRangeChanged = dateRange => {
    if (dateRange) {
      this.request.startDate = dateRange.dates.startDate();
      this.request.endDate = dateRange.dates.endDate();
    } else {
      this.request.startDate = '';
      this.request.endDate = '';
    }
    this.request.offset = 0;

    this.load(true);
  };

  onOrgChange = option => {
    this.request.offset = 0;
    delete this.request.status;

    this.setState({ selectedOrg: option.value }, () => {
      this.load(true);
    });
  };

  onSortClick = ({ sortKey, direction }) => {
    this.request.offset = 0;
    this.request.sortColumn = sortKey;
    this.request.sortType = direction;
    this.load(true);
  };

  getHeaderComponents = () => {
    if (isCapManager() || isOrganizationManager()) {
      return (
        <React.Fragment>
          <Button class="refreshTop" disabled={!this.state.refreshEnable} onClick={this.onRefresh} />
          <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
          <Button
            class={HEADER_BUTTON_DARK_BLUE}
            onClick={() => this.props.onCreateKit(this.request, () => this.onRefresh())}
            title={Strings.createKit}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Button class="refreshTop" disabled={!this.state.refreshEnable} onClick={this.onRefresh} />
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={this.onDateRangeChanged}
          defaultValue={DateFilters.AllTime.value}
        />
      </React.Fragment>
    );
  };

  getSuperUserHeaderComponents = () => {
    const { selectedOrg, organizations } = this.state;
    const { onCreateKit } = this.props;

    const data = [
      {
        value: '',
        label: Strings.patientDashboard.all,
      },
    ];

    if (organizations) {
      Object.values(organizations).forEach(org => {
        data.push({
          value: org.masked_id,
          label: org.name,
        });
      });

      return (
        <React.Fragment>
          <Button class="refreshTop" disabled={!this.state.refreshEnable} onClick={this.onRefresh} />
          <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
          <Select data={data} value={selectedOrg} onChange={this.onOrgChange} isSearchable />
          <Select
            data={[
              DateFilters.AllTime,
              DateFilters.Last48Hours,
              DateFilters.ThisMonth,
              DateFilters.Last30Days,
              DateFilters.LastMonth,
              DateFilters.Last6Months,
              DateFilters.ThisYear,
              DateFilters.Last12Months,
            ]}
            onChange={this.onDateRangeChanged}
            defaultValue={DateFilters.AllTime.value}
          />
          <Button
            class={HEADER_BUTTON_DARK_BLUE}
            onClick={() => onCreateKit(this.request, () => this.onRefresh())}
            title={Strings.createKit}
          />
        </React.Fragment>
      );
    }
  };

  render() {
    const columns = [];
    columns.push(<Column key="kitId" title={Strings.kitId} value={e => (e.id ? e.id : null)} className="fix" fixed />);

    columns.push(
      <Column key="kit_name" title={Strings.kitName} value={e => (e.name ? e.name : '-')} sortKey="kit_name" />,
    );

    if (isShipper() || isSuperUser()) {
      columns.push(
        <Column
          key="organization"
          title={Strings.organizationName}
          value={e =>
            e.org_id && this.state.organizations
              ? this.state.organizations.find(o => o.masked_id === e.org_id)?.name
              : '-'
          }
        />,
      );
    }

    columns.push(
      <Column key="kitStatus" title={Strings.status} value={e => (e.status ? capitalize(e.status) : '-')} />,
    );

    columns.push(
      <Column
        key="active_since"
        title={Strings.activeSince}
        value={e => (e.active_since ? moment(e.active_since).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
    );

    columns.push(
      <Column key="nDevices" title={Strings.devices} value={e => (e.devices_count ? e.devices_count : '-')} />,
    );

    columns.push(
      <Column
        key="deviceReadings"
        title={Strings.deviceReadings}
        value={e => (e.devices_readings ? e.devices_readings : '-')}
      />,
    );

    columns.push(
      <Column
        key="last_activity"
        title={Strings.lastActivity}
        value={e => (e.last_activity ? moment(e.last_activity).format(DATE_MONTH_DAY_YEAR_WITH_DASH) : '-')}
      />,
    );

    columns.push(
      <Column
        key="updated_at"
        title={Strings.updated_at}
        sortKey="updated_at"
        value={e => (e.updated_at ? convertUnixEpochToHumanReadable(e.updated_at) : '-')}
      />,
    );

    columns.push(<Column key="location" title={Strings.location} value={e => (e.location ? e.location : '-')} />);
    if (hasPermission(PERMISSIONS.KITS_SHOW_PATIENT)) {
      columns.push(<Column key="mrn" title={Strings.capPatient.mrn} value={e => e.patient_mrn || '-'} />);
    }

    const headerTitle =
      !this.state.isLoading && this.state.pagination && this.state.kits
        ? this.infiniteScrollTable
          ? Strings.formatString(Strings.showingX, this.state.pagination.totalRecords, Strings.kits)
          : Strings.formatString(
              Strings.showingXKits,
              this.state.kits.length,
              this.state.pagination.totalRecords ? this.state.pagination.totalRecords : 0,
            )
        : Strings.showingWait;

    return (
      <React.Fragment>
        <PageHeader
          isBlack
          left={headerTitle}
          right={isShipper() || isSuperUser() ? this.getSuperUserHeaderComponents() : this.getHeaderComponents()}
        />
        <TableWithPagination
          isLoading={this.state.isLoading}
          name="kits"
          uuid="8b9e141d-87f9-4593-9f2e-73296919f7a5"
          pagination={this.state.pagination}
          data={this.state.kits}
          onPrevClick={this.onPrevClick}
          onOffsetChange={this.onOffsetChange}
          onRowClick={this.onRowSelection}
          onSortClick={this.onSortClick}
          freezeFirstColumn
          enableColumnFiltering
          infiniteScroll={this.infiniteScrollTable}
          onPageLimitChange={this.fullHeightTable || this.infiniteScrollTable ? this.onPageLimitChange : undefined}
        >
          {columns}
        </TableWithPagination>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Kits));
