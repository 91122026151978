import { API_CALL } from '../../middleware/api';

const GET_ACCESS_TOKEN = 'get-access-token';
export const GET_ACCESS_TOKEN_RESULT = `${GET_ACCESS_TOKEN}/result`;
export const GET_ACCESS_TOKEN_ERROR = `${GET_ACCESS_TOKEN}/error`;

const GET_LIST_DOCUMENTS = 'get-list-documents';
export const GET_LIST_DOCUMENTS_RESULT = `${GET_LIST_DOCUMENTS}/result`;
export const GET_LIST_DOCUMENTS_ERROR = `${GET_LIST_DOCUMENTS}/error`;

const GET_DOWNLOAD_URL = 'get-download-link';
export const GET_DOWNLOAD_URL_RESULT = `${GET_DOWNLOAD_URL}/result`;
export const GET_DOWNLOAD_URL_ERROR = `${GET_DOWNLOAD_URL}/error`;

const GET_UPLOAD_URL = 'get-upload-link';
export const GET_UPLOAD_URL_RESULT = `${GET_UPLOAD_URL}/result`;
export const GET_UPLOAD_URL_ERROR = `${GET_UPLOAD_URL}/error`;

export const documentsActions = {
  getS3ListDocuments: patientId => ({
    [API_CALL]: {
      endpoint: `/patients/${patientId}/documents/list`,
      method: 'get',
    },
    type: GET_LIST_DOCUMENTS,
  }),

  getS3DownloadUrl: (patientId, fileName) => ({
    [API_CALL]: {
      endpoint: `/patients/${patientId}/documents/download?file_name=${fileName}`,
      method: 'get',
    },
    type: GET_DOWNLOAD_URL,
  }),

  getS3UploadUrl: (patientId, fileName) => ({
    [API_CALL]: {
      endpoint: `/patients/${patientId}/documents/upload?file_name=${fileName}`,
      method: 'get',
    },
    type: GET_UPLOAD_URL,
  }),

  getS3UploadPatientListUrl: fileName => ({
    [API_CALL]: {
      endpoint: `/patients/upload-patient-list-url?file_name=${fileName}`,
      method: 'get',
    },
    type: GET_UPLOAD_URL,
  }),

  getBoxDownloadToken: patientId => ({
    [API_CALL]: {
      endpoint: `/patients/${patientId}/documents/get-download-token`,
      method: 'get',
    },
    type: GET_ACCESS_TOKEN,
  }),
  getBoxUploadToken: patientId => ({
    [API_CALL]: {
      endpoint: `/patients/${patientId}/documents/get-upload-token`,
      method: 'get',
    },
    type: GET_ACCESS_TOKEN,
  }),
};
