import React from 'react';
import PropTypes from 'prop-types';

import './graph.scss';
import Strings from '../../Strings';
import GenericCategoryGraph, { chartTypeEnum } from './GenericCategoryGraph';

export const GetCptDodesDistributionDataSeries = colorPalette => [
  {
    name: Strings.programs.rtm,
    color: colorPalette?.interpolate(0.66),
    selector: item => item['rtm'],
  },
  {
    name: Strings.programs.rpm,
    color: colorPalette?.interpolate(0.33),
    selector: item => item['rpm'],
  },
];

export default function CptCodesDistributionGraph(props) {
  const { graphData } = props;

  return (
    <GenericCategoryGraph
      graphData={graphData}
      hasBorder={true}
      forceCondensed
      xAxisTopPosition={false}
      //xAxisLabelsMinMaxOnly
      yAxisWidth={40}
      yAxisUnit=""
      tooltipTitle="Cpt Codes Distribution"
      dataSeries={GetCptDodesDistributionDataSeries(props.colorPalette)}
      categorySelector={item => item.codes}
      hasReferenceAreas={true}
      graphType={chartTypeEnum.stackedBar}
    />
  );
}

CptCodesDistributionGraph.propTypes = { graphData: PropTypes.array };
