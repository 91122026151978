import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Strings from '../../Strings';
import { actionsAdvancedFilters } from './actions';
import BarItem from './internals/BarItem';
import { getRelevantFilters, getFiltersForRequest } from './helpers';
import { getFiltersDefinition } from './FiltersDefinition';

class AdvancedFiltersBar extends React.PureComponent {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      urlInjected: false,
    };
  }

  componentDidMount() {
    this.injectStateFromUrl();
  }

  componentWillUnmount() {
    this.props.clearAll();
  }

  publishFiltersFromUrl = filters => {
    const { updateFilterState, clearFilters } = this.props;

    filters.forEach(e => {
      try {
        const filterFromUrl = e.split(':');
        const group = filterFromUrl[0];
        const values = filterFromUrl[1].split(',');

        const f = getFiltersDefinition();
        if (f[group].type === 'number') {
          updateFilterState({
            group,
            value: group,
            state: parseInt(values, 10),
          });
        } else {
          f[group].values.forEach(k => {
            if (values.includes(k.value) && k.value !== 'all') {
              updateFilterState({
                group,
                value: k.value,
                state: true,
              });
            }
          });
        }
      } catch (error) {
        // format of filters from url is not compatible, lets clear that
        clearFilters();
      }
    });
  };

  injectStateFromUrl = () => {
    const { isFiltersInitialized } = this.props;

    if (isFiltersInitialized) {
      const urlParams = new URLSearchParams(window.location.search);
      const filtersSerialized = urlParams.get('filters');
      const filters = JSON.parse(filtersSerialized);
      if (Array.isArray(filters)) {
        this.publishFiltersFromUrl(filters);
      }
      this.state.urlInjected = true;
      this.props.urlInjected();
    }
  };

  onClear = () => {
    this.props.clearFilters();
  };

  getStatusRepresentants = () => {
    const { filters, updateFilterState, customFilters } = this.props;

    return getRelevantFilters(filters).map((filter, i) => {
      return <BarItem updateFilterState={updateFilterState} key={i} filter={filter} customFilters={customFilters} />;
    });
  };

  componentDidUpdate(prevProps) {
    const { filters, isFiltersInitialized } = this.props;
    const { urlInjected } = this.state;

    if (isFiltersInitialized && !urlInjected) {
      // inject filters from URL only once, when filters are initialized
      this.injectStateFromUrl();
    }

    if (filters !== prevProps.filters && isFiltersInitialized && urlInjected) {
      // lets replace current entry in history with new one with filters state
      const filtersSerialized = JSON.stringify(getFiltersForRequest(filters));
      this.props.replacePath(`${window.location.pathname}?filters=${filtersSerialized}`);
    }
  }

  render() {
    const clearButton = (
      <div className="round-button" onClick={this.onClear}>
        {Strings.clear}
      </div>
    );

    const reps = this.getStatusRepresentants();

    if (reps.length !== 0) {
      return (
        <div className="advanced-filters">
          <div className="horizontal-line" />
          <div className="advanced-filters-bar">
            {clearButton}
            {reps}
          </div>
        </div>
      );
    }
    return <React.Fragment />;
  }

  static propTypes = {
    clearFilters: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
    urlInjected: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    updateFilterState: PropTypes.func.isRequired,
    replacePath: PropTypes.func.isRequired,
    isFiltersInitialized: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = state => {
  return {
    filters: state.entities.advancedFilters.filters.items,
    isFiltersInitialized: state.entities.advancedFilters.filters.isInitalized,
  };
};

const mapDispatchToProps = dispatch => ({
  clearFilters: () => dispatch(actionsAdvancedFilters.clearFilters()),
  urlInjected: () => dispatch(actionsAdvancedFilters.urlInjected()),
  clearAll: () => dispatch(actionsAdvancedFilters.clearAll()),
  updateFilterState: state => dispatch(actionsAdvancedFilters.updateFilterState(state)),
  replacePath: path => dispatch(replace(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFiltersBar);
