import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { clear_two_factor_key, login, oauth2Login, two_factor_auth, two_factor_auth_resend } from '../../actions/auth';
import { Background } from '../../components/Login/Background/Background';
import Strings from '../../Strings';
import { getMobileOperatingSystem, QueryString } from '../../utils';
import { saveBrandInLS, getVariablesForBrand, setBrandFromLS } from '../../utils/brand';
import { setTheme, setThemeFromLS } from '../../utils/colorTheme';
import { LoginForm } from './LoginForm';
import { TwoFAForm } from './TwoFAForm';
import { getDefaultPageForUser } from '../../utils/defaultPages';

class Login extends PureComponent {
  static propTypes = {
    TwoFA: PropTypes.func,
    TwoFAResend: PropTypes.func,
    authenticated: PropTypes.bool,
    clear2FA: PropTypes.func,
    errors: PropTypes.array,
    isEnabled: PropTypes.any,
    isLoading: PropTypes.bool,
    location: PropTypes.object.isRequired,
    loginErrors: PropTypes.any,
    onAuthenticated: PropTypes.func,
    onConfirm: PropTypes.func,
    onFHIRLaunch: PropTypes.func,
    onForgotClick: PropTypes.func,
    onLogin: PropTypes.func,
    onNavigate: PropTypes.func,
    reset_password: PropTypes.any,
    role: PropTypes.string.isRequired,
    state: PropTypes.object,
    twoFAErrors: PropTypes.object,
    two_factor_key: PropTypes.any,
    verified: PropTypes.any,
  };

  state = {
    username: '',
    password: '',
    isError: false,
    errors: [],
    twoFACodeExpiresAt: null,
    codeIsResending: false,
  };

  UNSAFE_componentWillMount() {
    const query = new QueryString(this.props.location.search);
    if (query.params.org) saveBrandInLS(query.params.org);
    if (query.params.org === 'caredx') setTheme('red');

    if (query.params.origin === 'fhir' && query.params.sst) {
      this.props.onFHIRLaunch(query.params.sst);
    }

    if (this.props.authenticated) {
      this.props.onAuthenticated(this.props.role, query.params, this.props.reset_password);
    }

    setBrandFromLS();
    setThemeFromLS();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loginErrors !== nextProps.loginErrors) {
      if (nextProps.loginErrors) {
        const validationErrors = [];
        validationErrors.push(nextProps.loginErrors.message);
        this.setState({ errors: validationErrors });
      }
    }

    if (this.props.twoFAErrors !== nextProps.twoFAErrors) {
      if (nextProps.twoFAErrors) {
        this.setState({ errors: [nextProps.twoFAErrors.message] });
      } else {
        this.setState({ errors: [] });
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.authenticated) {
      const isIE = !!document.documentMode || false;
      if (isIE) {
        window.location.reload();
      } else {
        nextProps.onAuthenticated(
          nextProps.role,
          new QueryString(nextProps.location.search).params,
          nextProps.reset_password,
        );
      }
    }
  }

  onLogin = event => {
    event.preventDefault();
    this.setState({ errors: [] });
    const { username, password } = this.state;
    this.props
      .onConfirm(username, password)
      .then(r => this.setState({ twoFACodeExpiresAt: r.response.code_expires_at }));
  };

  onChange = event => {
    if (this.state.isError) {
      this.setState({ isError: false });
      this.setState({ username: '' });
      return;
    }
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  on2FAFormSubmit = ({ code }) => {
    this.send2FA(code);
  };

  send2FA(code) {
    const { two_factor_key } = this.props;
    this.props.TwoFA(code, two_factor_key);
  }

  on2FABack = event => {
    if (event) {
      event.preventDefault();
    }
    this.props.clear2FA();
  };

  on2FAResend = event => {
    const { TwoFAResend, two_factor_key } = this.props;

    if (event) {
      event.preventDefault();
    }

    this.setState({ codeIsResending: true });
    TwoFAResend(two_factor_key).then(r => {
      this.setState({ codeIsResending: false });

      const error = r?.response?.error;

      if (error) {
        this.setState({ errors: [error] });
      } else {
        this.setState({ twoFACodeExpiresAt: r.response.code_expires_at });
      }
    });
  };

  render() {
    const { onForgotClick, two_factor_key, verified, isLoading } = this.props;
    const { errors, twoFACodeExpiresAt, codeIsResending } = this.state;
    const query = new QueryString(this.props.location.search);
    const isEnabled = this.state.username.length > 0 && this.state.password.length > 0;

    if (query.params.origin === 'fhir') {
      return <Background title={Strings.loadingFhirSessions} noLines />;
    }

    let form;

    if (two_factor_key) {
      if (!verified) {
        this.props.onNavigate(`/invite-doctor`);
      } else {
        form = (
          <TwoFAForm
            onFormSubmit={this.on2FAFormSubmit}
            onBack={this.on2FABack}
            onResend={this.on2FAResend}
            error={errors[0]}
            isLoading={isLoading || codeIsResending}
            expiresAt={twoFACodeExpiresAt}
          />
        );
      }
    } else {
      form = (
        <LoginForm
          state={this.state}
          errors={errors}
          onLogin={this.onLogin}
          onChange={this.onChange}
          onForgotClick={onForgotClick}
          isEnabled={isEnabled}
          isLoading={isLoading}
        />
      );
    }

    const brandVariables = getVariablesForBrand();
    return (
      <Background title={`${Strings.loginTo} ${brandVariables ? brandVariables.orgName : 'RxCap'}`} noLines>
        {form}
      </Background>
    );
  }
}

export default connect(
  state => ({
    authenticated: state.auth.authenticated,
    loginErrors: state.auth.loginErrors,
    twoFAErrors: state.auth.twoFAErrors,
    is_doctor: state.auth.is_doctor,
    role: state.auth.role || '',
    two_factor_key: state.auth.two_factor_key,
    verified: state.auth.verified,
    isLoading: state.auth.isLoading,
    reset_password: state.auth.reset_password,
  }),
  dispatch => ({
    onFHIRLaunch: sst => dispatch(oauth2Login({ sst })),
    onForgotClick: () => dispatch(push('/resetpassword')),
    clear2FA: () => dispatch(clear_two_factor_key()),
    onNavigate: path => dispatch(push(path)),
    TwoFA: (code, two_factor_key) =>
      dispatch(
        two_factor_auth({
          code,
          two_factor_key,
        }),
      ),
    TwoFAResend: two_factor_key => dispatch(two_factor_auth_resend({ two_factor_key })),
    onAuthenticated: (role, queryParams, reset_password) => {
      if (queryParams.next) {
        if (queryParams.next.startsWith('/oauth/authorize/')) {
          window.location = queryParams.next;
        } else {
          dispatch(push(queryParams.next));
        }
      } else {
        const url = localStorage.getItem('redirectUrl');
        if (url) {
          localStorage.removeItem('redirectUrl');
          const redirectUrl = decodeURIComponent(url);
          window.location = redirectUrl;
        } else {
          dispatch(push(getDefaultPageForUser()));
        }
      }
    },
    onConfirm: (username, password) =>
      dispatch(
        login({
          username,
          password,
        }),
      ),
  }),
)(Login);
