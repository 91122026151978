import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment, { now } from 'moment-timezone';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import ReadingsWidget, { WidgetTypes } from '../../Dashboards/Widgets/ReadingsWidget';
import { PageHeader } from '../../../components/PageHeader';
import DashboardEditModeButton from '../../Dashboards/DashboardEditModeButton';
import DashboardFilter from '../../Dashboards/DashboardFilter';
import { Button } from '../../../components/PageHeader/Button';
import DashboardLayout from '../../Dashboards/DashboardLayout';
import Strings from '../../../Strings';
import AdherenceDistributionGraph from '../../../components/Graphs/AdherenceDistributionGraph';
import { actions } from './redux/actions';
import { actions as orgActions } from '../Organization/redux/actions';
import Select from '../../../components/Select';
import { getDefaultPeriods } from './Report';
import { ORGANIZATION_LIST_RESULT } from '../Organization/redux/constants';
import { roundToDecimal } from '../../../utils/unitConverters';
import PatientsPerProgramGraph from '../../../components/Graphs/PatientsPerProgramGraph';
import { graphContainer, graphContainerWithLegend } from '../../Dashboards/Widgets/graphContainers';
import CptCodesDistributionGraph, {
  GetCptDodesDistributionDataSeries,
} from '../../../components/Graphs/CptCodesDistributionGraph';
import { ColorPalette } from '../../Dashboards/DashboardColorPalettes';
import { openModalAction } from '../../../actions/modal';

//#region definitions
export const DASHBOARD_ID = 'ReportDashboard';

const customFormattingConfig = {
  backgroundColor: {
    type: 'color',
    name: 'backgroundColor',
    label: 'Background color',
    defaultValue: '#f7f7f7',
    canBeSetByDashboard: true,
  },
  valueColor: {
    type: 'color',
    name: 'value',
    label: 'Value color',
    defaultValue: '#000000',
    canBeSetByDashboard: true,
  },
  titleColor: {
    type: 'color',
    name: 'titleColor',
    label: 'Title color',
    defaultValue: '#000000',
    canBeSetByDashboard: true,
  },
};

const EnrollmentsWithNoCptCodesRpmMetadata = {
  id: 'EnrollmentsWithNoCptCodesRpm',
  name: 'RPM - Enrollments with no CPT code',
  group: 'RPM',
  permission: '',
  defProps: { i: 'EnrollmentsWithNoCptCodesRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const EnrollmentsWithNoCptCodesRtmMetadata = {
  id: 'EnrollmentsWithNoCptCodesRtm',
  name: 'RTM - Enrollments with no CPT code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'EnrollmentsWithNoCptCodesRtm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWith20minCodeRtmMetadata = {
  id: 'PatientsWith20minCodeRtm',
  name: 'RTM - Enrollments with 20 min code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'PatientsWith20minCodeRtm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWith40minCodeRtmMetadata = {
  id: 'PatientsWith40minCodeRtm',
  name: 'RTM - Enrollments with 40 min code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'PatientsWith40minCodeRtm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWith60minCodeRtmMetadata = {
  id: 'PatientsWith60minCodeRtm',
  name: 'RTM - Enrollments with 60 min code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'PatientsWith60minCodeRtm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWith20minCodeRpmMetadata = {
  id: 'PatientsWith20minCodeRpm',
  name: 'RPM - Enrollments with 20 min code',
  group: 'RPM',
  permission: '',
  defProps: { i: 'PatientsWith20minCodeRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWith40minCodeRpmMetadata = {
  id: 'PatientsWith40minCodeRpm',
  name: 'RPM - Enrollments with 40 min code',
  group: 'RPM',
  permission: '',
  defProps: { i: 'PatientsWith40minCodeRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWith60minCodeRpmMetadata = {
  id: 'PatientsWith60minCodeRpm',
  name: 'RPM - Enrollments with 60 min code',
  group: 'RPM',
  permission: '',
  defProps: { i: 'PatientsWith60minCodeRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsWoMonitoringCodeRtmRespMetadata = {
  id: 'PatientsWoMonitoringCodeRtmResp',
  name: 'RTM Resp - Enrollments with no monitoring code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'PatientsWoMonitoringCodeRtmResp', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const PatientsWoMonitoringCodeRtmMsMetadata = {
  id: 'PatientsWoMonitoringCodeRtmMS',
  name: 'RTM Musc - Enrollments with no monitoring code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'PatientsWoMonitoringCodeRtmMS', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const PatientsWoMonitoringCodeRtmBehavMetadata = {
  id: 'PatientsWoMonitoringCodeRtmBehav',
  name: 'RTM Bhv - Enrollments with no monitoring code',
  group: 'RTM',
  permission: '',
  defProps: { i: 'PatientsWoMonitoringCodeRtmBehav', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const PatientsWoMonitoringCodeRpmMetadata = {
  id: 'PatientsWoMonitoringCodeRpm',
  name: 'RPM - Enrollments with no monitoring code',
  group: 'RPM',
  permission: '',
  defProps: { i: 'PatientsWoMonitoringCodeRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const AvgEnrommentTimeRpmMetadata = {
  id: 'AvgEnrommentTimeRpm',
  name: 'RPM - Avg Enrollment Time',
  group: 'RPM',
  permission: '',
  defProps: { i: 'AvgEnrommentTimeRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const AvgEnrommentTimeRtmMetadata = {
  id: 'AvgEnrommentTimeRtm',
  name: 'RTM - Avg Enrollment Time',
  group: 'RTM',
  permission: '',
  defProps: { i: 'AvgEnrommentTimeRtm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const NewEnrollmentsRpmMetadata = {
  id: 'NewEnrollmentsRpm',
  name: 'RPM - New Enrollments',
  group: 'RPM',
  permission: '',
  defProps: { i: 'NewEnrollmentsRpm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};
const NewEnrollmentsRtmMetadata = {
  id: 'NewEnrollmentsRtm',
  name: 'RTM - New Enrollments',
  group: 'RTM',
  permission: '',
  defProps: { i: 'NewEnrollmentsRtm', w: 4, h: 2, minW: 2, minH: 2 },
  configuration: customFormattingConfig,
};

const PatientsPerProgramPerMonthMetadata = {
  id: 'PatientsPerProgramPerMonth',
  name: 'Patients per Program',
  permission: '',
  defProps: { i: 'PatientsPerProgramPerMonth', w: 4, h: 4, minW: 2, minH: 2 },
};

const AdherenceDistributionMetadata = {
  id: 'AdherenceDistribution',
  name: 'Adherence Distribution',
  permission: '',
  defProps: { i: 'AdherenceDistribution', w: 4, h: 4, minW: 2, minH: 2 },
};

const CptCodesDistributionMetadata = {
  id: 'CptCodesDistribution',
  name: 'CPT Codes Distribution',
  permission: '',
  defProps: { i: 'CptCodesDistribution', w: 4, h: 4, minW: 2, minH: 2 },
};

export const getWidgetMetadata = enableWidgetConfig => [
  {
    ...EnrollmentsWithNoCptCodesRpmMetadata,
    configuration: enableWidgetConfig ? EnrollmentsWithNoCptCodesRpmMetadata.configuration : undefined,
  },
  {
    ...EnrollmentsWithNoCptCodesRtmMetadata,
    configuration: enableWidgetConfig ? EnrollmentsWithNoCptCodesRtmMetadata.configuration : undefined,
  },
  {
    ...PatientsPerProgramPerMonthMetadata,
    configuration: enableWidgetConfig ? PatientsPerProgramPerMonthMetadata.configuration : undefined,
  },
  {
    ...PatientsWith20minCodeRtmMetadata,
    configuration: enableWidgetConfig ? PatientsWith20minCodeRtmMetadata.configuration : undefined,
  },
  {
    ...PatientsWith40minCodeRtmMetadata,
    configuration: enableWidgetConfig ? PatientsWith40minCodeRtmMetadata.configuration : undefined,
  },
  {
    ...PatientsWith60minCodeRtmMetadata,
    configuration: enableWidgetConfig ? PatientsWith60minCodeRtmMetadata.configuration : undefined,
  },
  {
    ...PatientsWoMonitoringCodeRtmRespMetadata,
    configuration: enableWidgetConfig ? PatientsWoMonitoringCodeRtmRespMetadata.configuration : undefined,
  },
  {
    ...PatientsWoMonitoringCodeRtmMsMetadata,
    configuration: enableWidgetConfig ? PatientsWoMonitoringCodeRtmMsMetadata.configuration : undefined,
  },
  {
    ...PatientsWoMonitoringCodeRtmBehavMetadata,
    configuration: enableWidgetConfig ? PatientsWoMonitoringCodeRtmBehavMetadata.configuration : undefined,
  },
  {
    ...PatientsWith20minCodeRpmMetadata,
    configuration: enableWidgetConfig ? PatientsWith20minCodeRpmMetadata.configuration : undefined,
  },
  {
    ...PatientsWith40minCodeRpmMetadata,
    configuration: enableWidgetConfig ? PatientsWith40minCodeRpmMetadata.configuration : undefined,
  },
  {
    ...PatientsWith60minCodeRpmMetadata,
    configuration: enableWidgetConfig ? PatientsWith60minCodeRpmMetadata.configuration : undefined,
  },
  {
    ...AvgEnrommentTimeRpmMetadata,
    configuration: enableWidgetConfig ? AvgEnrommentTimeRpmMetadata.configuration : undefined,
  },
  {
    ...AvgEnrommentTimeRtmMetadata,
    configuration: enableWidgetConfig ? AvgEnrommentTimeRtmMetadata.configuration : undefined,
  },
  {
    ...NewEnrollmentsRpmMetadata,
    configuration: enableWidgetConfig ? NewEnrollmentsRpmMetadata.configuration : undefined,
  },
  {
    ...NewEnrollmentsRtmMetadata,
    configuration: enableWidgetConfig ? NewEnrollmentsRtmMetadata.configuration : undefined,
  },
  {
    ...PatientsWoMonitoringCodeRpmMetadata,
    configuration: enableWidgetConfig ? PatientsWoMonitoringCodeRpmMetadata.configuration : undefined,
  },
  {
    ...AdherenceDistributionMetadata,
    configuration: enableWidgetConfig ? AdherenceDistributionMetadata.configuration : undefined,
  },
  {
    ...CptCodesDistributionMetadata,
    configuration: enableWidgetConfig ? CptCodesDistributionMetadata.configuration : undefined,
  },
];

const l = [
  { ...EnrollmentsWithNoCptCodesRpmMetadata.defProps, x: 0, y: 12, w: 4, h: 2 },
  { ...EnrollmentsWithNoCptCodesRtmMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...PatientsWith20minCodeRtmMetadata.defProps, x: 8, y: 0, w: 4, h: 2 },
  { ...PatientsWith40minCodeRtmMetadata.defProps, x: 8, y: 2, w: 4, h: 2 },
  { ...PatientsWith60minCodeRtmMetadata.defProps, x: 8, y: 4, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRtmRespMetadata.defProps, x: 4, y: 0, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRtmMsMetadata.defProps, x: 4, y: 2, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRtmBehavMetadata.defProps, x: 4, y: 4, w: 4, h: 2 },
  { ...PatientsWith20minCodeRpmMetadata.defProps, x: 8, y: 12, w: 4, h: 2 },
  { ...PatientsWith40minCodeRpmMetadata.defProps, x: 8, y: 14, w: 4, h: 2 },
  { ...PatientsWith60minCodeRpmMetadata.defProps, x: 8, y: 16, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRpmMetadata.defProps, x: 0, y: 14, w: 4, h: 2 },
  { ...AvgEnrommentTimeRpmMetadata.defProps, x: 4, y: 14, w: 4, h: 2 },
  { ...AvgEnrommentTimeRtmMetadata.defProps, x: 0, y: 4, w: 4, h: 2 },
  { ...NewEnrollmentsRpmMetadata.defProps, x: 4, y: 12, w: 4, h: 2 },
  { ...NewEnrollmentsRtmMetadata.defProps, x: 0, y: 2, w: 4, h: 2 },
  { ...PatientsPerProgramPerMonthMetadata.defProps, x: 8, y: 6, w: 4, h: 6 },
  { ...AdherenceDistributionMetadata.defProps, x: 0, y: 6, w: 4, h: 6 },
  { ...CptCodesDistributionMetadata.defProps, x: 4, y: 6, w: 4, h: 6 },
];

const m = [
  { ...EnrollmentsWithNoCptCodesRpmMetadata.defProps, x: 0, y: 0, w: 8, h: 2 },
  { ...EnrollmentsWithNoCptCodesRtmMetadata.defProps, x: 0, y: 14, w: 8, h: 2 },
  { ...PatientsWith20minCodeRtmMetadata.defProps, x: 4, y: 18, w: 4, h: 2 },
  { ...PatientsWith40minCodeRtmMetadata.defProps, x: 4, y: 20, w: 4, h: 2 },
  { ...PatientsWith60minCodeRtmMetadata.defProps, x: 4, y: 22, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRtmRespMetadata.defProps, x: 0, y: 18, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRtmMsMetadata.defProps, x: 0, y: 20, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRtmBehavMetadata.defProps, x: 0, y: 22, w: 4, h: 2 },
  { ...PatientsWith20minCodeRpmMetadata.defProps, x: 4, y: 2, w: 4, h: 2 },
  { ...PatientsWith40minCodeRpmMetadata.defProps, x: 4, y: 4, w: 4, h: 2 },
  { ...PatientsWith60minCodeRpmMetadata.defProps, x: 4, y: 6, w: 4, h: 2 },
  { ...PatientsWoMonitoringCodeRpmMetadata.defProps, x: 0, y: 6, w: 4, h: 2 },
  { ...AvgEnrommentTimeRpmMetadata.defProps, x: 0, y: 2, w: 4, h: 2 },
  { ...AvgEnrommentTimeRtmMetadata.defProps, x: 4, y: 16, w: 4, h: 2 },
  { ...NewEnrollmentsRpmMetadata.defProps, x: 0, y: 4, w: 4, h: 2 },
  { ...NewEnrollmentsRtmMetadata.defProps, x: 0, y: 16, w: 4, h: 2 },
  { ...PatientsPerProgramPerMonthMetadata.defProps, x: 0, y: 8, w: 3, h: 6 },
  { ...AdherenceDistributionMetadata.defProps, x: 3, y: 8, w: 2, h: 6 },
  { ...CptCodesDistributionMetadata.defProps, x: 5, y: 8, w: 3, h: 6 },
];

const s = [
  { ...EnrollmentsWithNoCptCodesRpmMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...EnrollmentsWithNoCptCodesRtmMetadata.defProps, x: 0, y: 18, w: 4, h: 2 },
  { ...PatientsWith20minCodeRtmMetadata.defProps, x: 4, y: 20, w: 2, h: 2 },
  { ...PatientsWith40minCodeRtmMetadata.defProps, x: 4, y: 22, w: 2, h: 2 },
  { ...PatientsWith60minCodeRtmMetadata.defProps, x: 4, y: 24, w: 2, h: 2 },
  { ...PatientsWoMonitoringCodeRtmRespMetadata.defProps, x: 0, y: 20, w: 2, h: 2 },
  { ...PatientsWoMonitoringCodeRtmMsMetadata.defProps, x: 0, y: 22, w: 2, h: 2 },
  { ...PatientsWoMonitoringCodeRtmBehavMetadata.defProps, x: 0, y: 24, w: 2, h: 2 },
  { ...PatientsWith20minCodeRpmMetadata.defProps, x: 4, y: 2, w: 2, h: 2 },
  { ...PatientsWith40minCodeRpmMetadata.defProps, x: 4, y: 4, w: 2, h: 2 },
  { ...PatientsWith60minCodeRpmMetadata.defProps, x: 4, y: 6, w: 2, h: 2 },
  { ...PatientsWoMonitoringCodeRpmMetadata.defProps, x: 0, y: 6, w: 2, h: 2 },
  { ...AvgEnrommentTimeRpmMetadata.defProps, x: 0, y: 2, w: 2, h: 2 },
  { ...AvgEnrommentTimeRtmMetadata.defProps, x: 4, y: 18, w: 2, h: 2 },
  { ...NewEnrollmentsRpmMetadata.defProps, x: 0, y: 4, w: 2, h: 2 },
  { ...NewEnrollmentsRtmMetadata.defProps, x: 0, y: 18, w: 2, h: 2 },
  { ...PatientsPerProgramPerMonthMetadata.defProps, x: 0, y: 8, w: 4, h: 5 },
  { ...AdherenceDistributionMetadata.defProps, x: 0, y: 13, w: 2, h: 5 },
  { ...CptCodesDistributionMetadata.defProps, x: 2, y: 13, w: 2, h: 5 },
];

export const DefaultLayouts = { l, m, s };
//#endregion

function ReportDashboard(props) {
  const [widgets, setWidgets] = useState([]);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const [refreshEnable, setRefreshEnable] = useState(true);
  const allOrgs = {
    value: 'all',
    label: Strings.patientDashboard.all,
  };
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(allOrgs);
  const [data, setData] = useState([]);
  const [data30days, setData30days] = useState({});

  const periods = getDefaultPeriods();

  const numberWithTitleWidget = (meta, value, percent = undefined, unit = '') => {
    const displayValue = isNaN(value) ? '-' : `${value}${unit}${isNaN(percent) ? '' : ` (${percent}%)`}`;
    return {
      ...meta,
      configuration: props.dashboardSettingsFeature ? meta.configuration : undefined,
      noPadding: true,
      noBorder: true,
      render: (
        <ReadingsWidget
          color={'#000'}
          title={meta.name}
          value={displayValue}
          type={WidgetTypes.plain}
          // valueTooltip: PropTypes.string,
        />
      ),
    };
  };
  const initializeDashboard = () => {
    const widgetEwoCptCodeRpm = numberWithTitleWidget(
      EnrollmentsWithNoCptCodesRpmMetadata,
      data30days?.RPM?.enrollments_without_cpt_codes,
      roundToDecimal(data30days?.RPM?.percent_of_enrollments_without_cpt_codes, 2),
    );
    const widgetEwoCptCodeRtm = numberWithTitleWidget(
      EnrollmentsWithNoCptCodesRtmMetadata,
      data30days?.RTM?.enrollments_without_cpt_codes,
      roundToDecimal(data30days?.RTM?.percent_of_enrollments_without_cpt_codes, 2),
    );
    const widget20minCodeRtm = numberWithTitleWidget(
      PatientsWith20minCodeRtmMetadata,
      data30days?.RTM?.cpt_codes_statistics?.['CPT 98980']?.qualified_enrollments,
      roundToDecimal(data30days?.RTM?.cpt_codes_statistics?.['CPT 98980']?.percent_qualified, 2),
    );
    const widget40minCodeRtm = numberWithTitleWidget(
      PatientsWith40minCodeRtmMetadata,
      data30days?.RTM?.cpt_codes_statistics?.['CPT 98981 #1'].qualified_enrollments,
      roundToDecimal(data30days?.RTM?.cpt_codes_statistics?.['CPT 98981 #1'].percent_qualified, 2),
    );
    const widget60minCodeRtm = numberWithTitleWidget(
      PatientsWith60minCodeRtmMetadata,
      data30days?.RTM?.cpt_codes_statistics?.['CPT 98981 #2'].qualified_enrollments,
      roundToDecimal(data30days?.RTM?.cpt_codes_statistics?.['CPT 98981 #2'].percent_qualified, 2),
    );
    const widgetmonitCodeRtmResp = numberWithTitleWidget(
      PatientsWoMonitoringCodeRtmRespMetadata,
      data30days?.RTM?.cpt_codes_statistics?.['CPT 98976'].qualified_enrollments,
      roundToDecimal(data30days?.RTM?.cpt_codes_statistics?.['CPT 98976'].percent_qualified, 2),
    );
    const widgetmonitCodeRtmMs = numberWithTitleWidget(
      PatientsWoMonitoringCodeRtmMsMetadata,
      data30days?.RTM?.cpt_codes_statistics?.['CPT 98977'].qualified_enrollments,
      roundToDecimal(data30days?.RTM?.cpt_codes_statistics?.['CPT 98977'].percent_qualified, 2),
    );
    const widgetmonitCodeRtmBehav = numberWithTitleWidget(
      PatientsWoMonitoringCodeRtmBehavMetadata,
      data30days?.RTM?.cpt_codes_statistics?.['CPT 98978'].qualified_enrollments,
      roundToDecimal(data30days?.RTM?.cpt_codes_statistics?.['CPT 98978'].percent_qualified, 2),
    );
    const widget20minCodeRpm = numberWithTitleWidget(
      PatientsWith20minCodeRpmMetadata,
      data30days?.RPM?.cpt_codes_statistics?.['CPT 99457']?.qualified_enrollments,
      roundToDecimal(data30days?.RPM?.cpt_codes_statistics?.['CPT 99457']?.percent_qualified, 2),
    );
    const widget40minCodeRpm = numberWithTitleWidget(
      PatientsWith40minCodeRpmMetadata,
      data30days?.RPM?.cpt_codes_statistics?.['CPT 99458 #1'].qualified_enrollments,
      roundToDecimal(data30days?.RPM?.cpt_codes_statistics?.['CPT 99458 #1'].percent_qualified, 2),
    );
    const widget60minCodeRpm = numberWithTitleWidget(
      PatientsWith60minCodeRpmMetadata,
      data30days?.RPM?.cpt_codes_statistics?.['CPT 99458 #2'].qualified_enrollments,
      roundToDecimal(data30days?.RPM?.cpt_codes_statistics?.['CPT 99458 #2'].percent_qualified, 2),
    );
    const widgetMonitCodeRpm = numberWithTitleWidget(
      PatientsWoMonitoringCodeRpmMetadata,
      data30days?.RPM?.cpt_codes_statistics?.['CPT 99454'].qualified_enrollments,
      roundToDecimal(data30days?.RPM?.cpt_codes_statistics?.['CPT 99454'].percent_qualified, 2),
    );
    const widgetNewEnrollmentsRpm = numberWithTitleWidget(NewEnrollmentsRpmMetadata, data30days?.RPM?.new_enrollments);
    const widgetNewEnrollmentsRtm = numberWithTitleWidget(NewEnrollmentsRtmMetadata, data30days?.RTM?.new_enrollments);
    const widgetAvgEnrollemntTimeRpm = numberWithTitleWidget(
      AvgEnrommentTimeRpmMetadata,
      data30days?.RPM?.average_enrollment_time
        ? roundToDecimal(data30days?.RPM?.average_enrollment_time / (60 * 60 * 60), 0)
        : 0,
      '',
      ` ${Strings.days}`,
    );
    const widgetAvgEnrollemntTimeRtm = numberWithTitleWidget(
      AvgEnrommentTimeRtmMetadata,
      data30days?.RTM?.average_enrollment_time
        ? roundToDecimal(data30days?.RTM?.average_enrollment_time / (60 * 60 * 60), 0)
        : 0,
      '',
      ` ${Strings.days}`,
    );

    const widgetAD = {
      ...AdherenceDistributionMetadata,
      noPadding: true,
      render: graphContainer(
        <AdherenceDistributionGraph
          graphData={
            data30days?.RTM?.adherence_distribution
              ? Object.entries(data30days?.RTM?.adherence_distribution).map(([range, adherence]) => ({
                  range,
                  adherence,
                }))
              : []
          }
          colorPalette={props.colorPalette}
        />,
        'Adherence Distribution',
      ),
    };

    const widgetPPM = {
      ...PatientsPerProgramPerMonthMetadata,
      noPadding: true,
      render: graphContainer(
        <PatientsPerProgramGraph
          graphData={
            data
              ? data
                  .sort((a, b) => a.monthStart - b.monthStart)
                  .map(d => ({
                    month: d.monthStart.format('YYYY/MM'),
                    ...d,
                  }))
              : []
          }
          colorPalette={props.colorPalette}
        />,
        'Patients per Program',
      ),
    };

    const dataForCodesDistribution = [0, 1, 2, 3, 4].map(number => ({
      codes: number,
      rpm: data30days?.RPM?.cpt_codes_distribution?.[number] || 0,
      rtm: data30days?.RTM?.cpt_codes_distribution?.[number] || 0,
    }));
    const widgetCodesDistr = {
      ...CptCodesDistributionMetadata,
      noPadding: true,
      render: graphContainerWithLegend(
        <CptCodesDistributionGraph graphData={dataForCodesDistribution} colorPalette={props.colorPalette} />,
        'CPT Codes Distribution',
        GetCptDodesDistributionDataSeries(props.colorPalette),
      ),
    };

    setWidgets([
      widgetEwoCptCodeRpm,
      widgetEwoCptCodeRtm,
      widgetPPM,
      widgetAD,
      widget20minCodeRtm,
      widget40minCodeRtm,
      widget60minCodeRtm,
      widgetmonitCodeRtmResp,
      widgetmonitCodeRtmMs,
      widgetmonitCodeRtmBehav,
      widget20minCodeRpm,
      widget40minCodeRpm,
      widget60minCodeRpm,
      widgetMonitCodeRpm,
      widgetCodesDistr,
      widgetNewEnrollmentsRpm,
      widgetNewEnrollmentsRtm,
      widgetAvgEnrollemntTimeRpm,
      widgetAvgEnrollemntTimeRtm,
    ]);
  };

  useEffect(() => {
    initializeDashboard();
  }, [data, data30days, props.colorPalette]);

  useEffect(() => {
    props.getOrgs().then(resp => {
      if (resp?.type === ORGANIZATION_LIST_RESULT) {
        setOrgs(
          [allOrgs].concat(
            resp.response?.data?.map(o => {
              return { ...o, value: o.masked_id, label: o.name };
            }),
          ),
        );
      }
    });
  }, [refreshTimestamp]);

  React.useEffect(() => {
    setData([]);

    let request = {};
    if (selectedOrg.value !== 'all') {
      request.org_id = selectedOrg.value;
    }

    request.start_time = moment()
      .subtract(30, 'days')
      .startOf('day')
      .format();
    request.end_time = moment()
      .subtract(1, 'days')
      .endOf('day')
      .format();
    props.getProgramStatistics(request).then(resp => {
      setData30days(resp.response);
    });

    periods.forEach(period => {
      request.start_time = period.start;
      request.end_time = period.end;
      props.getProgramStatistics(request).then(resp => {
        const monthData = resp.response;
        setData(d =>
          d.concat([
            {
              monthStart: moment(period.start),
              rtmResp: monthData.RTM?.total_patients_per_subprogram?.RTMRespiratory || 0,
              rtmMs: monthData.RTM?.total_patients_per_subprogram?.RTMMusculoskeletal || 0,
              rtmBeh: monthData.RTM?.total_patients_per_subprogram?.RTMBehavioralHealth || 0,
              rpm: monthData.RPM?.total_patients_per_subprogram?.RPM || 0,
            },
          ]),
        );
      });
    });
  }, [selectedOrg]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
    props.filters.forEach(f => {
      // if (f.id === PatientsEnrollment.widgetId && f.checked) props.refreshPE();
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        isBlack
        left={Strings.last30days}
        right={
          <React.Fragment>
            <Select data={orgs} value={selectedOrg} onChange={setSelectedOrg} isSearchable />
            <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
            {props.dashboardSettingsFeature && (
              <div className="settings-button">
                <Button class="settings-button-icon" onClick={props.configDashboard}></Button>
              </div>
            )}
            <DashboardFilter dashboardId={DASHBOARD_ID} />
            <Button class="refresh" disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
      />
      {widgets.length > 0 && !_.isEmpty(DefaultLayouts) && (
        <OverlayScrollbarsComponent
          defer
          className="scrollbar-right-margin"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        >
          <DashboardLayout
            dashboardId={DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={DefaultLayouts}
            additionalData={props.dashboardSettingsFeature}
            rowHeight={45}
            breakpoints={{ l: 1500, m: 1200, s: 800 }}
          />
        </OverlayScrollbarsComponent>
      )}
    </React.Fragment>
  );
}

ReportDashboard.propTypes = {
  userName: PropTypes.string,
  filters: PropTypes.array,
  configuration: PropTypes.any,
  initializeDashboard: PropTypes.func,
  getOrgs: PropTypes.func.isRequired,
  getProgramStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { dashboardFilters } = state.dashboardState;
  const filters = dashboardFilters[DASHBOARD_ID];
  const configuration = state.dashboardState.configuration[DASHBOARD_ID];
  const labFeatures = state.labState.features;
  return {
    userName: profile ? (profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username) : '',
    filters,
    configuration,
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
    dashboardSettingsFeature: labFeatures.find(f => f.name === 'Dashboard configuration')?.selected,
  };
};

const mapDispatchToProps = dispatch => ({
  initializeDashboard: (dashboardId, defaultLayouts, widgets, force) =>
    dispatch(actions.initializeDashboard(dashboardId, defaultLayouts, widgets, force)),
  getProgramStatistics: request => dispatch(actions.getProgramStatistics(request)),
  getOrgs: request => dispatch(orgActions.getOrgs(request)),
  configDashboard: () =>
    dispatch(openModalAction('dashboard-configuration', { dashboardId: DASHBOARD_ID, additionalData: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboard);
