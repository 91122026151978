import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { cloneDeep } from 'lodash';
import { closeModal } from 'actions/modal';
import moment from 'moment-timezone';

import { DateInput, Input, MultiSelectField, PhoneInputField, SelectField, TextArea } from '../../containers/Form';
import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { notificationActions } from '../../components/Notification/redux/actions';
import './EnrollPatientModal.scss';
import {
  EDIT_PATIENT_ERROR,
  ENROLL_PATIENT_ERROR,
  GET_REMINDERS_DEFAULT_MESSAGES_RESULT,
  SEND_WELCOME_MESSAGE_ERROR,
} from '../../pages/SuperUser/Patients/redux/constants';
import { validateEmail, validateFirstName, validateLastName, validatePhoneNo } from '../../utils/validators/rpmPatient';
import {
  ATTACH_PATIENT_TO_KIT_ERROR,
  DELETE_KIT_ERROR,
  DELETE_KIT_RESULT,
  GET_KIT_DETAILS_RESULT,
  GET_PATIENT_KIT_INFO_RESULT,
} from '../../pages/Kits/redux/constants';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import { RPM_PATIENT_STATUS } from '../../constants';
import { flatProgramsSubprogramsList, getSubprogramForApi, programs, subprograms } from '../../utils/cmsPrograms';
import Wizard from '../../containers/Modal/Wizard';
import { SelectedOptions } from '../../components/Select/Multiselect';
import kitIdImg from '../../images/kitId.png';
import { ASSIGN_PATIENT_TO_COHORT_ERROR } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { EnrollPatientModalPages } from '../_ModalsMetadata/ModalsMetadata';

const EnrollPatientModal = props => {
  const enrollments = _.isEmpty(props.data?.patient?.patientEnrollment?.enrollments)
    ? []
    : Object.values(props.data.patient.patientEnrollment.enrollments).map(v => {
        return {
          ...v,
          name: programs[v.program],
          subprogram: subprograms[v.subprogram],
          isDefault: v.program === props.data.patient.patientEnrollment.defaultProgram,
          id: flatProgramsSubprogramsList.find(
            l => l.name === programs[v.program] && l.subprogram === subprograms[v.subprogram],
          )?.id,
        };
      });
  // data
  const [patient, setPatient] = useState(
    props.data.patient
      ? {
          ...props.data.patient,
          medicalHistory: null,
        }
      : {},
  );
  const [kit, setKit] = useState({});
  const [kits, setKits] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState(enrollments);
  const [selectedCondition, setSelectedCondition] = useState(() => calculateConditions(props.data.patient));
  const [welcomeMessage, setWelcomeMessage] = useState();
  const [defaultWelcomeMessage, setDefaultWelcomeMessage] = useState();
  const [endDate, setEndDate] = useState(calculateEndDate(props.data.patient));

  // checkboxes
  const [writtenConsentConfirm, setWrittenConsentConfirm] = useState(
    enrollments.length > 0 ? enrollments[0].writtenConsent : false,
  );
  const [verbalConsentConfirm, setVerbalConsentConfirm] = useState(
    enrollments.length > 0 ? enrollments[0].verbalConsent : false,
  );
  const [conditionConfirm, setConditionConfirm] = useState(props.data.patient?.conditions);
  const [kitConfirm, setKitConfirm] = useState(false);
  const [welcomeMessageChecked, setWelcomeMessageChecked] = useState(false);

  // wizard
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  // const [validationErrors, setValidationErrors] = useState([]);

  // other
  const [onlyAssigingKit, setOnlyAssigingKit] = useState(false);
  const [showKitPicture, setShowKitPicture] = useState(false);

  const editEndDateMode = !_.isEmpty(props.data.patient?.patientEnrollment?.enrollments);

  const getKitName = id => {
    props.getKitDetails(id).then(resp => {
      if (resp.type === GET_KIT_DETAILS_RESULT) {
        setKit(resp.response);
      }
    });
  };

  useEffect(() => {
    // get patient kit
    props.getPatientKitInfo(patient.id).then(resp => {
      if (resp.type === GET_PATIENT_KIT_INFO_RESULT && resp.response.kit?.id) {
        getKitName(resp.response.kit.id);
      }
    });

    props.getKits({ havingPatients: false }).then(resp =>
      setKits(
        resp.response.kits.map(d => {
          return { label: `${d.id}${d.name ? ` (${d.name})` : ''}`, value: d.id, name: d.name };
        }),
      ),
    );

    props.getRemindersDefaultMessages({ language: patient.language }).then(resp => {
      if (resp.type === GET_REMINDERS_DEFAULT_MESSAGES_RESULT) {
        const defaultMessage = resp.response?.data?.find(m => m.type === 'welcome_msg_sms')?.message;
        setDefaultWelcomeMessage(defaultMessage);
        setWelcomeMessage(defaultMessage);
      }
    });

    if (patient.status === RPM_PATIENT_STATUS.verified) {
      setOnlyAssigingKit(true);
    } else {
      props.getConditions();
    }
  }, []);

  useEffect(() => {
    const request = { limit: 1000 };
    if (patient.facilityId) {
      request.facilityId = patient.facilityId;
    }
    if (patient.conditions) {
      request.conditionId = Object.keys(patient.conditions).join(',');
    }

    props.getCohorts(request);
  }, [patient.conditions]);

  function calculateEndDate(p) {
    let ret = '';
    if (!p?.patientEnrollment || !p?.patientEnrollment.enrollments) {
      return ret;
    }
    Object.values(p.patientEnrollment.enrollments).forEach(v => {
      if (v.enrollmentEnd && ret != v.enrollmentEnd) {
        ret = new Date(v.enrollmentEnd);
      }
    });
    return ret;
  }

  function calculateConditions(p) {
    if (enrollments.length > 0) {
      return enrollments[0].conditionId;
    }
    return p?.conditions && Object.keys(p.conditions).length === 1 ? Object.keys(p.conditions)[0] : null;
  }

  const onTextChange = event => {
    if (event.target.value != ' ') {
      const newPatient = cloneDeep(patient);
      newPatient[event.target.getAttribute('name')] = event.target.value;
      setPatient(newPatient);
    }
  };

  const onPhoneChange = (event, field) => {
    setPatient(p => ({
      ...p,
      [field]: event.target.value,
    }));
  };

  const onConditionChange = option => {
    if (option.value != ' ') {
      setSelectedCondition(option.value);
      setConditionConfirm(true);
    }
  };

  // edit patient and return true if it was successful
  const updatePatientData = async () => {
    let ret = false;
    if (
      patient.firstName !== props.data.patient?.firstName ||
      patient.lastName !== props.data.patient?.lastName ||
      patient.primaryPhoneNo !== props.data.patient?.primaryPhoneNo ||
      patient.textPhoneNo !== props.data.patient?.textPhoneNo ||
      patient.email !== props.data.patient?.email
    ) {
      const data = {
        ...patient,
        conditions: Object.keys(patient.conditions),
      };
      await props.onEditPatient(data).then(response => {
        if (response) {
          if (response.type === EDIT_PATIENT_ERROR) {
            const error = response.response?.data?.error?.message;
            props.showNotification(error, 5000, true);
            ret = false;
          }
          ret = true;
        }
      });
    } else {
      return true;
    }
    return ret;
  };

  const assignToCohort = async () => {
    let ret = false;
    if (patient.cohortId && patient.cohortId !== props.data.patient?.cohortId) {
      await props.assignPatientToCohort(parseInt(patient.id, 10), patient.cohortId).then(response => {
        if (response) {
          if (response.type === ASSIGN_PATIENT_TO_COHORT_ERROR) {
            const error = response.response?.data?.error?.message;
            props.showNotification(error, 5000, true);
            ret = false;
          }
          ret = true;
        }
      });
    } else {
      return true;
    }
    return ret;
  };

  const getValidationErrros = () => {
    const errors = validateFirstName(patient.firstName)
      .concat(validateLastName(patient.lastName))
      .concat(validatePhoneNo(patient.primaryPhoneNo, 'primaryPhoneNo', true))
      .concat(validatePhoneNo(patient.textPhoneNo, 'textPhoneNo', true))
      .concat(validateEmail(patient.email));

    if (!conditionConfirm) {
      errors.push({ property: 'conditionConfirm', errors: [], missing: true });
    }
    if (!selectedCondition) {
      errors.push({ property: 'selectedCondition', errors: [], missing: true });
    }
    if (!verbalConsentConfirm && !writtenConsentConfirm) {
      errors.push({ property: 'consents', errors: [], missing: true });
    }
    if (!selectedPrograms || selectedPrograms.length === 0) {
      errors.push({ property: 'programs', errors: [], missing: true });
    }
    if (!selectedPrograms?.some(p => p.isDefault)) {
      errors.push({ property: 'defaultProgram', errors: [], missing: true });
    }
    if (
      endDate &&
      moment(endDate).unix() <
        moment()
          .endOf('day')
          .unix()
    ) {
      errors.push({ property: 'endDate', errors: [Strings.errors.dateInThePast], missing: false });
    }
    return errors;
  };

  const onKitSelected = option => {
    setKit({ id: option.value, name: option.name });
    setKitConfirm(true);
  };

  const onAssignKit = async () => {
    if (!kitConfirm) {
      return true;
    }
    // if patient kit remains unchanged
    if (props.kit && props.kit.id === kit.id) {
      return true;
    }

    // otherwise if patient kit is reassigned, delete the one he has
    let deleteKitSuccessful = true;
    if (props.kit) {
      await props.deleteKit(props.kit.id).then(resp => {
        if (resp.type === DELETE_KIT_RESULT) {
          deleteKitSuccessful = true;
        } else if (resp.type === DELETE_KIT_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
          deleteKitSuccessful = false;
        }
      });
    }
    if (!deleteKitSuccessful) {
      return false;
    }

    // assign new kit
    let ret = false;
    await props.attachKitToPatient(kit.id, patient.id).then(resp => {
      if (resp.type === ATTACH_PATIENT_TO_KIT_ERROR) {
        const error = resp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        ret = false;
      } else {
        props.showNotification(Strings.formatString(Strings.kitAssignedInfo, kit.id, patient.patientName));
        ret = true;
      }
    });
    return ret;
  };

  const enrollPatient = async () => {
    let ret = false;
    const data = { patient_id: patient.id };

    data.data = selectedPrograms.map(program => {
      const enrollmentData = { condition_id: selectedCondition };

      if (program.subprogram) {
        enrollmentData.subprogram = getSubprogramForApi(program.subprogram);
      }

      if (!onlyAssigingKit) {
        enrollmentData.default_program = program.isDefault;
        enrollmentData.verbal_consent = verbalConsentConfirm;
        enrollmentData.written_consent = writtenConsentConfirm;
      }

      if (kitConfirm) {
        enrollmentData.kit_id = kit.id;
      }

      if (endDate) {
        enrollmentData.end_date = moment(endDate).format();
      } else {
        // from backend documentaion: To unset end_date set it to 2300-01-01T00:00:00Z
        const magicDateToClearEndDate = '2300-01-01T00:00:00Z';
        enrollmentData.end_date = magicDateToClearEndDate;
      }

      return enrollmentData;
    });

    await props.enrollPatient(data).then(resp => {
      if (resp.type === ENROLL_PATIENT_ERROR) {
        const error = resp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        ret = false;
      } else {
        props.showNotification(Strings.formatString(Strings.patientEnrolledInfo, patient.patientName));
        ret = true;
      }
      return resp;
    });
    return ret;
  };

  const sendWelcomeSms = async () => {
    let ret = false;
    if (welcomeMessageChecked) {
      const request = { user_id: patient.id, kit_id: kit.id };
      if (welcomeMessage !== defaultWelcomeMessage) {
        request.message = welcomeMessage;
      }
      await props.sendWelcomeMessage(request).then(resp => {
        if (resp.type === SEND_WELCOME_MESSAGE_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
          ret = false;
        } else {
          props.showNotification(Strings.formatString(Strings.welcomeSmsSent, patient.patientName));
          ret = true;
        }
        return resp;
      });
    } else {
      ret = true;
    }
    return ret;
  };

  const onSave = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const assignSuccessful = await assignToCohort();
    if (assignSuccessful) {
      const updateSuccessful = await updatePatientData();
      if (updateSuccessful) {
        const kitSuccessful = await onAssignKit();
        if (kitSuccessful) {
          const enrollSuccessful = await enrollPatient();
          if (enrollSuccessful) {
            const welcomeSmsSuccessful = await sendWelcomeSms();
            if (welcomeSmsSuccessful) {
              props.closeModalWithNextAction();
            }
          }
        }
      }
    }
    setSaveBtnClicked(false);
  };

  const validationErrors = getValidationErrros();
  // wizzard pages definitions
  const confirmationsPage = (
    <React.Fragment>
      <div className="page-info-header">{patient.patientName}</div>
      <div className="horizontal-flex">
        <Input
          name="firstName"
          id="firstName"
          key="firstName"
          type="text"
          value={patient.firstName}
          onChange={onTextChange}
          label={Strings.firstName}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'firstName')?.errors || []}
        />
        <Input
          name="lastName"
          id="lastName"
          key="lastName"
          type="text"
          value={patient.lastName}
          onChange={onTextChange}
          label={Strings.lastName}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'lastName')?.errors || []}
        />
      </div>
      <div className="horizontal-flex">
        <PhoneInputField
          align="left"
          name="textPhoneNo"
          id="textPhoneNo"
          value={patient.textPhoneNo}
          onChange={e => onPhoneChange(e, 'textPhoneNo')}
          label={Strings.capPatient.textNumber}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'textPhoneNo')?.errors || []}
        />
        <PhoneInputField
          align="left"
          name="primaryPhoneNo"
          id="primaryPhoneNo"
          value={patient.primaryPhoneNo}
          onChange={e => onPhoneChange(e, 'primaryPhoneNo')}
          label={Strings.capPatient.phoneNumber}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'primaryPhoneNo')?.errors || []}
        />
      </div>
      <Input
        name="email"
        id="email"
        label={Strings.email}
        type="text"
        value={patient?.email || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'email')?.errors || []}
      />
      <div className="horizontal-flex">
        <div
          className={`checkbox-container border ${
            highlightInvalidFields && !writtenConsentConfirm && !verbalConsentConfirm
              ? 'invalid-control-background'
              : ''
          }`}
        >
          <Input
            type="checkbox"
            name="writtenConsentConfirm"
            id="writtenConsentConfirm"
            label={Strings.writtenConsentGiven}
            checked={writtenConsentConfirm}
            onChange={() => setWrittenConsentConfirm(!writtenConsentConfirm)}
          />
        </div>
        <div
          className={`checkbox-container border ${
            highlightInvalidFields && !writtenConsentConfirm && !verbalConsentConfirm
              ? 'invalid-control-background'
              : ''
          }`}
        >
          <Input
            type="checkbox"
            name="verbalConsentConfirm"
            id="verbalConsentConfirm"
            label={Strings.verbalConsentGiven}
            checked={verbalConsentConfirm}
            onChange={() => setVerbalConsentConfirm(!verbalConsentConfirm)}
          />
        </div>
      </div>
    </React.Fragment>
  );

  const conditionPage = (
    <React.Fragment>
      <div className="page-info-header">{patient.patientName}</div>
      {props.conditions && props.conditions.length > 0 && (
        <SelectField
          name="condition"
          id="condition"
          className="dialog-form"
          placeholder={Strings.select}
          label={Strings.condition}
          value={selectedCondition || ''}
          onChange={onConditionChange}
          data={
            patient.conditions
              ? Object.keys(patient.conditions).map(key => {
                  return { value: key, label: patient.conditions[key].title };
                })
              : []
          }
          isRequired
          highlightInvalid={highlightInvalidFields}
        />
      )}
      {selectedCondition && (
        <div
          className={`checkbox-container border ${
            highlightInvalidFields && !conditionConfirm ? 'invalid-control-background' : ''
          }`}
        >
          <Input
            type="checkbox"
            name="conditionConfirm"
            id="conditionConfirm"
            label={props.conditions?.find(c => c.id === selectedCondition)?.title}
            checked={conditionConfirm}
            onChange={() => setConditionConfirm(!conditionConfirm)}
          />
        </div>
      )}
    </React.Fragment>
  );

  const onMultiselectChange = checkedPrograms => {
    if (!checkedPrograms || checkedPrograms == []) {
      setSelectedPrograms([]);
    } else if (checkedPrograms.length === 1) {
      setSelectedPrograms([{ ...checkedPrograms[0], isDefault: true }]);
    } else {
      const def = selectedPrograms.find(s => s.isDefault);
      setSelectedPrograms(
        checkedPrograms.map(p => {
          return {
            ...p,
            isDefault: def?.name === p.name && def?.subprogram === p.subprogram,
          };
        }),
      );
    }
  };
  const onProgramRemove = option => {
    onMultiselectChange(selectedPrograms.filter(p => p.id !== option.value));
  };

  const onDefaultProgramChange = option => {
    if (option.value !== ' ')
      setSelectedPrograms(
        selectedPrograms.map(p => {
          return {
            ...p,
            isDefault: p.id === option.value,
          };
        }),
      );
  };

  const onProgramSelected = option => {
    if (option.value !== ' ') setSelectedPrograms([{ ...option, isDefault: true }]);
  };

  const onSelectedOption = (option, field) => {
    if (option.value) {
      setPatient(p => ({
        ...p,
        [field]: option.value,
      }));
    }
  };

  const programPage = (
    <React.Fragment>
      <div className="page-info-header">{patient.patientName}</div>
      <div className="horizontal-flex">
        <SelectField
          value={selectedPrograms.map(p => p.id)?.[0]}
          data={flatProgramsSubprogramsList.map(l => ({ ...l, value: l.id, label: l.displayText }))}
          onChange={onProgramSelected}
          placeholder={Strings.select}
          label={Strings.selectProgram}
          isRequired
          highlightInvalid={highlightInvalidFields}
          disabled={editEndDateMode}
        />
        {/* <SelectField
          name="default_program"
          id="default_program"
          label={Strings.defaultProgram}
          value={
            selectedPrograms.find(p => p.isDefault)?.id !== undefined ? selectedPrograms.find(p => p.isDefault)?.id : ''
          }
          onChange={onDefaultProgramChange}
          placeholder={Strings.select}
          data={
            selectedPrograms && selectedPrograms.length > 0
              ? selectedPrograms.map(c => {
                  return { value: c.id, label: c.displayText };
                })
              : [{ value: '', label: Strings.none }]
          }
          isRequired
          highlightInvalid={highlightInvalidFields}
        /> */}
      </div>
      {/* {selectedPrograms && selectedPrograms.length > 0 && (
        <SelectedOptions items={selectedPrograms} onRemove={onProgramRemove} />
      )} */}
      {selectedPrograms && selectedPrograms.length > 0 && (
        <DateInput
          name="end_date"
          id="end_date"
          label={Strings.endDate}
          placeholder="MM / DD / YYYY"
          value={endDate || ''}
          format="MM / dd / yyyy"
          onChange={setEndDate}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'endDate')?.errors || []}
        />
      )}
      {selectedPrograms && selectedPrograms.length > 0 && !editEndDateMode && (
        <SelectField
          name="cohort"
          id="cohort"
          label={Strings.capPatient.assignCohort}
          value={patient.cohortId}
          onChange={option => onSelectedOption(option, 'cohortId')}
          placeholder={Strings.select}
          data={
            props.cohorts && props.cohorts.length > 0
              ? props.cohorts.map(c => {
                  return { value: c.id, label: c.title };
                })
              : []
          }
        />
      )}
    </React.Fragment>
  );

  const kitPage = (
    <React.Fragment>
      <div className="page-info-header">{patient.patientName}</div>
      <div className="control-with-top-right-text">
        <SelectField
          id="kit"
          name="kit"
          label={Strings.searchKit}
          placeholder={Strings.select}
          data={kits}
          onChange={onKitSelected}
          isSearchable
        />
        <div className="right-top-text">
          <span className="label">{Strings.capPatient.whereIsKitId}</span>
          <span
            onMouseEnter={() => setShowKitPicture(true)}
            onMouseLeave={() => setShowKitPicture(false)}
            className="icon"
          >
            ?
          </span>
        </div>
      </div>

      {showKitPicture && (
        <div className="kit-location">
          <img src={kitIdImg} className="hwImg" alt="hardware" />
          {Strings.wheresKitIdText}
        </div>
      )}

      {kit?.id && (
        <div className="checkbox-container border">
          <Input
            type="checkbox"
            name="kitConfirm"
            id="kitConfirm"
            label={`${kit.id}${kit.name ? ` (${kit.name})` : ''}`}
            checked={kitConfirm}
            onChange={() => setKitConfirm(!kitConfirm)}
          />
        </div>
      )}
    </React.Fragment>
  );

  const welcomeSmsPage = (
    <React.Fragment>
      <div className="page-info-header">{patient.patientName}</div>
      <div className="horizontal-flex reminders-row reminder">
        <Input
          type="checkbox"
          name="welcome_sms"
          id="welcome_sms"
          label={Strings.capPatient.welcomeMessage}
          defaultChecked={welcomeMessageChecked}
          onChange={e => setWelcomeMessageChecked(e.target.checked)}
        />
      </div>
      {welcomeMessageChecked && (
        <div className="reminder-text">
          <TextArea
            name="welcome_sms_text"
            id="welcome_sms_text"
            value={welcomeMessage}
            rows={10}
            onChange={e => setWelcomeMessage(e.target.value)}
            isRequired
            highlightInvalid={highlightInvalidFields}
          />
          {welcomeMessage !== defaultWelcomeMessage && (
            <button
              onClick={() => setWelcomeMessage(defaultWelcomeMessage)}
              className="reset-reminder-text brand-white-gray"
            >
              {Strings.setDefault}
            </button>
          )}
        </div>
      )}
    </React.Fragment>
  );

  const conditionFields = ['conditionConfirm', 'selectedCondition'];
  // const programFields = ['programs', 'defaultProgram', 'endDate'];
  const programFields = ['programs', 'endDate'];
  const consentsFields = ['primaryPhoneNo', 'textPhoneNo', 'email', 'firstName', 'lastName', 'consents'];
  const kitFields = ['kitConfirm'];
  const welcomeMessageFields = ['welcomeMessage'];

  // definitions of pages for modal
  // onNext should return bool indicating if action was successfull and wizzard can switch to next page
  const pages = [
    {
      id: EnrollPatientModalPages.condition.id,
      title: EnrollPatientModalPages.condition.name,
      content: conditionPage,
      emptyFieldsCount: validationErrors.filter(v => conditionFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => conditionFields.includes(v.property)).length === 0,
      closeButton: { text: props.data.workflow ? Strings.skipAndClose : Strings.close },
    },
    {
      id: EnrollPatientModalPages.programs.id,
      title: EnrollPatientModalPages.programs.name,
      content: programPage,
      emptyFieldsCount: validationErrors.filter(v => programFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => programFields.includes(v.property)).length === 0,
    },
    {
      id: EnrollPatientModalPages.confirmations.id,
      title: EnrollPatientModalPages.confirmations.name,
      content: confirmationsPage,
      emptyFieldsCount: validationErrors.filter(v => consentsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => consentsFields.includes(v.property)).length === 0 && !saveBtnClicked,
      nextButton: {
        tooltip: writtenConsentConfirm ? Strings.consentUploadInstruction : null,
      },
    },
    {
      id: EnrollPatientModalPages.kit.id,
      title: EnrollPatientModalPages.kit.name,
      content: kitPage,
      nextButton: {
        text: onlyAssigingKit || kitConfirm ? null : Strings.saveWithNoKit,
      },
      emptyFieldsCount: validationErrors.filter(v => kitFields.includes(v.property) && v.missing).length,
      canGoNext: (!onlyAssigingKit || kitConfirm) && !saveBtnClicked,
    },
    {
      id: EnrollPatientModalPages.welcomeMessage.id,
      title: EnrollPatientModalPages.welcomeMessage.name,
      content: welcomeSmsPage,
      emptyFieldsCount: validationErrors.filter(v => welcomeMessageFields.includes(v.property) && v.missing).length,
      canGoNext:
        validationErrors.filter(v => welcomeMessageFields.includes(v.property)).length === 0 && !saveBtnClicked,
    },
  ];

  const getpagesToShow = () => {
    let ret =
      props.data.pages && props.data.pages.length > 0
        ? props.data.pages.map(p => pages.find(pg => pg.id === p.id))
        : pages;
    if (props.data.ignoreFilters) {
      return ret;
    }
    if (!kitConfirm && !onlyAssigingKit) {
      ret = ret.filter(p => p.id !== EnrollPatientModalPages.welcomeMessage.id);
    }

    return ret.filter(p => props.pagesVisibility.some(pg => pg.id === p.id && pg.visible));
  };

  return (
    <Wizard
      name="enroll-patient"
      pages={getpagesToShow()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
      showPagesFilter={!props.data.ignoreFilters}
    />
  );
};

EnrollPatientModal.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneNo: PropTypes.string,
      textPhoneNo: PropTypes.string,
      conditions: PropTypes.any,
      cohortId: PropTypes.string,
    }),
    action: PropTypes.func,
    nextAction: PropTypes.func,
    pages: PropTypes.array,
    workflow: PropTypes.any,
    ignoreFilters: PropTypes.bool,
  }),
  kit: PropTypes.shape({ id: PropTypes.string }),
  conditions: PropTypes.array,
  cohorts: PropTypes.array,
  getConditions: PropTypes.func,
  getCohorts: PropTypes.func,
  assignPatientToCohort: PropTypes.func,
  getKits: PropTypes.func,
  getKitDetails: PropTypes.func,
  getPatientKitInfo: PropTypes.func,
  onEditPatient: PropTypes.func,
  deleteKit: PropTypes.func,
  attachKitToPatient: PropTypes.func,
  enrollPatient: PropTypes.func,
  showNotification: PropTypes.func,
  closeModalWithNextAction: PropTypes.func,
  pagesVisibility: PropTypes.array,
  getRemindersDefaultMessages: PropTypes.func,
  sendWelcomeMessage: PropTypes.func,
};

EnrollPatientModal.defaultProps = { data: { ignoreFilters: false } };

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('enroll-patient', {}, ownProps.modalId)),
  closeModalWithNextAction: () => {
    dispatch(closeModal('enroll-patient'));
    if (ownProps.data.onSuccess) {
      ownProps.data.onSuccess(ownProps.data.patient);
    }
  },
  getConditions: () => dispatch(actions.getConditions()),
  getCohorts: request => dispatch(cohortActions.getCohorts(request)),
  onEditPatient: data => dispatch(actions.editPatient(data, data.id)),
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  getKitDetails: kitId => dispatch(kitActions.getKitDetails(kitId)),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
  deleteKit: kitId => dispatch(kitActions.deleteKit(kitId)),
  attachKitToPatient: (kitId, patientId) => dispatch(kitActions.attachKitToPatient(kitId, patientId)),
  enrollPatient: data => dispatch(actions.enrollPatient(data)),
  assignPatientToCohort: (patientId, cohortId) => dispatch(cohortActions.assignPatientToCohort(patientId, cohortId)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  getRemindersDefaultMessages: request => dispatch(actions.getRemindersDefaultMessages(request)),
  sendWelcomeMessage: data => dispatch(actions.sendWelcomeMessage(data)),
});

const mapStateToProps = state => {
  return {
    conditions: state.superUser.patients?.conditions,
    cohorts: state.superUser.cohorts?.cohorts,
    kit: state.entities.kits?.patientKit,
    pagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === 'enroll-patient').pages,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrollPatientModal);
