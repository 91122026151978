import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closeModal, openModal, openModalAction } from '../../../actions/modal';
import { CardWrapper } from '../../../components/CardWrapper/CardWrapper';
import { PageHeader } from '../../../components/PageHeader';
import lteUserLink from '../../../documents/LTE_User_Instructions.pdf';
import rpmQuickGuide from '../../../documents/RPM_Quick_Guide.pdf';
import rxcapPortalOnePager from '../../../documents/RxCap_Portal_One_Pager.pdf';
import rxcapLaunchOutlineExternal from '../../../documents/RxCap_Launch_Outline_External.pdf';
import EmptyModal from '../../../modals/EmptyModal/EmptyModal';
import Strings from '../../../Strings';
import blueonboarding from '../../../video/blue-onboarding.mp4';
import RxCapSmartCapInstructionsFinal from '../../../video/RxCapSmartCapInstructionsFinal.mp4';
import styles from './Support.module.scss';

import TabView, { Tab } from '../../../components/TabView';
import { actions } from './redux/actions';
import Location from './Location';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { downloadFromURL } from '../../../utils';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';

const mapStateToProps = state => ({
  support: state.support,
  organization: state.auth.organization,
  eula: state.auth.eula,
});

const mapDispatchToProps = dispatch => ({
  openHelpModal: () => dispatch(openModal('technical-support-modal')),
  openEmptyModal: data => dispatch(openModalAction(EmptyModal.MODAL_NAME, data)),
  closeEmptyModal: () => dispatch(closeModal(EmptyModal.MODAL_NAME)),
  getConfig: id => dispatch(actions.getConfig(id)),
});
class Support extends PureComponent {
  bluetutorial =
    'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20-%20Web%20Portal%20Tutorial%20-%20Final%20-%20parts%201%20to%204.mp4';
  blueenrollment =
    'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20Web%20Portal%20-%20Part%202%20-%20Enrollment.mp4';
  bluebilling =
    'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20Web%20Portal%20-%20Add%20Medications.mp4';
  bluemonitoring =
    'https://storage.googleapis.com/static.rxcap.com/videos/RPM/RxCap%20Web%20Portal%20-%20Medication%20Monitoring.mp4';

  documents = [
    {
      name: Strings.lteUserInstructions,
      type: 'PDF',
      size: '1.25MB',
      language: 'English',
      descriptions: 'Get Started with your new smart cap',
      link: lteUserLink,
    },
    {
      name: Strings.rpmQuickGuide,
      type: 'PDF',
      size: '1.65MB',
      language: 'English',
      descriptions: 'Get started with your new kit',
      link: rpmQuickGuide,
    },
    {
      name: Strings.rxcapPortal,
      type: 'PDF',
      size: '309KB',
      language: 'English',
      descriptions: 'Get Started with the RxCap Portal',
      link: rxcapPortalOnePager,
    },
    {
      name: Strings.rxcapLaunchOutlineExternal,
      type: 'PDF',
      size: '107KB',
      language: 'English',
      descriptions: 'RxCap Outline',
      link: rxcapLaunchOutlineExternal,
    },
  ];
  supportLinkRef = React.createRef();
  static propTypes = {
    closeEmptyModal: PropTypes.func,
    getConfig: PropTypes.func,
    openEmptyModal: PropTypes.func,
    openHelpModal: PropTypes.func,
    support: PropTypes.shape({
      flags: PropTypes.shape({
        chat: PropTypes.any,
        email: PropTypes.any,
        help_center: PropTypes.any,
        smart_cap: PropTypes.any,
      }),
      sections: PropTypes.shape({
        chat: PropTypes.shape({
          description: PropTypes.any,
          phone: PropTypes.any,
          title: PropTypes.any,
        }),
        email: PropTypes.shape({
          description: PropTypes.any,
          emailAddress: PropTypes.any,
          emailSubject: PropTypes.any,
          title: PropTypes.any,
        }),
        help_center: PropTypes.shape({
          description: PropTypes.any,
          title: PropTypes.any,
        }),
        smart_cap: PropTypes.shape({ title: PropTypes.any }),
      }),
    }),
    eula: PropTypes.any,
  };

  interval = null;
  refModalCloseButton = React.createRef();
  refVideo = React.createRef();

  componentDidMount() {
    this.supportLinkRef && this.supportLinkRef?.current?.focus();
    const masked_id = this.props.organization?.masked_id;
    this.props.getConfig(masked_id);
  }

  closeModal = () => {
    this.props.closeEmptyModal();
    clearInterval(this.interval);
  };

  onVideoEnded = () => {
    this.closeModal();
  };

  intervalButtonPosition = () => {
    const button = this.refModalCloseButton.current;
    const videoCurrent = this.refVideo.current;

    if (!button || !videoCurrent) return;

    const width = videoCurrent.clientWidth;
    if (!width) return;

    const parentWidth = videoCurrent.parentElement?.offsetWidth;
    if (!parentWidth) return;

    const right = (parentWidth - width) / 2 + 20;

    button.style.right = `${right}px`;
  };

  openVideoPopup = () => {
    this.interval = setInterval(this.intervalButtonPosition, 500);

    this.props.openEmptyModal({
      data: (
        <React.Fragment>
          <div className={styles.videoModal}>
            <div ref={this.refModalCloseButton} className={styles.modalCloseButton} onClick={this.closeModal} />
            <video ref={this.refVideo} src={video} controls autoPlay onEnded={this.onVideoEnded} />
          </div>
        </React.Fragment>
      ),
    });
  };

  closeVideo = () => {
    this.setState({ isShow: false });
  };

  onDocSelected = id => {
    window.open(this.documents[id].link);
  };

  render() {
    const { openHelpModal, support } = this.props;
    const flags = support?.data?.support?.flags;
    const sections = support?.data?.support?.sections;

    const { activeTab } = this.props;
    const tabArray = [];
    const location = {
      lat: +sections?.location?.coordinates?.split(', ')[0],
      lng: +sections?.location?.coordinates?.split(', ')[1],
    };

    const contactUs = (
      <Tab name={Strings.contactUs} key={Strings.contactUs} path="/support/contact-us">
        <div className={styles.wrapper}>
          {flags && flags?.email && (
            <CardWrapper>
              <React.Fragment>
                <div className={styles.title}>{Strings.emailUs}</div>
                <div className={styles.text}>
                  {Strings.emailText}
                  <br></br>
                  <br></br>
                  <a href={`mailto:${sections.email?.emailAddress}?subject=${sections.email?.emailSubject}`}>
                    &nbsp;{sections.email?.emailAddress}
                  </a>
                </div>
              </React.Fragment>
            </CardWrapper>
          )}

          {flags && flags?.chat && (
            <CardWrapper>
              <React.Fragment>
                <div className={styles.title}>{Strings.callUs}</div>
                <div className={styles.text}>
                  {Strings.chatText}

                  <br></br>
                  <br></br>
                  <a href={'tel:' + sections.chat?.phone}>
                    <span className={styles.phone}>{sections.chat?.phone}</span>
                  </a>
                </div>
              </React.Fragment>
            </CardWrapper>
          )}

          {flags && flags?.location && (
            <CardWrapper>
              <React.Fragment>
                <div className={styles.title}>{sections.location?.title}</div>

                <div className={styles.mapParent}>
                  <div className={styles.text}>
                    {sections.location?.description}
                    <br></br>
                    <br></br>
                    <a target="_blank" href={sections.location?.map}>
                      <span className={styles.phone}>{sections.location?.location}</span>
                    </a>
                  </div>
                  {sections?.location?.coordinates && <Location center={location}></Location>}
                </div>
              </React.Fragment>
            </CardWrapper>
          )}

          {flags && flags?.help_center && (
            <CardWrapper>
              <React.Fragment>
                <div className={styles.title}>{Strings.helpCenter}</div>
                <div className={styles.text}>{Strings.helpCenterText}</div>
                <a className={styles.button} onClick={openHelpModal} tabIndex={0}>
                  {Strings.helpCenter}
                </a>
              </React.Fragment>
            </CardWrapper>
          )}
        </div>
      </Tab>
    );

    const actionBtn = (url, subject) => (
      <div className={styles.actionBox}>
        <button
          className={styles.btn}
          onClick={() => {
            navigator.clipboard.writeText(url);
          }}
        >
          <i className={styles.urlCopy}></i>
        </button>
        <button
          className={styles.btn}
          onClick={e => {
            window.location.href = `mailTo:?subject=${subject}&body=${encodeURIComponent(url)}`;
            e.preventDefault();
          }}
        >
          <i className={styles.urlMessage}></i>
        </button>
        <button
          className={styles.btn}
          onClick={() => {
            downloadFromURL(url);
          }}
        >
          <i className={styles.urlDownload}></i>
        </button>
      </div>
    );

    const videos = (
      <Tab name={Strings.videos} key={Strings.videos} path="/support/videos">
        {flags && flags?.rpm_video && (
          <React.Fragment>
            <div className={styles.wrapper}>
              <div className={styles.vidContainer}>
                <React.Fragment>
                  <div className={styles.title}>
                    <div className={styles.header}>{Strings.gettingStart}</div>
                    {actionBtn(this.bluetutorial, Strings.gettingStart)}
                  </div>
                  <video className={styles.videoModalTutorial} src={this.bluetutorial} controls />
                  <div className={styles.description}>{Strings.gettingStartDesc}</div>
                </React.Fragment>
              </div>
              <div className={styles.vidContainer}>
                <React.Fragment>
                  <div className={styles.title}>
                    <div className={styles.header}>{Strings.onboarding}</div>
                    {actionBtn(blueonboarding, Strings.onboarding)}
                  </div>

                  <video className={styles.videoModalTutorial} src={blueonboarding} controls />
                  <div className={styles.description}>{Strings.onboardingDesc}</div>
                </React.Fragment>
              </div>
              <div className={styles.vidContainer}>
                <React.Fragment>
                  <div className={styles.title}>
                    <div className={styles.header}>{Strings.enrollment}</div>
                    {actionBtn(this.blueenrollment, Strings.onboardingDesc)}
                  </div>
                  <video className={styles.videoModalTutorial} src={this.blueenrollment} controls />
                  <div className={styles.description}>{Strings.enrollementDesc}</div>
                </React.Fragment>
              </div>
              <div className={styles.vidContainer}>
                <React.Fragment>
                  <div className={styles.title}>
                    <div className={styles.header}>{Strings.monitoring}</div>
                    {actionBtn(this.bluemonitoring, Strings.monitoring)}
                  </div>
                  <video className={styles.videoModalTutorial} src={this.bluemonitoring} controls />
                  <div className={styles.description}>{Strings.enrollementDesc}</div>
                </React.Fragment>
              </div>
              <div className={styles.vidContainer}>
                <React.Fragment>
                  <div className={styles.title}>
                    <div className={styles.header}>{Strings.billing.billing}</div>
                    {actionBtn(this.bluebilling, Strings.billing.billing)}
                  </div>
                  <video className={styles.videoModalTutorial} src={this.bluebilling} controls />
                  <div className={styles.description}>{Strings.enrollementDesc}</div>
                </React.Fragment>
              </div>
              <div className={styles.vidContainer}>
                <React.Fragment>
                  <div className={styles.title}>
                    <div className={styles.header}>{Strings.SmartCapRTM}</div>
                    {actionBtn(RxCapSmartCapInstructionsFinal, Strings.SmartCapRTM)}
                  </div>

                  <div>
                    <center>
                      <video className={styles.potraitVideo} src={RxCapSmartCapInstructionsFinal} controls />
                    </center>
                    <div className={styles.description}>{Strings.enrollementDesc}</div>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </React.Fragment>
        )}
      </Tab>
    );

    const columns = [
      <Column key="name" title={Strings.name} value={e => e.name} />,
      <Column key="type" title={Strings.type} value={e => e.type} />,
      <Column key="size" title={Strings.size} value={e => e.size} />,
      <Column key="language" title={Strings.language} value={e => e.language} />,
      <Column key="descriptions" title={Strings.description} value={e => e.descriptions} />,
    ];

    const documents = (
      <Tab name={Strings.documents} key={Strings.documents} path="/support/documents">
        {flags && flags?.rpm_video && (
          <div className={styles.mt30}>
            <Table name="documents" isLoading={false} data={this.documents} onRowSelection={this.onDocSelected}>
              {columns}
            </Table>
          </div>
        )}
      </Tab>
    );

    if (
      flags &&
      (flags?.email || flags?.chat || flags?.help_center) &&
      (hasPermission(PERMISSIONS.SUPPORT_EMAIL) ||
        hasPermission(PERMISSIONS.SUPPORT_CHAT) ||
        hasPermission(PERMISSIONS.SUPPORT_HELP_CENTER))
    ) {
      tabArray.push(contactUs);
    }

    if (flags && flags?.rpm_video && hasPermission(PERMISSIONS.SUPPORT_RPM_VIDEO)) {
      tabArray.push(videos);
    }

    if (hasPermission(PERMISSIONS.SUPPORT_DOCUMENTS)) {
      tabArray.push(documents);
    }

    if (this.props.eula?.eula_content) {
      tabArray.push(
        <Tab name={Strings.endUserLicenseAgreement} key={Strings.endUserLicenseAgreement} path="/support/eula">
          <div className={styles.eulaAndTC}>{this.props.eula.eula_content}</div>
        </Tab>,
      );
    }
    if (this.props.eula?.tc_content) {
      tabArray.push(
        <Tab name={Strings.termsAndConditions} key={Strings.termsAndConditions} path="/support/terms">
          <div className={styles.eulaAndTC}>{this.props.eula.tc_content}</div>
        </Tab>,
      );
    }

    const tab = activeTab || (tabArray.length > 0 ? tabArray[0].props.name : null);

    return (
      <React.Fragment>
        <PageHeader noLeftPadding left={Strings.supportHeader} />

        <TabView key="tabs" activeTab={tab} routeMode className="" noSwiping>
          {tabArray}
        </TabView>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);
