import React from 'react';
import SimpleBar from 'simplebar-react';

import '../editStudyModal.scss';
import '../AttachCapToSchedule/attachCap.scss';
import Strings from '../../Strings';
import { SelectedOptions } from '../../components/Select/Multiselect';
import Select from '../../components/Select';
import AsyncSelect from '../../components/Select/AsyncSelect';

const DeviceList = React.forwardRef((props, injectedRef) => {
  return (
    <React.Fragment>
      {props.asyncNeeded ? (
        <AsyncSelect
          key={props.key}
          placeholder={Strings.patient_medications.enter3Chars}
          loadOptions={props.loadOptions}
          defaultOptions={props.data}
          onChange={props.handleChange}
        />
      ) : (
        <Select
          key={props.key}
          placeholder={Strings.select}
          onChange={props.handleChange}
          data={props.data}
          isSearchable
        />
      )}
      {props.simpleBar && (
        <SimpleBar className="devices-list-container" scrollableNodeProps={{ ref: injectedRef }}>
          <SelectedOptions items={props.devices} onRemove={props.delete} itemsPerRow={1} />
        </SimpleBar>
      )}
    </React.Fragment>
  );
});

export default DeviceList;
