import PropTypes from 'prop-types';
import { sendRestPasswordMessage } from 'actions/auth';
import { Button, BUTTON_TYPE_BLUE, BUTTON_TYPE_WHITE } from 'components/Buttons/Button';
import { Background } from 'components/Login/Background/Background';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Strings from 'Strings';

import { BASE_URL } from '../../constants';
import { Input, PhoneInputField, RadioButton } from '../../containers/Form';
import './ResetPassword.scss';
import { validateEmail, validatePhoneNo } from '../../utils/validators/rpmPatient';
import { notificationActions } from '../../components/Notification/redux/actions';

function ResetPassword(props) {
  const [selectedOption, setSelectedOption] = React.useState('email');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [buttonHover, setButtonHover] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState([]);

  useEffect(() => {
    if (selectedOption === 'email') {
      setValidationErrors(validateEmail(email));
    } else {
      setValidationErrors(validatePhoneNo(phone, 'phone', true));
    }
  }, [email, phone, selectedOption]);

  const onBackClick = () => {
    props.onBack();
  };

  const onSubmit = () => {
    const request = { method: selectedOption };
    if (selectedOption === 'email') {
      request.email = email;
    } else {
      request.phone_number = phone;
    }

    sendPasswordResetRequest(request);
  };

  const sendPasswordResetRequest = model => {
    props.sendRestPasswordMessage(model).then(sendPasswordResetRequestCallback);
  };

  const sendPasswordResetRequestCallback = e => {
    try {
      if (e.response.success.code === 200) {
        window.location = `${BASE_URL}reset_password_form/`;
        return;
      }
    } catch (_) {} // eslint-disable-line no-empty

    try {
      props.showNotification(e.response.data.error.message, 5000, true);
    } catch (_) {
      props.showNotification(Strings.errors.internalError, 5000, true);
    }
  };

  return (
    <Background title={Strings.recoverPassword} noLines>
      <div className="recover-password wizard">
        <div className="content-block">
          <p>{Strings.resetPasswordMethodPrompt}</p>
          <div className="grid-with-options">
            <div className="checkbox-container border">
              <RadioButton
                type="radio"
                name="email"
                id="email"
                value="email"
                label={Strings.email}
                containerClass="for-radio"
                checked={selectedOption === 'email'}
                onChange={() => setSelectedOption('email')}
              />
            </div>
            <div className="checkbox-container border">
              <RadioButton
                type="radio"
                name="sms"
                id="sms"
                value="sms"
                label="SMS"
                containerClass="for-radio"
                checked={selectedOption === 'sms'}
                onChange={() => setSelectedOption('sms')}
              />
            </div>
          </div>
          {selectedOption === 'email' ? (
            <Input
              name="email"
              id="email"
              label={Strings.email}
              type="text"
              value={email || ''}
              onChange={e => setEmail(e.target.value)}
              isRequired
              highlightInvalid={buttonHover}
              errorsForTooltip={validationErrors.find(v => v.property === 'email')?.errors || []}
            />
          ) : (
            <PhoneInputField
              align="left"
              name="phoneNo"
              id="phoneNo"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              label={Strings.phoneNumber}
              isRequired
              highlightInvalid={buttonHover}
              errorsForTooltip={validationErrors.find(v => v.property === 'phone')?.errors || []}
            />
          )}

          <ButtonRow
            left={
              <Button buttonType={BUTTON_TYPE_WHITE} type="button" onClick={onBackClick}>
                {Strings.back}
              </Button>
            }
            right={
              <div
                className="bottom-button-container"
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
              >
                <Button
                  buttonType={BUTTON_TYPE_BLUE}
                  type="submit"
                  disabled={validationErrors.length > 0}
                  onClick={onSubmit}
                >
                  {Strings.send}
                </Button>
              </div>
            }
          />
        </div>
      </div>
    </Background>
  );
}

ResetPassword.propTypes = {
  onBack: PropTypes.func,
  sendRestPasswordMessage: PropTypes.func,
  showNotification: PropTypes.func,
};

export default connect(null, dispatch => ({
  onBack: () => {
    dispatch(push('login'));
  },
  sendRestPasswordMessage: model => dispatch(sendRestPasswordMessage(model)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
}))(ResetPassword);
