import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getCapChargeText, signalRender, getVitalsData, convertUnixEpochToHumanReadable } from '../../../utils';
import { isShipper } from '../../../utils/userRoles';
import { actions as capActions } from '../../ShipperCaps/redux/actions';
import { actions } from '../Organization/redux/actions';
import {
  BLOOD_PRESSURE_MONITOR,
  BLUETOOTH_CAP,
  WEIGHING_MACHINE,
  GLUCOMETER_DEVICE_TYPE,
  WRIST_BAND,
  SPIROMETER_DEVICE_TYPE,
} from './constant';
import './Devices.scss';
import { getUrlForDevice } from './HubDeviceEvents';

class HubDevices extends PureComponent {
  hubId = encodeURIComponent(this.props.match.params.id);
  pageRequest = {
    offset: 0,
    search: '',
    filterBy: '',
  };
  refreshEnable = true;

  componentDidMount() {
    this.props.loadCaps(this.hubId, this.pageRequest);
    this.props.getDeviceStatusHistory(this.hubId);
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onSearchQueryChange(query) {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.props.loadCaps(this.hubId, this.pageRequest);
  }

  onDateRangeChanged = () => {
    this.pageRequest.offset = this.props.pagination.offset;
    this.props.loadCaps(this.hubId, this.pageRequest);
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    this.pageRequest.offset = pagination.offset - PAGE_LIMIT;
    this.props.loadCaps(this.hubId, this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.pageRequest.offset = pagination.offset + PAGE_LIMIT;
    this.props.loadCaps(this.hubId, this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.loadCaps(this.hubId, this.pageRequest);
  };

  onChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onBackButton = () => {
    const url = this.props.match.url;
    const updateURL = url.replace(`/${this.hubId}/devices`, '');
    if (this.props.history.location.state?.params?.offset) {
      this.props.onNavigate(updateURL, { offset: this.props.history.location.state.params?.offset });
    } else {
      this.props.onNavigate(updateURL, { offset: 0 });
    }
  };

  onRowSelected = id => {
    const device = this.props.devices[id];
    const url = getUrlForDevice(device, device.device_type);
    this.props.onNavigate(url, null, `?lastActivity=${device.timestamp}&hubId=${this.hubId}`);
  };

  onViewDetails = () => {
    const word = '/devices';
    const wordRegx = new RegExp(`(\\b${word}\\b)(?!.*\\b\\1\\b)`, 'i');
    const url = this.props.match.url.replace(wordRegx, '');
    this.props.onNavigate(url);
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.props.loadCaps(this.hubId, this.pageRequest);
  };
  getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button class={HEADER_BUTTON_DARK_BLUE} onClick={this.onViewDetails} title={Strings.deviceDetails} />
        <Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />
      </React.Fragment>
    );
  };

  getHWValue(e) {
    return isShipper() ? `${e.activity_value ?? ''} (${e.hw_code})` : e.activity_value;
  }

  onCapsDelete = id => {
    const cap = this.props.devices[id];
    const hubId = this.hubId;
    const deviceId = cap.device_id;
    const data = {
      title: (
        <span>
          {Strings.deleteHubDeviceWarning}{' '}
          <b>{cap.device_id.split('.').length >= 2 ? cap.device_id.split('.')[1] : cap.device_id}</b>?
        </span>
      ),
      onConfirmAction: actions.deleteHubDevice(hubId, deviceId, [actions.getHubDevices(hubId, this.pageRequest)]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  deleteDisabled = id => {
    const cap = this.props.devices[id];
    return (
      cap.device_type !== WEIGHING_MACHINE &&
      cap.device_type !== BLOOD_PRESSURE_MONITOR &&
      cap.device_type !== BLUETOOTH_CAP &&
      cap.device_type !== WRIST_BAND &&
      cap.device_type !== GLUCOMETER_DEVICE_TYPE &&
      cap.device_type !== SPIROMETER_DEVICE_TYPE
    );
  };

  render() {
    const { devices, isLoading, pagination, capInfo } = this.props;
    const selectedHub = this.props.match.params.id;
    const columns = [];
    columns.push(
      <Column
        key="device_id"
        title={Strings.deviceId}
        value={e => {
          const prefix = e.manufacturer.concat('.', e.model);
          let trueDeviceId = e.device_id.replace(prefix, '');
          if (trueDeviceId.startsWith('.')) trueDeviceId = trueDeviceId.slice(1);
          trueDeviceId = trueDeviceId.replace('rxcap.', '');
          return trueDeviceId;
        }}
      />,
    );

    columns.push(
      <Column key="deviceModel" title={Strings.deviceModel} value={e => e.manufacturer.concat(' ', e.model)} />,
    );

    columns.push(
      <Column
        key="timestamp"
        title={Strings.lastActivity}
        value={e => (e.timestamp ? convertUnixEpochToHumanReadable(e.timestamp) : '-')}
      />,
    );
    columns.push(<Column key={Strings.deviceType} title={Strings.deviceType} value={e => e.device_type ?? '-'} />);
    columns.push(
      <Column
        key="last_reading"
        title={Strings.lastReading}
        value={e => (!_.isEmpty(e.last_reading) ? getVitalsData(e.last_reading) : '-')}
      />,
    );

    columns.push(
      <Column
        key="pairing_status"
        title={Strings.pairingStatus}
        value={e => (e.last_pairing_status ? e.last_pairing_status : '')}
      />,
    );
    columns.push(
      <Column
        key="pairing_time"
        title={Strings.pairingTime}
        value={e => (e.last_pairing_timestamp ? convertUnixEpochToHumanReadable(e.last_pairing_timestamp) : '')}
      />,
    );
    const buttons = [
      {
        icon: 'delete',
        onClick: this.onCapsDelete,
        disabled: this.deleteDisabled,
        text: Strings.delete,
      },
    ];

    const signalStrength = capInfo ? capInfo.signal_strength : undefined;
    const battery = capInfo ? capInfo.battery : undefined;

    return (
      <React.Fragment>
        {!this.props.noHeader && (
          <div className="infoHeader">
            <div className="rowDirection">
              <button className="back" onClick={() => this.onBackButton()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildBox">
                {Strings.deviceId}: <div className="feildValue">{selectedHub}</div>
              </div>
            </div>
            <div className={'rowDirection left'}>
              <div className="feildBox">
                {Strings.signal}: <div className="feildValue">{signalRender(signalStrength)}</div>
              </div>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.battery.battery}: <div className="feildValue">{getCapChargeText(battery)}</div>
              </div>
            </div>
          </div>
        )}
        <div className="details-container">
          <PageHeader right={() => this.getHeaderComponents()} />
          {devices && (
            <React.Fragment>
              <Table
                name="hub-devices"
                uuid="96dffcdd-f1cd-483e-a98a-4216ba7d1601"
                data={devices}
                onPrevClick={this.onPrevClick}
                onNextClick={this.onNextClick}
                onRowClick={this.onRowSelected}
                pagination={
                  pagination || {
                    offset: 0,
                    total: 0,
                  }
                }
                isLoading={isLoading}
                onCustomPage={this.onCustomPage}
                buttons={buttons}
                enableColumnFiltering
              >
                {columns}
              </Table>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

HubDevices.propTypes = {
  clearData: PropTypes.func,
  devices: PropTypes.any,
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  isLoading: PropTypes.any,
  loadCaps: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.any }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  capInfo: PropTypes.shape({
    signal_strength: PropTypes.string,
    battery: PropTypes.number,
  }),
  noHeader: PropTypes.bool,
};

HubDevices.defaultProps = { noHeader: false };

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  return {
    role: state.auth.role,
    devices: organizations && organizations?.hubDevices,
    isLoading: organizations && organizations?.isLoading,
    pagination: organizations && organizations?.pagination,
    capInfo: organizations && organizations?.capInfo,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: (path, params, search) =>
    dispatch(
      push({
        pathname: path,
        state: { params },
        search,
      }),
    ),
  loadCaps: (hubId, pageRequest) => dispatch(actions.getHubDevices(hubId, pageRequest)),
  clearData: () => dispatch(actions.clearData()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  getDeviceStatusHistory: id => dispatch(capActions.getDeviceStatusHistory(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubDevices);
